<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.keyword"
          size='small'
          style="width:110px"
          placeholder='问题Id关键字'
      ></a-input>
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 130px"
          v-model="params.coinId"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="items of bigCoinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName + `【${items.id}】` + (items.coinCategory ? `---${items.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 100px"
          v-model="params.questionStatus"
          placeholder="问题状态"
      >
        <a-select-option
            v-for="item of questionStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 100px"
          v-model="params.answerStatus"
          placeholder="回答状态"
      >
        <a-select-option
            v-for="item of answerStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 150px"
          v-model="params.targetUserId"
          placeholder="回答老师"
      >
        <a-select-option
            v-for="item of userAuthList"
            :key="item.userId"
            :value="item.userId"
        >{{ item.userName + `【${item.userId}】`}}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 110px"
          v-model="params.operateStatus"
          placeholder="操作类型"
      >
        <a-select-option
            v-for="item of operateStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 100px"
          v-model="params.questionLevel"
          placeholder="难度等级"
      >
        <a-select-option
            v-for="item of levelList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-range-picker
          :placeholder="['推送开始日期','推送结束日期']"
          size='small'
          style="width:200px;margin:5px;"
          @change="handleChangePushQuestionTime"
      />
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          size="small"
          class="m-5 w-100"
          v-model="params.sourceType"
          placeholder="问题来源"
      >
        <a-select-option
            v-for="item of sourceTypeTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name}}</a-select-option>
      </a-select>
      <a-button
          size="small"
          type="primary"
          @click="search"
      >搜索</a-button>
      <div class="select-all-text">
        <a-checkbox
            v-model="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
      </div>
      <a-button
          @click="handleActionBatchByExpertOrNormal()"
          size="small"
          type="primary"
          class="header-button"
          :disabled="isDisabled"
      >批量推送</a-button>
      <a-button
          @click="handleCustomerUpLoadData"
          size="small"
          class="header-button"
      >手动上传</a-button>
    </div>
    <div class="table-content">
      <QuestionPageTable
        :list="data"
        :loading="loading"
        :pagination="pagination"
        @changePage="handleChangePage"
        @successStore="getList"
        @selectChange="onSelectChange"
        @resetVersion="handleResetVersion($event)"
      />
    </div>
<!--  推送到池子  -->
    <BatchPushDataPopup ref="batchPushDataPopupEl" @addOrEditorSuccess="search"/>
<!--  人工上传  -->
    <CustomerUploadPopup ref="customerUploadPopupEl" @success="search"/>
  </div>
</template>
<script>
import QuestionPageTable from "@/views/cmsPage/versionManage/duibanQuestionList/_components/QuestionPageTable"
import {operateStatusListData} from "@/views/cmsPage/versionManage/duibanquestionanswer/_data";
import untils from "@/untils";
import BatchPushDataPopup from "@/views/cmsPage/versionManage/duibanQuestionList/_components/BatchPushDataPopup"
import {
  answerStatusList,
  questionLevelList,
  questionStatusList
} from "@/views/cmsPage/versionManage/duibanQuestionList/_data"
import CustomerUploadPopup from "@/views/cmsPage/versionManage/duibanQuestionList/_components/CustomerUploadPopup"
import {sourceTypeTypeList} from "@/views/cmsPage/versionManage/_data"
import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    QuestionPageTable, BatchPushDataPopup, CustomerUploadPopup
  },
  computed: {
    isDisabled() {
      return this.listStatusByIdList.length <= 0;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: false,
      handler(newValue, oldNewValue) {
        let listStatus = []
        const listLength = this.data.length
        this.data.forEach(item => {
          if (item.checkStatus) {
            listStatus.push(item.questionId)
          }
        })
        if (listStatus.length === listLength) {
          this.checkAll = true
          this.indeterminate = false
        }
        if (listStatus.length > 0 && listStatus.length < listLength) {
          this.indeterminate = true
        }
        if (listStatus.length === 0) {
          this.checkAll = false
          this.indeterminate = false
        }
        try {
          this.listStatusByIdList = Array.from(new Set(this.listStatusByIdList.concat(listStatus)))
        } catch (e) {
          this.listStatusByIdList = listStatus
        }
        const newValueIds = []
        newValue.forEach(el => {
          if (!el.checkStatus) {
            newValueIds.push(el.questionId)
          }
        })
        this.listStatusByIdList = this.listStatusByIdList.filter(el => !newValueIds.includes(el))
      }
    }
  },
  data() {
    return {
      untils,
      sourceTypeTypeList,
      listStatusByIdList: [],
      checkAll: false,
      indeterminate: false,
      data: [],
      levelList: questionLevelList,
      answerStatusList: answerStatusList,
      userAuthList: [],
      questionStatusList: questionStatusList,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 20,
        operateStatus: undefined,
        coinId: undefined,
        questionStatus: undefined,
        targetUserId: undefined,
        keyword: undefined,
        answerStatus: undefined,
        questionStartTime: undefined,
        questionEndTime: undefined,
        questionLevel: undefined,
        sourceType: undefined
      },
      bigCoinList: [],
      versionList: [],
      selectedArray: [],
      operateStatusList: operateStatusListData,
      colorMap: {
        0: 'red',
        1: 'green',
        2: 'orange',
        3: 'gray',
        4: 'gray-b'
      },
      loading: false,
      authParams: {
        userId: undefined,
        userName: undefined,
        coinId: undefined,
        pageNum: 1,
        pageSize: 20
      },
    };
  },
  async mounted() {
    await this.getList()
    await this.searchUserData()
  },
  methods: {
    /** 重新对版 */
    async handleResetVersion(info) {
      const res = await this.axios("/dq_admin/duibanPushQuestion/listQuestions", {
        params: {
          pageNum: 1,
          keyword: info.questionId
        }
      });
      if (res.status !== '200') return
      let list = res.data.records
      list.forEach(el => {
        el.checkStatus = this.listStatusByIdList.includes(el.questionId)
        try {
          const listLength = el.machineList.length
          for (let i = 0; i < listLength; i++) {
            const versionId = el.machineList[i].versionId
            el.machineList.forEach(item => {
              if (versionId === item.versionId && !item.bgColor) {
                item.bgColor = this.colorMap[i]
              }
            })
          }
        } catch (e) {}
      })
      this.data.forEach(el => {
        if (el.questionId === info.questionId) {
          Object.assign(el, list[0])
        }
      })
    },
    /** 手动上传 */
    handleCustomerUpLoadData() {
      this.$refs.customerUploadPopupEl.show()
    },
    /** 修改全选 */
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.data.forEach(item => {
          item.checkStatus = true
        })
      } else {
        this.data.forEach(item => {
          item.checkStatus = false
        })
      }
    },
    /** 选择时间 */
    handleChangePushQuestionTime(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.questionStartTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.params.questionEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.params.questionStartTime = "";
        this.params.questionEndTime = "";
      }
    },
    /** 批量推送操作 */
    handleActionBatchByExpertOrNormal() {
      if (!this.params.coinId) {
        return this.$message.warn('请先选择大币种')
      }
      this.$refs.batchPushDataPopupEl.show(this.listStatusByIdList, this.params.coinId)
    },
    /** 获取table选中数据 */
    onSelectChange(selectedArray) {
      this.selectedArray = JSON.parse(JSON.stringify(selectedArray));
    },
    editSuccess() {
      this.getList()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList('changePage')
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1
      this.pagination.current = 1
      this.listStatusByIdList = []
      this.getList()
    },
    /**  获取列表数据 */
    async getList(type) {
      this.loading = true
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanPushQuestion/listQuestions", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      this.loading = false
      if (res.status !== '200') return
      let list = res.data.records
      list.forEach(el => {
        el.checkStatus = this.listStatusByIdList.includes(el.questionId)
        try {
          const listLength = el.machineList.length
          for (let i = 0; i < listLength; i++) {
            const versionId = el.machineList[i].versionId
            el.machineList.forEach(item => {
              if (versionId === item.versionId && !item.bgColor) {
                item.bgColor = this.colorMap[i]
              }
            })
          }
        } catch (e) {}
      })
      this.data = list
      this.pagination.total = res.data.total
    },
    /** 获取大币种列表 */
    async searchData(inner){
      if (!inner) return
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.bigCoinList = records
    },
    /** 搜索用户 */
    async searchUserData() {
      const url = '/dq_admin/duibanNormalAuthCoin/getAuthorityList'
      const res = await this.axios(url, {
        params: this.authParams
      })
      if (res.status != 200) return
      this.userAuthList = res.data.records
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
