var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-log-list-info-pages" },
    [
      _vm._l(_vm.orderLogVo, function(item, index) {
        return _c("div", { key: index, staticClass: "order-log-item" }, [
          _c("div", { staticClass: "order-item-left" }, [
            _vm._v(_vm._s(index + 1))
          ]),
          _c("div", { staticClass: "order-item-right" }, [
            _c("div", { staticClass: "font-weight-bold color-gray" }, [
              _vm._v(_vm._s(item.createTime))
            ]),
            _c("div", { staticClass: "font-weight-bold color-black mt-5" }, [
              _vm._v(_vm._s(item.userName))
            ]),
            _c("div", { staticClass: "flex-start-center flex-wrap mt-5" }, [
              _c("div", { staticClass: "font-weight-bold color-black w-400" }, [
                _vm._v(_vm._s(item.content || item.statusMessage))
              ]),
              item.remark
                ? _c(
                    "div",
                    {
                      staticClass: "font-weight-bold color-red mt-10 w-400",
                      class: _vm.computedClassType(item.remark)
                        ? "cur-pot color-blue"
                        : "",
                      on: {
                        click: function($event) {
                          return _vm.handleShowLogisticsInfoPopup(item.remark)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.remark))]
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "mt-5 flex-start-center" },
              [
                item.files
                  ? _vm._l(item.files, function(img, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "w-50 h-50 flex-center-center"
                        },
                        [
                          _c("img", {
                            staticClass: "w-100-w h-100-h",
                            attrs: { src: img, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowBigImg(item.files, index)
                              }
                            }
                          })
                        ]
                      )
                    })
                  : _vm._e(),
                item.videoUrl
                  ? _c(
                      "a-button",
                      {
                        staticClass: "ml-",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToShowVideo(item.videoUrl)
                          }
                        }
                      },
                      [_vm._v("查看视频")]
                    )
                  : _vm._e()
              ],
              2
            ),
            _c("div", { staticClass: "mt-5" }, [
              item.proofDesc
                ? _c("div", { staticClass: "font-weight-bold color-black" }, [
                    _vm._v(_vm._s(item.proofDesc))
                  ])
                : _vm._e()
            ])
          ])
        ])
      }),
      _c("ShowLogisticsDataPopup", { ref: "showLogisticsDataPopupEl" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }