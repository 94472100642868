<template>
  <div class="content">
    <div class="top">
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 180px"
          v-model="params.coinId"
          placeholder="请选择大币种"
          @change="handleChangeCoin($event)"
      >
        <a-select-option
            v-for="items of bigCoinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName + `【${items.id}】` }}</a-select-option>
      </a-select>
      <!-- 选择小币种 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @change="coinItemChange"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        size="small"
        style="margin: 5px; width: 180px"
        v-model="params.coinItemId"
        placeholder="请选择小币种"
      >
        <a-select-option
          v-for="item of smallCoinList"
          :key="item.coinKindItemId"
          :value="item.coinKindItemId"
        >{{ item.coinKindItemName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="search"
        allowClear
        showSearch
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 260px"
        v-model="params.versionId"
        :dropdownMenuStyle="{'max-height': '500px'}"
        placeholder="请选择版别"
      >
        <a-select-option
          v-for="item of versionList"
          :key="item.coinVersionId"
          :value="item.coinVersionId"
        >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
      </a-select>
      <a-button
          size="small"
          type="primary"
        @click="search"
      >搜索</a-button>
      <div class="change-box">
        <a-button
            size="small"
          @click="handleConfirmBottomReservoir()"
        >确认进底库</a-button>

        <a-button
            size="small"
            @click="handleConfirmSample()"
        >确认进样本库</a-button>
        <a-popconfirm
            title="确定要处理吗？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="handleBatchProcessed()"
        >
        <a-button
            size="small"
        >批量已处理</a-button>
        </a-popconfirm>

        <a-radio-group
            size="small"
            style="margin-left: 10px"
          v-model="imageType"
          :default-value="0"
          button-style="solid"
        >
          <a-radio-button :value="0">
            正面图
          </a-radio-button>
          <a-radio-button :value="1">
            背面图
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <div class="top-list">
      <div class="top-item" v-for="item in topData" :key="item.id">
        <div class="image-box" @click="handleSelectSearch(item)">
          <img
              :class="selectId === item.id ? 'selected-box' : ''"
              class="image"
              :src="showImage(item)"
          />
          <div class="tip">{{item.size}}</div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <BatchList
          :coinKindName="bigCoinText"
          :params="params"
        :list="data"
        :imageType="imageType"
        @editSuccess="editSuccess"
      ></BatchList>
      <div class="pagination">
        <a-pagination
          :pageSize.sync="pagination.pageSize"
          :current="pagination.current"
          :total="pagination.total"
          @change="changePage"
        />
      </div>
    </div>
    <BatchMove
        ref="p-move"
        @success="editSuccess"
    />
  </div>
</template>
<script>
import BatchList from "@/views/cmsPage/versionManage/duibanBatch/BatchList.vue";
import BatchMove from "@/views/cmsPage/versionManage/duibanBatch/BatchMove";
import untils from "@/untils";

export default {
  components: {
    BatchList,
    BatchMove
  },
  data() {
    return {
      untils,
      selectId: null,
      imageType: 0, //正面 1背面
      data: [],
      topData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
      },
      params: {
        pageNum: 1,
        pageSize: 30,
        coinId: undefined,
        coinItemId: undefined,
        versionId: undefined,
        minId: ''
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      bigCoinText: '',
      minId: ''
    };
  },
  mounted() {
  },
  methods: {
    /**  需要对应的api 选中搜索 */
    handleSelectSearch(item) {
      if (this.selectId === item.id) {
        this.selectId = null
        this.getList()
      } else {
        this.selectId = item.id
        this.getList()
      }
    },
    /** 确认进底库 */
    handleConfirmBottomReservoir() {
      let batchIds = []
      this.data.forEach(el => {
        if (el.checkStatus) {
          batchIds.push(el.id)
        }
      })
      if (batchIds && batchIds.length) {
        const data = {
          batchIds,
          type: 'bottom',
          coinKindId: this.params.coinId,
          coinKindItemId: this.params.coinItemId,
          coinVersionId: this.params.versionId,
          coinKindName: this.bigCoinList.find(el => el.id === this.params.coinId).coinKindName
        }
        this.$refs['p-move'].show(data)
      } else {
        this.$message.warning('请先选择要确认的数据')
      }
    },
    /** 确认进样本库 */
    handleConfirmSample() {
      let batchIds = []
      this.data.forEach(el => {
        if (el.checkStatus) {
          batchIds.push(el.id)
        }
      })
      if (batchIds && batchIds.length) {
        const data = {
          batchIds,
          type: 'sample',
          coinKindId: this.params.coinId,
          coinKindItemId: this.params.coinItemId,
          coinVersionId: this.params.versionId,
          coinKindName: this.bigCoinList.find(el => el.id === this.params.coinId).coinKindName
        }
        this.$refs['p-move'].show(data)
      } else {
        this.$message.warning('请先选择要确认的数据')
      }
    },
    /** 批量已处理 */
    async handleBatchProcessed() {
      let batchIds = []
      this.data.forEach(el => {
        if (el.checkStatus) {
          batchIds.push(el.id)
        }
      })
      if (batchIds && batchIds.length) {
        this.$loading.show()
        let ids = batchIds.join(",");
        const res = await this.axios("/dq_admin/duibanUserRecord/batchLooked", {
          params: { recordIds: ids },
        });
        this.$loading.hide()
        if (res.status != 200) return;
        this.$message.success(res.message || "操作成功");
        await this.getList();
      } else {
        this.$message.warning('请先选择要处理的数据')
      }
    },
    /** 图片变换 */
    showImage(item) {
      if (this.imageType === 0) {
        return item.frontImage
      } else {
        return item.backImage
      }
    },
    editSuccess() {
      this.getList()
    },
    /** 获取大币种列表 */
    searchData(inner){
      if (!inner) return
      this.axios('/dq_admin/kind/list',{params:{
          coinName: inner
      }}).then(res=>{
        if(res.status == '200'){
          const {records} = res.data
          this.bigCoinList = records
        }
      })
    },
    /** 选择大币种 */
    handleChangeCoin(e) {
      this.bigCoinText = this.bigCoinList.find(el => el.id === e).coinKindName
      delete this.params.coinItemId;
      delete this.params.versionId
      if (!e) {
        this.bigCoinList = []
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      this.getSmallCoinList();
    },
    /** 查询小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinItems", {
        params: {
          coinId: this.params.coinId,
        },
      });
      if (res.status != 200) return;
      this.smallCoinList = res.data;
    },
    /** 选择小币种 */
    coinItemChange(val) {
      delete this.params.versionId;
      if (!val) {
        this.versionList = [];
        return;
      }
      this.getVersionType();
    },
    /** 获取版别 */
    async getVersionType() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId: this.params.coinId,
          coinItemId: this.params.coinItemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },
    /** 获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanUserRecord/improveTheEfficiencyOfVersionMatching", {
        params: {
          ...this.params,
          duibanConfirmId: this.selectId
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.topData = res.data.duibanConfirmList
      this.minId = res.data.minId


      let list = res.data.duibanUserRecordPage.records;
      list.forEach(item => {
        item.checkStatus = false
      })
      this.data = list;
      this.pagination.total = res.data.duibanUserRecordPage.total;
    },

    changePage(page) {
      this.pagination.current = page;
      this.params.pageNum = page;
      this.params.minId = this.minId
      if (this.pagination.current === 1) delete this.params.minId

      this.getList();
    },

    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1;
      if (this.pagination.current === 1) delete this.params.minId
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.selected-box {
  border: 4px solid red;
}
.top-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 200px;
  flex-wrap: nowrap;
  overflow-y: scroll;
  position: relative;
  .top-item {
    position: relative;
    width: 150px;
    height: 150px;
    .image-box {
      cursor: pointer;
    }
    .image {
      width: 150px;
      height: 150px;
      border-radius: 4px;
    }
    .tip {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 2px;
      color: black;
      font-size: 12px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 4px;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
