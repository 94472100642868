<template>
  <div class="breadcrumb-attrs">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item style="margin-left:10px">斗泉后台</a-breadcrumb-item>
      <a-breadcrumb-item v-for="item of breadList" :key="item.key">{{item.menuName}}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mr-20 flex-end-center">
<!--   导出省市区JSON---省份   -->
      <a-button size="small" class="mr-20" type="primary"
          v-if="[45, 24].includes(userInfo.id)"
          @click="handleExpertAreaJson('province')"
      >导出省份</a-button>
<!--   导出省市区JSON---市区   -->
      <a-button size="small" class="mr-20" type="primary"
          v-if="[45, 24].includes(userInfo.id)"
          @click="handleExpertAreaJson('city')"
      >导出市区</a-button>
<!--   导出省市区JSON---县区   -->
      <a-button size="small" class="mr-20" type="primary"
          v-if="[45, 24].includes(userInfo.id)"
          @click="handleExpertAreaJson('country')"
      >导出县区</a-button>
<!--   导出省市区JSON---全部   -->
      <a-button size="small" class="mr-40" type="primary"
          v-if="[45, 24].includes(userInfo.id)"
          @click="handleExpertAreaJson('all')"
      >导出全部</a-button>
<!--   增加词条   -->
      <a-button
          size="small"
          type="primary"
          v-if="![27].includes(userInfo.id)"
          @click="handleAddEntryPopup"
      >新增词条</a-button>
    </div>
<!--  新增词条弹窗  -->
    <AddEntryPopup ref="addEntryPopupEl"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import AddEntryPopup from "@/components/AddEntryPopup/index.vue"
import {
  h5AreaExportServiceByAreaJson,
  h5AreaExportServiceByCityList,
  h5AreaExportServiceByCountryList,
  h5AreaExportServiceByProvinceList
} from "@/untils/_service"
export default {
  components: {AddEntryPopup},
  props:{
    userInfo: {
      type: Object
    },
    result:{
      type:Array,default:null,
    }
  },
  data(){
    return {
    }
  },
  computed:{
    ...mapState(['NavigationsData','breadList']),
  },
  methods: {
    /** 导出Area-json h5用 */
    handleExpertAreaJson(type) {
      if (type === 'province') {
        h5AreaExportServiceByProvinceList(this)
      }
      if (type === 'city') {
        h5AreaExportServiceByCityList(this)
      }
      if (type === 'country') {
        h5AreaExportServiceByCountryList(this)
      }
      if (type === 'all') {
        h5AreaExportServiceByAreaJson(this)
      }
    },
    /** 新增词条弹窗 */
    handleAddEntryPopup() {
      this.$refs.addEntryPopupEl.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-attrs{width:100%;height:40px;display: flex;justify-content: space-between;align-items:center;}
</style>