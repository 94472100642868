var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticStyle: { "margin-left": "5px", width: "220px" },
            attrs: { allowClear: "", size: "small", placeholder: "用户Id" },
            model: {
              value: _vm.params.userId,
              callback: function($$v) {
                _vm.$set(_vm.params, "userId", $$v)
              },
              expression: "params.userId"
            }
          }),
          _c("a-input", {
            staticStyle: { "margin-left": "5px", width: "220px" },
            attrs: { allowClear: "", size: "small", placeholder: "用户名称" },
            model: {
              value: _vm.params.userName,
              callback: function($$v) {
                _vm.$set(_vm.params, "userName", $$v)
              },
              expression: "params.userName"
            }
          }),
          _c(
            "a-select",
            {
              staticStyle: { "margin-left": "5px", width: "220px" },
              attrs: {
                "filter-option": _vm.untils.filterOption,
                showSearch: "",
                allowClear: "",
                size: "small",
                placeholder: "请选择大币种"
              },
              on: { search: _vm.searchData },
              model: {
                value: _vm.params.coinId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinId", $$v)
                },
                expression: "params.coinId"
              }
            },
            _vm._l(_vm.bigList, function(item, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: item.id } },
                [
                  _vm._v(
                    _vm._s(
                      item.coinKindName +
                        "【" +
                        item.id +
                        "】" +
                        (item.coinCategory ? "---" + item.coinCategory : "")
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "ml-5",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            {
              staticClass: "ml-5",
              attrs: { type: "default", size: "small" },
              on: { click: _vm.addAuth }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("ExpertOrNormalOrRatingPageTable", {
            attrs: {
              list: _vm.listData,
              "page-type": "normal",
              pagination: _vm.pagination
            },
            on: { deleteItem: _vm.getList, changePage: _vm.changePage }
          })
        ],
        1
      ),
      _c("ExpertOrNormalAdd", {
        ref: "expertOrNormalAddEl",
        on: { addOrEditorSuccess: _vm.addSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }