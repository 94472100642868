var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemIdSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-black" }, [
                  _vm._v("ID："),
                  _c("span", { staticClass: "color-blue" }, [
                    _vm._v(_vm._s(row.id))
                  ])
                ]),
                _c("div", { staticClass: "font-weight-bold color-black" }, [
                  _vm._v("排序："),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v(_vm._s(row.sort))
                  ])
                ])
              ])
            }
          },
          {
            key: "itemTitleSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-black" }, [
                  _vm._v(_vm._s(row.layerTitle))
                ])
              ])
            }
          },
          {
            key: "itemContentSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.detailType === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "cur-pot color-blue",
                        on: {
                          click: function($event) {
                            return _vm.handleShowTextContent(row.detailContent)
                          }
                        }
                      },
                      [_vm._v("富文本内容")]
                    )
                  : _vm._e(),
                row.detailType === 2
                  ? _c(
                      "div",
                      {
                        staticClass: "w-100-w flex-center-center",
                        on: {
                          click: function($event) {
                            return _vm.handleShowBigImg(row.detailPicture)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "w-50 text-center",
                          attrs: { src: row.detailPicture, alt: "" }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { class: _vm.noticeStatusClassType[row.noticeStatus] },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedHomeNoticeStatusListMapText(
                          row.noticeStatus
                        )
                      )
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title:
                            "确定" +
                            (row.noticeStatus ? "下架" : "发布") +
                            "当前公告？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleChangeNoticeStatusHomeNotice(
                              row.id
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              size: "small",
                              type: row.noticeStatus ? "danger" : "primary"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(row.noticeStatus ? "下架公告" : "发布公告")
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemCreateOrUpdateAboutSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(" 创建时间："),
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.createTime))
                  ])
                ]),
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(" 公告开始时间："),
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.startTime))
                  ])
                ]),
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(" 公告结束时间："),
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.endTime))
                  ])
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mt-10",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditorHomeNoticePopup(row)
                          }
                        }
                      },
                      [_vm._v("编辑更新")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除当前公告？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDeleteHomeNotice(row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("TextContentPopup", { ref: "textContentPopupEl" }),
      _c("EditorAppHomeNoticeContentPopup", {
        ref: "editorAppHomeNoticeContentPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }