var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "180px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                dropdownMenuStyle: { "max-height": "350px" },
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择大币种"
              },
              on: {
                search: _vm.searchData,
                change: function($event) {
                  return _vm.handleChangeCoin($event)
                }
              },
              model: {
                value: _vm.params.coinId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinId", $$v)
                },
                expression: "params.coinId"
              }
            },
            _vm._l(_vm.bigCoinList, function(items) {
              return _c(
                "a-select-option",
                { key: items.id, attrs: { value: items.id } },
                [_vm._v(_vm._s(items.coinKindName + "【" + items.id + "】"))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "180px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择小币种"
              },
              on: { change: _vm.coinItemChange },
              model: {
                value: _vm.params.coinItemId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinItemId", $$v)
                },
                expression: "params.coinItemId"
              }
            },
            _vm._l(_vm.smallCoinList, function(item) {
              return _c(
                "a-select-option",
                {
                  key: item.coinKindItemId,
                  attrs: { value: item.coinKindItemId }
                },
                [_vm._v(_vm._s(item.coinKindItemName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "260px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                dropdownMenuStyle: { "max-height": "500px" },
                placeholder: "请选择版别"
              },
              on: { select: _vm.search },
              model: {
                value: _vm.params.versionId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "versionId", $$v)
                },
                expression: "params.versionId"
              }
            },
            _vm._l(_vm.versionList, function(item) {
              return _c(
                "a-select-option",
                {
                  key: item.coinVersionId,
                  attrs: { value: item.coinVersionId }
                },
                [
                  _vm._v(
                    _vm._s(
                      "【" +
                        (item.level ? item.level : "无") +
                        "】 " +
                        item.coinVersionName +
                        "(" +
                        (item.size || 0) +
                        ")"
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "div",
            { staticClass: "change-box" },
            [
              _c(
                "a-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.handleConfirmBottomReservoir()
                    }
                  }
                },
                [_vm._v("确认进底库")]
              ),
              _c(
                "a-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.handleConfirmSample()
                    }
                  }
                },
                [_vm._v("确认进样本库")]
              ),
              _c(
                "a-popconfirm",
                {
                  attrs: {
                    title: "确定要处理吗？",
                    "ok-text": "确认",
                    "cancel-text": "取消"
                  },
                  on: {
                    confirm: function($event) {
                      return _vm.handleBatchProcessed()
                    }
                  }
                },
                [
                  _c("a-button", { attrs: { size: "small" } }, [
                    _vm._v("批量已处理")
                  ])
                ],
                1
              ),
              _c(
                "a-radio-group",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    size: "small",
                    "default-value": 0,
                    "button-style": "solid"
                  },
                  model: {
                    value: _vm.imageType,
                    callback: function($$v) {
                      _vm.imageType = $$v
                    },
                    expression: "imageType"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 0 } }, [
                    _vm._v(" 正面图 ")
                  ]),
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v(" 背面图 ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top-list" },
        _vm._l(_vm.topData, function(item) {
          return _c("div", { key: item.id, staticClass: "top-item" }, [
            _c(
              "div",
              {
                staticClass: "image-box",
                on: {
                  click: function($event) {
                    return _vm.handleSelectSearch(item)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "image",
                  class: _vm.selectId === item.id ? "selected-box" : "",
                  attrs: { src: _vm.showImage(item) }
                }),
                _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(item.size))])
              ]
            )
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("BatchList", {
            attrs: {
              coinKindName: _vm.bigCoinText,
              params: _vm.params,
              list: _vm.data,
              imageType: _vm.imageType
            },
            on: { editSuccess: _vm.editSuccess }
          }),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("a-pagination", {
                attrs: {
                  pageSize: _vm.pagination.pageSize,
                  current: _vm.pagination.current,
                  total: _vm.pagination.total
                },
                on: {
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.pagination, "pageSize", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.pagination, "pageSize", $event)
                  },
                  change: _vm.changePage
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("BatchMove", { ref: "p-move", on: { success: _vm.editSuccess } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }