<template>
  <div class="content">
    <div class="flex-sp-bt-center">
      <div class="top">
        <a-input allowClear v-model="params.keyword" class="w-200 mb-5" placeholder='标题关键词搜索'></a-input>
        <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.noticeStatus" placeholder="公告发布状态">
          <a-select-option v-for="item of noticeStatusList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
        <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
        <a-button class="mb-5 ml-40" type="primary" @click="handleAddNewNotice">发布新公告</a-button>
      </div>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
<!--  添加新公告  -->
    <EditorAppHomeNoticeContentPopup ref="editorAppHomeNoticeContentPopupEl" @success="getList" />
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_components/TableList/index.vue"
import {noticeStatusList} from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_data"
import EditorAppHomeNoticeContentPopup
  from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_components/EditorAppHomeNoticeContentPopup/index.vue"
import {getAppHomeNoticeListApi} from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_apis"

export default {
  components: {
    TableList, EditorAppHomeNoticeContentPopup,
  },
  data() {
    return {
      data: [],
      noticeStatusList, // 公告上线状态
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        keyword: undefined,
        noticeStatus: undefined, // 公告发布状态
      },
    };
  },
  async created() {
    await this.getList()
  },
  methods: {
    /** 添加新公告 */
    handleAddNewNotice() {
      this.$refs.editorAppHomeNoticeContentPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    /** 搜索 */
    search() {
      this.pagination.params = 1
      this.params.pageNum = 1
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getAppHomeNoticeListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
