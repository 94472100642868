var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 1100, title: "修改评级价格" },
          on: { cancel: _vm.handleCancel },
          scopedSlots: _vm._u(
            [
              !_vm.confirmStatus
                ? {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "a-button",
                          { key: "back", on: { click: _vm.handleCancel } },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "a-button",
                          {
                            key: "submit",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleConfirm }
                          },
                          [_vm._v("确定")]
                        )
                      ]
                    },
                    proxy: true
                  }
                : {
                    key: "footer",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
            ],
            null,
            true
          ),
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c(
            "div",
            { staticClass: "popup-content" },
            [
              _c("div", { staticClass: "content-top" }, [
                _c("div", { staticClass: "w-100-w flex-start-center" }, [
                  _c("div", { staticClass: "cell-text" }, [
                    _vm._v("数量："),
                    _c("span", { staticClass: "color-black" }, [
                      _vm._v(_vm._s(_vm.popupData.coinAmount))
                    ])
                  ]),
                  _c("div", { staticClass: "cell-text ml-10" }, [
                    _vm._v("是否需要对版："),
                    _c(
                      "span",
                      {
                        class: _vm.confirmClassType[_vm.popupData.duibanService]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.dTypeVersionStatusTypeText(
                              _vm.popupData.duibanService
                            )
                          )
                        )
                      ]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "w-100-w" },
                  _vm._l(_vm.popupData.payArr, function(el, ind) {
                    return _c(
                      "div",
                      { key: ind, staticClass: "mt-10 flex-start-center" },
                      [
                        _c("div", { staticClass: "cell-text ml-10" }, [
                          _vm._v("支付金额："),
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(el.payMoney))
                          ])
                        ]),
                        _c("div", { staticClass: "cell-text ml-10" }, [
                          _vm._v("支付状态："),
                          _c(
                            "span",
                            {
                              class: _vm.dTypePayStatusClassType[el.payStatus]
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.dTypePayStatusTypeText(el.payStatus))
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "cell-text ml-10" }, [
                          _vm._v("支付时间："),
                          _c("span", { staticClass: "color-black" }, [
                            _vm._v(_vm._s(el.payTime))
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "mt-10" }, [
                  _vm._v("地址："),
                  _c("span", { staticClass: "cur-pot color-gary" }, [
                    _vm._v(_vm._s(_vm.popupData.targetAddress))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "mt-20" },
                  [
                    _c("a-textarea", {
                      attrs: { placeholder: "售后定责描述" },
                      model: {
                        value: _vm.reviewRemarks,
                        callback: function($$v) {
                          _vm.reviewRemarks = $$v
                        },
                        expression: "reviewRemarks"
                      }
                    }),
                    _c("div", { staticClass: "color-red" }, [
                      _vm._v("*请谨慎填写")
                    ])
                  ],
                  1
                )
              ]),
              _c("a-divider", [_vm._v("以下为对应钱币数据")]),
              _c(
                "div",
                { staticClass: "content-bottom" },
                _vm._l(_vm.popupData.boxCodeArr, function(item, index) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "content-bottom-item mt-20" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100-w flex-start-center flex-wrap bottom-item-top"
                        },
                        [
                          _c("div", { staticClass: "cell-text" }, [
                            _c("span", { staticClass: "color-black" }, [
                              _vm._v(_vm._s(index + 1 + "、"))
                            ]),
                            _vm._v("名称版别："),
                            _c("span", { staticClass: "color-black" }, [
                              _vm._v(
                                _vm._s(
                                  (item.title || "暂无信息") +
                                    " " +
                                    (item.version || "")
                                )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "cell-text ml-10" }, [
                            _vm._v("评级编号："),
                            _c("span", { staticClass: "color-green" }, [
                              _vm._v(_vm._s(item.boxCode))
                            ])
                          ]),
                          _c("div", { staticClass: "cell-text ml-10" }, [
                            _vm._v("赔付等级："),
                            _c("span", { staticClass: "color-green" }, [
                              _vm._v(_vm._s(item.payLevel || "暂无信息"))
                            ])
                          ]),
                          _c("div", { staticClass: "cell-text ml-10" }, [
                            _vm._v("评级状态："),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.rateServiceResultStatusClassType[
                                    item.cistrue
                                  ]
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.rateServiceResultStatusTypeList[
                                      item.cistrue
                                    ] || "暂无信息"
                                  )
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "cell-text ml-10" }, [
                            _vm._v("分数："),
                            _c("span", { staticClass: "color-green" }, [
                              _vm._v(_vm._s(item.score || "暂无信息"))
                            ])
                          ]),
                          _c("div", { staticClass: "cell-text ml-10" }, [
                            _vm._v("描述："),
                            _c("span", { staticClass: "color-gray" }, [
                              _vm._v(_vm._s(item.remark || "暂无信息"))
                            ])
                          ]),
                          item.boxCode
                            ? _c(
                                "a-button",
                                {
                                  staticClass: "ml-10",
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleShowChangeRatingCodePopup(
                                        item.boxCode,
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("修改评级编号")]
                              )
                            : _vm._e(),
                          !item.qualityInspectionImage
                            ? _c(
                                "a-button",
                                {
                                  key: "reset",
                                  staticClass: "ml-40",
                                  attrs: { size: "small" },
                                  on: { click: _vm.getDTypeDetail }
                                },
                                [_vm._v("重新获取")]
                              )
                            : _vm._e(),
                          item.qualityInspectionImage
                            ? _c(
                                "a-popconfirm",
                                {
                                  attrs: { title: "确定刷新评级结果？" },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.handleRefreshRateResult(
                                        item.boxCode
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      key: "refresh",
                                      staticClass: "ml-40",
                                      attrs: { type: "danger", size: "small" }
                                    },
                                    [_vm._v("刷新评级结果")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mt-10 flex-start-center" }, [
                        _c(
                          "div",
                          { staticClass: "cell-text" },
                          [
                            _vm._v(" 评级费用： "),
                            _c("a-input", {
                              staticClass: "w-100",
                              attrs: {
                                placeholder: "填写评级费用",
                                type: "number"
                              },
                              model: {
                                value: _vm.editorPriceObj[item.id].ratingPrice,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editorPriceObj[item.id],
                                    "ratingPrice",
                                    $$v
                                  )
                                },
                                expression:
                                  "editorPriceObj[item.id].ratingPrice"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.popupData.duibanService
                          ? _c(
                              "div",
                              { staticClass: "cell-text ml-10" },
                              [
                                _vm._v(" 对版费用： "),
                                _c("a-input", {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: "填写对版费用",
                                    type: "number"
                                  },
                                  model: {
                                    value:
                                      _vm.editorPriceObj[item.id].duibanPrice,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editorPriceObj[item.id],
                                        "duibanPrice",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editorPriceObj[item.id].duibanPrice"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      item.qualityInspectionImage
                        ? _c(
                            "div",
                            { staticClass: "mt-10 img-list" },
                            _vm._l(
                              item.qualityInspectionImage.concat(
                                item.officialWebsiteImage || []
                              ),
                              function(img, ind) {
                                return _c("img", {
                                  key: ind,
                                  attrs: { src: img, alt: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.handleShowBigImg(
                                        item.qualityInspectionImage.concat(
                                          item.officialWebsiteImage || []
                                        ),
                                        ind
                                      )
                                    }
                                  }
                                })
                              }
                            ),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      ),
      _c("EditorRatingCodePopup", {
        ref: "editorRatingCodePopupEl",
        attrs: { "rate-type": "D" },
        on: { change: _vm.getDTypeDetail }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }