<template>
  <div class="content">
    <div class="flex-sp-bt-center">
      <div class="top">
        <a-select
            :dropdownMatchSelectWidth="false"
            @search="coinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            class="w-300 ml-5"
            v-model="params.coinId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName + `【${item.id}】` + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
        </a-select>
        <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
        <a-button class="ml-20 mb-5" type="primary" @click="handleAdd">添加币种模型版本</a-button>
        <a-popconfirm
            title="确定同步测试集？"
            @confirm="handleSyncVersionTest"
        >
          <a-button class="ml-20 mb-5" type="danger">同步测试集</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
      />
    </div>
<!--  新增  -->
    <AddCoinModelVersionPopup ref="addCoinModelVersionPopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_components/TableList/index.vue"
import {getCoinModelVersionListApi} from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_apis"
import AddCoinModelVersionPopup
  from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_components/AddCoinModelVersionPopup/index.vue"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import untils from "@/untils"
import {getSyncTestApi} from "@/views/cmsPage/versionManage/duibanFeedback/_apis"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  components: {
    TableList, AddCoinModelVersionPopup
  },
  data() {
    return {
      untils,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageSize: 10,
        pageNum: 1,
        coinId: undefined
      },
    };
  },
  async created() {
    await this.getList()
  },
  methods: {
    /** 同步测试集 */
    async handleSyncVersionTest() {
      this.$loading.show()
      const res = await getSyncTestApi()
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message || '同步完成')
    },
    /** 天假币种 */
    handleAdd() {
      this.$refs.addCoinModelVersionPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.getList();
    },
    /** 搜索 */
    search() {
      this.pagination.params = 1
      this.params.pageNum = 1
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getCoinModelVersionListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
