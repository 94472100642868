var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.tableList,
      pagination: _vm.pagination,
      "data-source": _vm.list,
      rowKey: function(record, index) {
        return index
      }
    },
    on: { change: _vm.changePage },
    scopedSlots: _vm._u([
      {
        key: "itemProductIdSlot",
        fn: function(row) {
          return _c("div", {}, [
            _c(
              "div",
              {
                staticClass: "color-blue font-weight-bold cur-pot",
                on: {
                  click: function($event) {
                    return _vm.handleGoToAuctionList(row)
                  }
                }
              },
              [_vm._v(_vm._s(row.productId))]
            )
          ])
        }
      },
      {
        key: "itemProductNameSlot",
        fn: function(row) {
          return _c("div", {}, [
            _c("div", { staticClass: "mt-10 color-black font-weight-bold" }, [
              _vm._v(" " + _vm._s(row.productName) + " 【"),
              _c("span", { staticClass: "color-red" }, [
                _vm._v(_vm._s(row.currentFinalPrice) + "元")
              ]),
              _vm._v("】 ")
            ])
          ])
        }
      },
      {
        key: "itemProductPriceAboutSlot",
        fn: function(row) {
          return _c("div", {}, [
            _c("div", { staticClass: "font-weight-bold color-black mt-20" }, [
              _vm._v(" 用户出价： "),
              _c("span", { staticClass: "color-red" }, [
                _vm._v(_vm._s(row.finalPrice) + "元")
              ]),
              _vm._v(" ， "),
              _c(
                "span",
                { class: row.isAdvanced ? "color-green" : "color-gray" },
                [_vm._v(_vm._s(row.isAdvanced ? "领先" : "落后"))]
              ),
              _vm._v(" （"),
              _c("span", { staticClass: "color-gray" }, [
                _vm._v(_vm._s(row.finalPriceCreateTime))
              ]),
              _vm._v("） ")
            ])
          ])
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }