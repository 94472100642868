var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "上传",
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.cancelPushModal, ok: _vm.confirmPushModal },
      model: {
        value: _vm.pushModal,
        callback: function($$v) {
          _vm.pushModal = $$v
        },
        expression: "pushModal"
      }
    },
    [
      _c("div", { staticClass: "push-modal" }, [
        _c(
          "div",
          [
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  allowClear: "",
                  showSearch: "",
                  size: "small",
                  placeholder: "请选择大币种"
                },
                on: { change: _vm.changeCoin, search: _vm.coinSearch },
                model: {
                  value: _vm.curItem.coinKindId,
                  callback: function($$v) {
                    _vm.$set(_vm.curItem, "coinKindId", $$v)
                  },
                  expression: "curItem.coinKindId"
                }
              },
              _vm._l(_vm.coinList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinKindName + "【" + item.id + "】"))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "220px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  allowClear: "",
                  showSearch: "",
                  size: "small",
                  placeholder: "请选择小币种"
                },
                on: { change: _vm.changeCoinItem },
                model: {
                  value: _vm.curItem.coinKindItemId,
                  callback: function($$v) {
                    _vm.$set(_vm.curItem, "coinKindItemId", $$v)
                  },
                  expression: "curItem.coinKindItemId"
                }
              },
              _vm._l(_vm.coinItemList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinKindItemName))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "260px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  allowClear: "",
                  showSearch: "",
                  "filter-option": _vm.untils.filterOption,
                  size: "small",
                  dropdownMenuStyle: { "max-height": "500px" },
                  placeholder: "版别"
                },
                on: { change: _vm.changeVersion },
                model: {
                  value: _vm.curItem.coinKindItemVersionId,
                  callback: function($$v) {
                    _vm.$set(_vm.curItem, "coinKindItemVersionId", $$v)
                  },
                  expression: "curItem.coinKindItemVersionId"
                }
              },
              _vm._l(_vm.versionList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s("" + item.coinKindVersionName))]
                )
              }),
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "images" }, [
          _c("div", { staticClass: "images-title" }, [
            _c("div", [_vm._v("正")]),
            _c("div", [_vm._v("反")])
          ]),
          _c("div", { staticClass: "up-image" }, [
            _c(
              "div",
              { staticClass: "up-left" },
              [
                _c(
                  "a-upload",
                  {
                    key: _vm.imgKey,
                    attrs: {
                      action: _vm.ip + "/file/upload",
                      "list-type": "picture-card",
                      "file-list": _vm.coverList,
                      multiple: ""
                    },
                    on: {
                      preview: _vm.handlePreview,
                      change: _vm.handleChangeCoverList
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("a-icon", { attrs: { type: "plus" } }),
                        _c("div", { staticClass: "ant-upload-text" }, [
                          _vm._v("上传图片")
                        ])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "up-right" },
              [
                _vm.coverList
                  ? _c(
                      "a-checkbox-group",
                      {
                        staticClass: "right-list",
                        on: {
                          change: function($event) {
                            return _vm.handleChangeCheckBoxText($event)
                          }
                        }
                      },
                      _vm._l(parseInt(_vm.coverList.length / 2), function(
                        items,
                        index
                      ) {
                        return _c(
                          "a-checkbox",
                          {
                            key: index,
                            staticClass: "right-item",
                            attrs: { value: index }
                          },
                          [_vm._v("是否作为测试集")]
                        )
                      }),
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }