<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.productName"
          class="w-200 mb-5"
          placeholder='产品名称'
      ></a-input>
      <a-input
          allowClear
          v-model="params.orderNo"
          class="w-200 ml-5 mb-5"
          placeholder='订单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.personApplyNo"
          class="w-200 ml-5 mb-5"
          placeholder='D类评级单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.userName"
          class="w-200 ml-5 mb-5"
          placeholder='送评人昵称或userID'
      ></a-input>
      <a-input
          allowClear
          v-model="params.shortCode"
          class="w-200 ml-5"
          placeholder='短码'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.reviewStatus"
          placeholder="质检状态"
      >
        <a-select-option
            v-for="item of dqCheckStatusTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.responsibilityDetermine"
          placeholder="质检结果类型"
      >
        <a-select-option
            v-for="item of dqCheckResultTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.ratingABCDType"
          placeholder="送评类型"
      >
        <a-select-option
            v-for="item of dqCheckPayTypeListByAD"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.receiveConfirmStatus"
          placeholder="在途状态"
      >
        <a-select-option
            v-for="item of arriveTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button type="primary" @click="search">搜索</a-button>
      <a-button v-if="false" class="ml-20" type="default" @click="handleShowExpertData">质检记录导出</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
      />
    </div>
    <!--  导出数据  -->
    <ExpertRateCheckListPopup ref="expertRateCheckListPopupEl"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/recordlist/_components/TableList"
import {
  dqArriveTypeList, dqCheckPayTypeList, dqCheckPayTypeListByAD,
  dqCheckResultAfterByBuyerTypeList,
  dqCheckResultTypeList, dqCheckStatusTypeList
} from "@/views/cmsPage/checkManage/_data"
import {scanCodeWatch} from "@/untils/otherEvent"
import ExpertRateCheckListPopup
  from "@/views/cmsPage/checkManage/recordlist/_components/ExpertRateCheckListPopup/index.vue"

export default {
  components: {
    TableList, ExpertRateCheckListPopup
  },
  data() {
    return {
      data: [],
      dqCheckPayTypeList,
      dqCheckPayTypeListByAD,
      dqCheckResultTypeList,
      dqCheckStatusTypeList,
      dqCheckResultAfterByBuyerTypeList,
      arriveTypeList: dqArriveTypeList,
      tempData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        productName: undefined,
        shortCode: undefined,
        orderNo: undefined,
        reviewStatus: undefined,
        buyerSelectionQfterInitialReview: undefined,
        receiveConfirmStatus: 1,
        personApplyNo: undefined,
        ratingABCDType: undefined,
        userName: undefined,
      },
    };
  },
  async mounted() {
    await this.getList()
    scanCodeWatch(this.scanCodeEvent)
  },
  methods: {
    /** 导出数据 */
    handleShowExpertData() {
      this.$refs.expertRateCheckListPopupEl.show()
    },
    async scanCodeEvent(code) {
      if (code) {
        this.params.barCode = code
        await this.getList()
      }
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/qualityzhandian/listQuality", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
