
/** 解析拍品瑕疵相关---str => json */
export  const productRemarkStringParseToJson = (str) => {
    if (!str) return
    try {
        const jsonData = JSON.parse(str)
        return {
            dqRemark: jsonData.coinStateRemark,
            userRemark: jsonData.customRemark
        }
    } catch (e) {
        return str
    }
}