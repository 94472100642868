var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        [
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "150px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择大币种",
                dropdownMenuStyle: { "max-height": "500px" }
              },
              on: {
                select: _vm.changeVersionlistSearch,
                search: _vm.handleChangeSearchBigCoin
              },
              model: {
                value: _vm.params.coinName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinName", $$v)
                },
                expression: "params.coinName"
              }
            },
            _vm._l(_vm.screenList, function(item) {
              return _c(
                "a-select-option",
                { key: item.coinName, attrs: { value: item.coinName } },
                [
                  _vm._v(
                    _vm._s(
                      item.coinName +
                        "【" +
                        item.id +
                        "】" +
                        (item.coinCategory ? "---" + item.coinCategory : "")
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "200px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "请选择版别"
              },
              on: { select: _vm.selectVersionSearch },
              model: {
                value: _vm.params.versionId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "versionId", $$v)
                },
                expression: "params.versionId"
              }
            },
            _vm._l(_vm.versionList, function(item) {
              return _c(
                "a-select-option",
                { key: item.versionId, attrs: { value: item.versionId } },
                [_vm._v(_vm._s(_vm._f("versionText")(item)))]
              )
            }),
            1
          ),
          _c("a-input", {
            staticStyle: { width: "220px" },
            attrs: { allowClear: "", size: "small", placeholder: "别名搜索" },
            model: {
              value: _vm.params.aliasName,
              callback: function($$v) {
                _vm.$set(_vm.params, "aliasName", $$v)
              },
              expression: "params.aliasName"
            }
          }),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px", margin: "5px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                size: "small",
                placeholder: "推送状态"
              },
              model: {
                value: _vm.params.markType,
                callback: function($$v) {
                  _vm.$set(_vm.params, "markType", $$v)
                },
                expression: "params.markType"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [
                _vm._v("未推送")
              ]),
              _c("a-select-option", { attrs: { value: 1 } }, [
                _vm._v("标注已推送")
              ]),
              _c("a-select-option", { attrs: { value: 2 } }, [
                _vm._v("样本库已推送")
              ])
            ],
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px", margin: "5px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                size: "small",
                placeholder: "推荐程度"
              },
              model: {
                value: _vm.params.featuredStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "featuredStatus", $$v)
                },
                expression: "params.featuredStatus"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [_vm._v("普通")]),
              _c("a-select-option", { attrs: { value: 1 } }, [
                _vm._v("精彩/精选")
              ])
            ],
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px", margin: "5px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                size: "small",
                placeholder: "机器对版"
              },
              model: {
                value: _vm.params.duibanStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "duibanStatus", $$v)
                },
                expression: "params.duibanStatus"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [
                _vm._v("未对版")
              ]),
              _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("已对版")])
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "Are you sure confirm this item?",
                "ok-text": "Yes",
                "cancel-text": "No",
                disabled: _vm.isDisabled
              },
              on: {
                confirm: function($event) {
                  return _vm.batchSend()
                }
              }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { disabled: _vm.isDisabled, size: "small" }
                },
                [_vm._v("批量推送标注")]
              )
            ],
            1
          ),
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "Are you sure confirm this item?",
                "ok-text": "Yes",
                "cancel-text": "No",
                disabled: _vm.isDisabled
              },
              on: {
                confirm: function($event) {
                  return _vm.batchDelete()
                }
              }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    disabled: _vm.isDisabled,
                    size: "small",
                    type: "danger"
                  }
                },
                [_vm._v("批量删除")]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "page-body" },
      [
        _c("PageTable", {
          ref: "table",
          attrs: {
            list: _vm.list,
            loading: _vm.loading,
            pagination: _vm.pagination
          },
          on: {
            selectChange: _vm.onSelectChange,
            changePage: _vm.changePage,
            success: _vm.getList,
            deleteItem: _vm.deleteItem
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }