<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.barCode"
          class="w-200"
          placeholder='快速搜索'
      ></a-input>
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.coinKindId"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindName + `【${item.id}】` }}</a-select-option>
      </a-select>
      <!-- 选择小币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.coinKindItemId"
          placeholder="请选择小币种"
      >
        <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindItemName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          class="w-250 ml-5"
          v-model="params.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
      >
        <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
        >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.imgNatureType"
          placeholder="性质"
      >
        <a-select-option
            v-for="item of natureTypeList"
            :key="item.id"
            :value="item.id"
        >{{ item.natureName }}</a-select-option>
      </a-select>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          class="ml-20"
          @click="handleCoinBookByAddImg"
      >录入数据</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          :img-type-list="tableImgTypeList"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
<!--  新增图库  -->
    <AddOrEditorPopup ref="addOrEditorPopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/coinBookManage/ImgStoreManage/_components/TableList/index.vue"
import {getListByCoinBook} from "@/views/cmsPage/coinBookManage/ImgStoreManage/_apis"
import untils from "@/untils"
import {getBigCoinList, getBookImgType, getCoinVersionList, getSmallCoinList} from "@/axios/apis"
import AddOrEditorPopup from "@/views/cmsPage/coinBookManage/ImgStoreManage/_components/AddOrEditorPopup/index.vue"

export default {
  components: {
    TableList, AddOrEditorPopup
  },
  data() {
    return {
      untils,
      data: [],
      tempData: [],
      // 大币种了列表
      coinList: [],
      // 小币种列表
      coinItemList: [],
      // 版别列表
      versionList: [],
      // 性质列表
      natureTypeList: [],
      tableImgTypeList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        coinKindId: undefined,
        coinKindItemId: undefined,
        coinKindItemVersionId: undefined,
        imgNatureType: undefined
      }
    };
  },
  async mounted() {
    await this.getImgTypeList();
    await this.getList()
  },
  methods: {
    /** 获取图片性质 */
    async getImgTypeList() {
      const res = await getBookImgType();
      this.$loading.hide()
      if (res.status !== "200") return;
      this.natureTypeList = res.data.records;
      this.tableImgTypeList = this.natureTypeList.map(el => {
        return {value: el.id, name: el.natureName}
      })
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    async search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      await this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getListByCoinBook(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },

    /** 大币种改变 */
    async changeCoin() {
      delete this.params.coinKindItemId;
      delete this.params.coinKindItemVersionId;
      if (!this.params.coinKindId) return;
      const coin = this.coinList.find((el) => {
        return el.id === this.params.coinKindId;
      });
      this.$set(this.params, "coinSid", coin.sid);
      this.$set(this.params, "coinKindName", coin.coinKindName);
      await this.coinItemSearch();
    },
    /** 选择小币种 */
    async changeCoinItem() {
      delete this.params.coinKindItemVersionId;
      if (!this.params.coinKindItemId) return;
      await this.getVersionType(this.params.coinKindId, this.params.coinKindItemId)
    },

    /** 搜索，大币种搜索 */
    async coinSearch(str) {
      if (!str) return;
      const res = await getBigCoinList(str)
      if (res.status !== "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    /** 搜索小币种 +版别 */
    async coinItemSearch() {
      const res = await getSmallCoinList(this.params.coinSid)
      if (res.status !== 'SUCCESS') return
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    /** 获取版别 */
    async getVersionType(coinId, coinItemId) {
      const res = await getCoinVersionList(coinId, coinItemId)
      if (res.status !== '200') return;
      this.versionList = res.data;
    },
    /** 新增数据 */
    handleCoinBookByAddImg() {
      this.$refs.addOrEditorPopupEl.show()
    }
  }
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
