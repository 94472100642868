<template>
  <div>
    <a-modal
        :width="1100"
        v-model="showPopup"
        title="修改评级价格"
        @cancel="handleCancel"
    >
      <template #footer v-if="!confirmStatus">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" @click="handleConfirm">确定</a-button>
      </template>
      <template #footer v-else></template>
     <div class="popup-content">
       <div class="content-top">
         <div class="w-100-w flex-start-center">
           <div class="cell-text">数量：<span class="color-black">{{ popupData.coinAmount }}</span></div>
           <div class="cell-text ml-10">是否需要对版：<span :class="confirmClassType[popupData.duibanService]">{{ dTypeVersionStatusTypeText(popupData.duibanService) }}</span></div>
         </div>
         <div class="w-100-w">
           <div v-for="(el, ind) in popupData.payArr" :key="ind" class="mt-10 flex-start-center">
             <div class="cell-text ml-10">支付金额：<span class="color-red">{{ el.payMoney }}</span></div>
             <div class="cell-text ml-10">支付状态：<span :class="dTypePayStatusClassType[el.payStatus]">{{ dTypePayStatusTypeText(el.payStatus) }}</span></div>
             <div class="cell-text ml-10">支付时间：<span class="color-black">{{ el.payTime }}</span></div>
           </div>
         </div>
         <div class="mt-10">地址：<span class="cur-pot color-gary">{{ popupData.targetAddress }}</span></div>
         <div class="mt-20">
           <a-textarea
               placeholder='售后定责描述'
               v-model="reviewRemarks"
           ></a-textarea>
           <div class="color-red">*请谨慎填写</div>
         </div>
       </div>
       <a-divider>以下为对应钱币数据</a-divider>
       <div class="content-bottom">
         <div class="content-bottom-item mt-20" v-for="(item, index) in popupData.boxCodeArr" :key="item.id">
           <div class="w-100-w flex-start-center flex-wrap bottom-item-top">
             <div class="cell-text"><span class="color-black">{{ index + 1 + '、' }}</span>名称版别：<span class="color-black">{{ (item.title || '暂无信息') + ' ' + (item.version || '') }}</span></div>
             <div class="cell-text ml-10">评级编号：<span class="color-green">{{ item.boxCode }}</span></div>
             <div class="cell-text ml-10">赔付等级：<span class="color-green">{{ item.payLevel || '暂无信息' }}</span></div>
             <div class="cell-text ml-10">评级状态：<span :class="rateServiceResultStatusClassType[item.cistrue]">{{ rateServiceResultStatusTypeList[item.cistrue] || '暂无信息' }}</span></div>
             <div class="cell-text ml-10">分数：<span class="color-green">{{ item.score || '暂无信息' }}</span></div>
             <div class="cell-text ml-10">描述：<span class="color-gray">{{ item.remark || '暂无信息' }}</span></div>
             <a-button
                 v-if="item.boxCode"
                 type="primary"
                 class="ml-10"
                 size="small"
                 @click="handleShowChangeRatingCodePopup(item.boxCode, item.id)"
             >修改评级编号</a-button>
             <a-button v-if="!item.qualityInspectionImage" class="ml-40" size="small" key="reset" @click="getDTypeDetail">重新获取</a-button>
             <a-popconfirm v-if="item.qualityInspectionImage" title="确定刷新评级结果？" @confirm="handleRefreshRateResult(item.boxCode)">
               <a-button class="ml-40" key="refresh" type="danger" size="small">刷新评级结果</a-button>
             </a-popconfirm>
           </div>
           <div class="mt-10 flex-start-center">
             <div class="cell-text">
               评级费用：
               <a-input
                   v-model="editorPriceObj[item.id].ratingPrice"
                   placeholder="填写评级费用"
                   class="w-100"
                   type="number"
               ></a-input>
             </div>
             <div v-if="popupData.duibanService" class="cell-text ml-10">
               对版费用：
               <a-input
                   v-model="editorPriceObj[item.id].duibanPrice"
                   placeholder="填写对版费用"
                   class="w-100"
                   type="number"
               ></a-input>
             </div>
           </div>
           <div class="mt-10 img-list" v-if="item.qualityInspectionImage">
             <img
                 v-for="(img, ind) in item.qualityInspectionImage.concat(item.officialWebsiteImage || [])"
                 :key="ind"
                 :src="img"
                 alt=""
                 @click="handleShowBigImg(item.qualityInspectionImage.concat(item.officialWebsiteImage || []), ind)"
             >
           </div>
         </div>
       </div>
     </div>
    </a-modal>
<!--  编辑评级编号  -->
    <EditorRatingCodePopup
        ref="editorRatingCodePopupEl"
        rate-type="D"
        @change="getDTypeDetail"
    />
  </div>
</template>
<script>
import {getDTypeDetailData, getRefreshRateResultApi, postConfirmPriceByDType} from "@/views/cmsPage/checkManage/_apis"
import {
  CONFIRM_CLASS_TYPE,
  D_TYPE_PAY_STATUS_CLASS_TYPE,
  D_TYPE_RATE_PRICE_TYPE,
  D_TYPE_VERSION_PRICE_TYPE,
  dTypePayStatusTypeMapText,
  dTypeVersionStatusTypeMapText,
  rateServiceResultStatusClassType,
  rateServiceResultStatusTypeList
} from "@/views/cmsPage/checkManage/_data"
import EditorRatingCodePopup from "@/views/cmsPage/checkManage/_components/EditorRatingCodePopup"

export default {
  components: {EditorRatingCodePopup},
  data() {
    return {
      confirmStatus: 0,
      rateServiceResultStatusTypeList,
      rateServiceResultStatusClassType,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      showPopup: false,
      params: {
        ratingId: undefined
      },
      popupData: '',
      editorPriceObj: {},
      dTypePayStatusClassType: D_TYPE_PAY_STATUS_CLASS_TYPE,
      confirmClassType: CONFIRM_CLASS_TYPE,
      reviewRemarks: undefined
    }
  },
  computed: {
    dTypeVersionStatusTypeText() {
      return (value) => dTypeVersionStatusTypeMapText(value)
    },
    dTypePayStatusTypeText() {
      return value => dTypePayStatusTypeMapText(value)
    }
  },
  methods: {
    /** 刷新评级过 */
    async handleRefreshRateResult(boxCode) {
      this.$loading.show()
      const res = await getRefreshRateResultApi({boxCode: boxCode})
      if (res.status !== '200') return
      this.$message.info(res.message || '成功')
      setTimeout(async () => {
        await this.getDTypeDetail()
      }, 1500)
    },
    /** 修改评级编号 */
    handleShowChangeRatingCodePopup(ratingCode, id) {
      const tempData = {
        ratingCode: ratingCode,
        ratingId: id
      }
      this.$refs.editorRatingCodePopupEl.show(tempData)
    },
    async show(data, confirmStatus) {
      this.editorPriceObj = {}
      this.reviewRemarks = undefined
      this.confirmStatus = confirmStatus
      this.params.ratingId = data
      await this.getDTypeDetail()
      this.showPopup = true
    },
    /** 获取详情信息 */
    async getDTypeDetail() {
      this.$loading.show()
      const res = await getDTypeDetailData(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      const tempData = res.data
      tempData.boxCodeArr.forEach(el => {
        if (tempData.duibanService) {
          this.editorPriceObj[el.id] = {
            id: el.id,
            ratingPrice: this.confirmStatus ? el.ratingPrice : (D_TYPE_RATE_PRICE_TYPE[el.payLevel] || 50),
            duibanPrice: this.confirmStatus ? el.duibanPrice : (D_TYPE_VERSION_PRICE_TYPE[el.payLevel] || 10)
          }
        } else {
          this.editorPriceObj[el.id] = {
            id: el.id,
            ratingPrice: this.confirmStatus ? el.ratingPrice : (D_TYPE_RATE_PRICE_TYPE[el.payLevel] || 50),
          }
        }
      })
      this.popupData = res.data
    },
    /** 确定 */
    async handleConfirm() {
      const items = Object.values(this.editorPriceObj)
      const hasValues = items.every(item => Object.values(item).every(value => value !== '' || value != null || value !== undefined))
      if (!hasValues) return this.$message.warn('有一组数据评级费用，或者对版费用不能为空')
      const tempParams = {
        ratingId: this.params.ratingId,
        reviewRemarks: this.reviewRemarks,
        items: items
      }
      const res = await postConfirmPriceByDType(tempParams)
      if (res.status !== '200') return
      this.$message.info('成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 查看大图 */
    handleShowBigImg(list, ind) {
      const imgArray = list.map(el => {
        return { img_url: el }
      })
      this.$previewImg({
        list: imgArray,
        baseImgField: "img_url",
        showMute: false,
        current: ind
      });
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        ratingId: undefined
      }
      this.showPopup = false
    },
  }
}
</script>
<style scoped lang="scss">
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 150px;
    height: 150px;
    border-radius: 4px;
  }
}
.content-top {
  width: 100%;
}
.popup-content {
  width: 100%;
}
.cell-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  span {
    font-size: 20px;
    font-weight: bold;
  }
}
.content-null {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 20px;
}
</style>