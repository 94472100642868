<template>
  <a-modal
      v-model="showModel"
      :width='500'
      title="质检记录导出"
      cancel-text="取消"
      @cancel="cancelPushModal"
  >
    <div class="push-modal">
      <div>
        <a-date-picker
            format="YYYY-MM-DD"
            :locale="locale"
            @change="handleDownloadChangeDate"
        />
        <a-button
            icon="download"
            class="btn ml-20"
            type="primary"
            :loading="loadingBtn"
            @click="handleDownloadData"
        >{{ btnText }}</a-button>
      </div>
    </div>
    <div slot="footer">
      <a-button @click="cancelPushModal">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import {listDownloadExcel} from "@/untils/listDownloadExcel"
import {getExpertRateCheckListApi} from "@/views/cmsPage/checkManage/recordlist/_apis"
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

export default {
  data() {
    return {
      locale,
      btnText: '质检记录数据导出',
      loadingBtn: false,
      showModel: false,
      titleName: '质检记录',
      times: [],
      params: {
        qualityDay: undefined,
      }
    };
  },
  methods: {
    /** 导出文件 */
    async handleDownloadData() {
      if (!this.params.qualityDay) return this.$message.warning('请选择导出日期')
      this.$loading.show()
      this.loadingBtn = true
      this.btnText = '导出中...'
      const res = await getExpertRateCheckListApi(this.params)
      this.$loading.hide()
      this.loadingBtn = false
      this.btnText = '质检记录数据导出'
      if (res.status !== '200') return this.$message.warning('导出超时，请再次尝试')
      if (!res.data.length) return this.$message.warning('暂无数据')
      // return
      listDownloadExcel(res.data, this.titleName, '', this.params.qualityDay)
    },
    /** 展示弹窗 */
    async show() {
      this.params = {
        qualityDay: undefined,
      }
      this.titleName = '质检记录'
      this.showModel = true
    },
    /** 改变日期 */
    handleDownloadChangeDate(date, dateString) {
      this.params.qualityDay= dateString
    },
    // 关闭
    cancelPushModal() {
      this.showModel = false
    },
  },
};
</script>

<style lang="scss" scoped>

</style>