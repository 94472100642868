import instance from "@/axios"

/** 获取物流信息 */
export const getDuiBanModelLabelListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/list',
        method: 'GET',
        params: params
    })
}

export const delDuiBanModelLabelListApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/deleteByIds',
        method: 'GET',
        params: {
            ids: params
        }
    })
}

export const getCheckModelLabelApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/recoCoinSingleCategory',
        method: 'GET',
        params: {
            id: params
        }
    })
}

/** 角度，清晰度，锈迹检测 */
export const getCheckModelLabelRustOrAngleOrClarityApi = async (params, type) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/recoLabel',
        method: 'GET',
        params: {
            id: params,
            recoModel: type
        }
    })
}

/** 提交特征收集 */
export const postModelLabelInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/updateLabelVaue',
        method: 'post',
        data: params
    })
}
/** 标记为不确定 */
export const postModelLabelNotConfirmApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/updateConfirmStatus',
        method: 'post',
        data: params
    })
}

/** 获取裁剪图 */
export const getModelLabelCutImg = async (ids) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/labelDataCutImage',
        method: 'get',
        params: {
            ids: ids
        }
    })
}

/** 币种确认 */
export const postCoinConfirmApi = async (id) => {
    return instance({
        url: '/dq_admin/duibanRecoModelLabel/coinConfirm',
        method: 'get',
        params: {
            id: id
        }
    })
}