<template>
  <div class="content">
    <div class="top">
      <a-input allowClear v-model="params.fileName" class="w-400 mb-5" placeholder='标题搜索'></a-input>
      <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/dqSys/OSSFileMange/_components/TableList/index.vue"
import {getOSSFileListApi} from "@/views/cmsPage/dqSys/OSSFileMange/_apis"

export default {
  components: {
    TableList
  },
  data() {
    return {
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        fileName: undefined, // 文件名
      },
    };
  },
  async created() {
    await this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.getList();
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getOSSFileListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
