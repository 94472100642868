import {getUserInfoByUserIdOrUserNameApi} from "@/axios/apis"

export default {
    data() {
        return {
            userInfoList: [],
        }
    },
    methods: {
        /** 用户搜索 */
        async searchUserInfo(inner, type) {
            if (!inner) return
            const res = await getUserInfoByUserIdOrUserNameApi(inner)
            if (res.status !== "200") return
            const { records } = res.data;
            this.userInfoList = records
        },
    }
}