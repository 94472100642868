<template>
  <a-modal
      v-model="showPopup"
      title="编辑店铺信息"
      :width="800"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="店铺名称">
          <a-input
              allowClear
              v-model="params.shopName"
              placeholder='店铺名称'
          ></a-input>
        </a-form-item>
        <a-form-item label="店铺介绍">
          <a-input
              allowClear
              v-model="params.shopInfo"
              placeholder='店铺介绍'
          ></a-input>
        </a-form-item>
        <a-form-item label="用户姓名">
          <a-input
              allowClear
              v-model="params.refundUserName"
              placeholder='用户姓名'
          ></a-input>
        </a-form-item>
        <a-form-item label="用户手机号">
          <a-input
              allowClear
              v-model="params.refundUserPhone"
              placeholder='用户手机号'
          ></a-input>
        </a-form-item>
        <a-form-item label="省市区" v-if="!customAreaStatus">
          <a-cascader
              class="w-500"
              :value="defaultList"
              :options="cityOptions"
              :fieldNames="addressListAttMapNewAtt"
              placeholder="请选择省市区"
              :popupStyle="{height: '400px'}"
              @change="handleChangeArea"
          />
          <a-button class="ml-10" type="danger" @click="handleUserCustomerAddress" size="small">地址没找到</a-button>
        </a-form-item>
        <a-form-item label="自定义省市区" v-else>
          <a-input allowClear v-model="params.refundProvince" class="w-150" placeholder='输入省份'/><span class="font-weight-bold color-black mr-10">省</span>
          <a-input allowClear v-model="params.refundCity" class="w-150" placeholder="输入市区"/><span class="font-weight-bold color-black mr-10">市</span>
          <a-input allowClear v-model="params.refundCounty" class="w-100" placeholder='输入县区'/><span class="font-weight-bold color-black">区</span>
          <a-button class="ml-10" type="danger" size="small" @click="handleCancelCustomAddress">取消自定义</a-button>
        </a-form-item>
        <a-form-item label="详情地址信息">
          <a-input
              allowClear
              v-model="params.refundUserAddress"
              placeholder='地址详情'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>

import {cityOptions} from "@/untils/cityOption"
import {addressListAttMapNewAtt} from "@/_data"

export default {
  data() {
    return {
      customAreaStatus: false,
      addressListAttMapNewAtt,
      cityOptions,
      showPopup: false,
      params: {
        id: undefined,
        shopName: undefined,
        shopInfo: undefined,
        refundUserAddress: undefined,
        refundProvince: undefined,
        refundCity: undefined,
        refundCounty: undefined,
        refundUserPhone: undefined,
        refundUserName: undefined,
      },
      defaultList: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      }
    }
  },
  methods: {
    /** 取消自定义地址 */
    handleCancelCustomAddress() {
      this.customAreaStatus = false
    },
    /** 自定义地址省市区 */
    handleUserCustomerAddress() {
      this.customAreaStatus = true
    },
    /** 改变地址选择 */
    handleChangeArea(e) {
      this.defaultList = e
    },
    /** 确定修改 */
    async handleConfirmShopTag() {
      this.$emit('setShopTagText', {id: this.baseInfo.id})
    },
    /** 确定当前星级 */
    async handleConfirm() {
      if (!this.params.shopName) return this.$message.warn('店铺名称不能为空')
      if (!this.params.shopInfo) return this.$message.warn('店铺描述不能为空')
      if (!this.params.refundUserName) return this.$message.warn('用户姓名不能为空')
      if (!this.params.refundUserPhone) return this.$message.warn('用户手机号不能为空')
      if (this.defaultList.length <= 0 && !this.customAreaStatus) return this.$message.warn('请选择省市区')
      if (!this.params.refundUserAddress) return this.$message.warn('请输入地址详情')
      if (!this.customAreaStatus) {
        this.params.refundProvince = this.defaultList[0]
        this.params.refundCity = this.defaultList[1]
        if (this.defaultList[2]) {
          this.params.refundCounty = this.defaultList[2]
        }
      } else {
        if (!this.params.refundProvince) return this.$message.warning('省份必须填写')
        if (!this.params.refundCity) return this.$message.warning('市区必须填写')
        if (!this.params.refundCounty) return this.$message.warning('县区必须填写')
      }
      const res = await this.axios.post('/dq_admin/userShopInfo/editShop',{
        ...this.params
      })
      if (res.status !== '200') return
      this.$message.success('编辑成功')
      this.$emit('setBaseInfo', this.params)
      this.handleCancel()
    },
    /** 展示信息 */
    show(data) {
      if (data.refundProvince) {
        this.defaultList = [data.refundProvince, data.refundCity, data.refundCounty]
      }
      this.params = data
      this.showPopup = true
    },
    /** 关闭信息 */
    handleCancel() {
      this.params = {
        id: undefined,
        shopName: undefined,
        shopInfo: undefined,
        refundUserAddress: undefined,
        refundProvince: undefined,
        refundCity: undefined,
        refundCounty: undefined,
        refundUserPhone: undefined,
        refundUserName: undefined,
      }
      this.defaultList = []
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>