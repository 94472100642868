<template>
  <a-drawer
      title="待提现货款明细"
      placement="right"
      :closable="true"
      :visible="showDrawer"
      width="900"
      @close="onClose"
  >
    <div class="shop-price-line-content">
      <div class="total-price-text">
        <div class="w-100-w flex-center-center color-red font-weight-bold font-size-32">待提现总金额：￥{{ totalPrice.totalMoney }}</div>
      </div>
      <a-table
          :columns="tableList"
          :pagination="pagination"
          :data-source="shopPriceLineList"
          :rowKey="(record, index) => index"
          @change="handleChangePage"
      >
<!-- 订单 -->
        <div slot="itemOrderNoSlot" slot-scope="row">
          <div class="color-blue cur-pot" @click="handleGoToOrder(row.orderNo)">{{ row.orderNo }}</div>
        </div>
<!--  处理状态      -->
        <div slot="itemRecFlagSlot" slot-scope="row">
          <div :class="recFlagClassType[row.recoFlag].classType">{{ recFlagClassType[row.recoFlag].text }}</div>
        </div>
      </a-table>
    </div>
  </a-drawer>
</template>
<script>

import {recFlagClassType, SHOP_PRICE_LINE_TABLE_COLUMNS} from "@/views/cmsPage/shopManage/_data"

export default {
  components: {},
  data() {
    return {
      recFlagClassType,
      tableList: SHOP_PRICE_LINE_TABLE_COLUMNS,
      showDrawer: false,
      shopPriceLineList: [],
      totalPrice: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      userId: ''
    }
  },
  methods: {
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/newordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 获取总金额 */
    async getShopTotalPrice() {
      const res = await this.axios("/dq_admin/orderProduct/confirmOrderWaitWithdrawalTotalMoney", {
        params: {userId: this.userId},
      })
      if (res.status != 200) return
      this.totalPrice = res.data
    },
    /** 获取明细列表数据 */
    async getShopPriceLine() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/orderProduct/confirmOrderWaitWithdrawal", {
        params: {...this.params, userId: this.userId},
      })
      this.$loading.hide()
      if (res.status != 200) return
      this.shopPriceLineList = res.data.records
      this.pagination.total = res.data.total
    },
    /** 展示信息 */
    async show(data) {
      this.userId = data.userId
      await this.getShopTotalPrice()
      await this.getShopPriceLine()
      this.showDrawer = true
    },
    /** 关闭信息 */
    onClose() {
      this.showDrawer = false
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getShopPriceLine()
    },
  }
}
</script>
<style lang="scss" scoped>

</style>