import instance from "@/axios"

/** 获取订单列表数据API */
export const getOrderListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/list',
        method: 'GET',
        params: params
    })
}
/** 获取订单列表数据API */
export const getOrderDetailInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/getDetail',
        method: 'GET',
        params: params
    })
}

/** 延长收货 */
export const postExtendTakeOverProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/bidOrderDelay',
        method: 'GET',
        params: params
    })
}
/** 一键退款 */
export const postConfirmReturnPriceByOneKeyApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/bidOrderNotSendRefund',
        method: 'post',
        data: params
    })
}
/** 添加备注 */
export const postAddNotesApi = async (params) => {
    return instance({
        url: '/dq_admin/orderLog/add',
        method: 'post',
        data: params
    })
}

/** 取消评级转直发 */
export const postCancelRateServiceToSendBuyerApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/cancelRatingToDirectMode',
        method: 'get',
        params: params
    })
}
/** 退标版费 */
export const postReturnRateServiceVersionPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/returnBiaobanMoney',
        method: 'get',
        params: params
    })
}
/** 修改标版费 */
export const postChangeRateServiceVersionPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/addMarkingPlate',
        method: 'post',
        data: params
    })
}

/** 获取备注列表 */
export const getServiceNoteListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderRemark/list',
        method: 'get',
        params: params
    })
}

/** 获取备注列表 */
export const postAddServiceNoteApi = async (params) => {
    return instance({
        url: '/dq_admin/orderRemark/add',
        method: 'post',
        data: params
    })
}

/** 发起退货退款申请获取最新买卖双方费率 */
export const getBuyerOrSellerServiceRateApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/calculateChangeRefundType',
        method: 'post',
        data: params
    })
}

/** 确定申请退货退款 */
export const postConfirmApplyReturnPriceOrReturnProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/platformOpenRefund',
        method: 'post',
        data: params
    })
}

/** 同步支付订单 */
export const postSyncUserPayOrderStatusApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/synchronizationPayStatus',
        method: 'get',
        params: params
    })
}

/** 同步线下支付订单 */
export const postSyncUserOfflinePayOrderStatusApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/offinePaySynchronizationPayStatus',
        method: 'get',
        params: params
    })
}

/** 获取用户线上下单快递信息数据 */
export const getUserOnLineCreateLogisticsInfoApi = async (logisticsNum) => {
    return instance({
        url: '/dq_admin/kdLogisticsDetail/getByKuaidiNum',
        method: 'get',
        params: {
            kuaidiNum: logisticsNum
        }
    })
}

/** 创建送评申请 */
export const postCreateRateServiceApplyApi = async (params) => {
    return instance({
        url: '/dq_admin/identificationResults/refundToRatingReason',
        method: 'get',
        params: params
    })
}