export const base64 = (s) => {
    return window.btoa(unescape(encodeURIComponent(s)));
}

/** 根据列表下载excel */
export const listDownloadExcel = (jsonData, titleName, filterStr, titleNameTime) => {

    if (filterStr) {
        jsonData.forEach(el => {
            // 从每个对象中删除需要过滤的字段
            delete el[filterStr]
        });
    }

    let str = "<tr style='text-align:center'>";
    const objKeys = Object.keys(jsonData[0])
    objKeys.forEach(key => {
        str += `<td>${ key }</td>`
    })
    // 循环遍历，每行加入tr标签，每个单元格加td标签
    for (let i = 0; i < jsonData.length; i++) {
        str += `<tr style='text-align:center'>`
        objKeys.forEach(key => {
            str += `<td>${jsonData[i][key] + "\t"}</td>`
        })
    }

    // Worksheet名
    const worksheet = "斗泉";
    const uri = "data:application/vnd.ms-excel;base64,";

    // 导出的表格模板数据
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;
    // 导出模板
    let DateTime = new Date()
    let month= (DateTime.getMonth() + 1)
    let day = DateTime.getDate()
    let time = DateTime.getFullYear() + '-' + (String(month).length > 1 ? month : '0' + month) + '-' + (String(day).length > 1 ? day : '0' + day)
    let fileName
    if (titleNameTime) {
        fileName = titleName + '-' + titleNameTime
    } else {
        fileName = titleName + '-' + time
    }
    const href = uri + base64(template)
    let link = document.createElement('a');
    link.setAttribute('href', href);
    link.setAttribute('download', `${fileName}.xls`);
    link.click();
}