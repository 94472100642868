<template>
  <a-modal v-model="showPopup" title="添加币种模型版本" :width="500" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model v-bind="layout">
      <a-form-item label="选择大币种">
        <a-select
            :dropdownMatchSelectWidth="false"
            @search="coinSearch($event, '', [])"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            class="w-250"
            v-model="params.coinId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName + `【${item.id}】` + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="版本号">
        <a-input
            allowClear
            v-model="params.recoVersion"
            class="w-200"
            placeholder='版本号'
        ></a-input>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {postCoinModelVersionApi} from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_apis"
import untils from "@/untils"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  emits: ['success'],
  data() {
    return {
      untils,
      showPopup: false,
      layout: {
        labelCol: { span: 6 }, //标签占5份  共24份
        wrapperCol: { span: 18 },
      },
      params: {
        coinId: undefined,
        recoVersion: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.showPopup = true
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.coinId) return this.$message.warning('请选择大币种')
      if (!this.params.recoVersion) return this.$message.warning('请输入版本号')
      this.$loading.show()
      const res = await postCoinModelVersionApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        coinId: undefined,
        recoVersion: undefined,
      }
      this.showPopup = false
    },
  }
}
</script>