var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { centered: "", width: 700, title: _vm.title },
      on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "用户Id", prop: "content" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        closable: "",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "搜索发起用户昵称或userId"
                      },
                      on: {
                        search: function($event) {
                          return _vm.searchUserInfo($event, "userId")
                        }
                      },
                      model: {
                        value: _vm.forms.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "userId", $$v)
                        },
                        expression: "forms.userId"
                      }
                    },
                    _vm._l(_vm.userInfoList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.userId } },
                        [
                          _vm._v(
                            _vm._s(item.userName + "【" + item.userId + "】")
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.selectedCoinList && _vm.selectedCoinList.length
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "已选择", prop: "content" } },
                    [
                      _vm._l(_vm.selectedCoinList, function(item) {
                        return [
                          _c(
                            "a-tag",
                            {
                              key: item.coinKindId,
                              attrs: { color: "blue", closable: "" },
                              on: {
                                close: function($event) {
                                  return _vm.handleDeleteTag($event, item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.coinKindName))]
                          )
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择大币种", prop: "degree" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        mode: "multiple",
                        placeholder: "请选择"
                      },
                      on: { search: _vm.searchData },
                      model: {
                        value: _vm.coinIds,
                        callback: function($$v) {
                          _vm.coinIds = $$v
                        },
                        expression: "coinIds"
                      }
                    },
                    _vm._l(_vm.bigList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [
                          _vm._v(
                            _vm._s(
                              item.coinKindName +
                                (item.coinCategory
                                  ? "---" + item.coinCategory
                                  : "")
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "用户标版单价" } },
                [
                  _c("a-input", {
                    attrs: { type: "number", placeholder: "用户标版单价" },
                    model: {
                      value: _vm.forms.labelPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "labelPrice", $$v)
                      },
                      expression: "forms.labelPrice"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }