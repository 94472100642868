import {objArrMapObj} from "@/untils/otherEvent"

/** 版本刷选 */
export const modelVersionList = [
    {name: '通用模型', value: 'vcommon'},
    {name: '崇宁通宝_3.0', value: 'vchongningt_3'},
    {name: '乾元重宝_4.0', value: 'v_qianyuanzb_4'},
    {name: '明早期_1.0', value: 'vearly_ming_1'},
    {name: '康熙通宝_2.0', value: 'vkangxi_2'},
    {name: '乾元重宝_3.0', value: 'v_qianyuanzb_3'},
    {name: '明晚期_2.0', value: 'vlate_ming_2'},
    {name: '崇宁重宝_4.0', value: 'vchongningz_4'},
    {name: '开元通宝_19.0', value: 'vkaiyuan_19'},
    {name: '乾隆通宝_5.0', value: 'vqianlong_5'},
    {name: '乾隆通宝_3.0', value: 'vqianlong_3'},
    {name: '乾隆通宝_2.0', value: 'vqianlong_2'},
    {name: '万历通宝_1.0', value: 'v_wanli_1'},
    {name: '开元通宝_18.0', value: 'vkaiyuan_18'},
    {name: '崇祯通宝_1.0', value: 'v_chongzhen_1'},
    {name: '清辽金_1.0', value: 'v_qing_liao_jin_1'},
    {name: '开元通宝_14.0', value: 'vkaiyuan_14'},
    {name: '雍正_光绪_1.0', value: 'v_yongzheng_guangxu'},
    {name: '开元通宝_13.0', value: 'vkaiyuan_13'},
    {name: '开元通宝_12.0', value: 'vkaiyuan_12'},
    {name: '开元通宝_11.0', value: 'vkaiyuan_11'},
    {name: '开元通宝_9.0', value: 'vkaiyuan_9'},
    {name: '开元通宝_8.0', value: 'vkaiyuan_8'},
    {name: '开元通宝_7.0', value: 'vkaiyuan_7'},
    {name: '开元通宝_6.0', value: 'vkaiyuan_6'},
    {name: '开元通宝_5.0', value: 'vkaiyuan_5'},
    {name: '开元通宝_4.0', value: 'vkaiyuan_4'},
    {name: '其它模型', value: 'vother'},
    {name: '治平模型_3.0', value: 'vzhiping_3'},
    {name: '开元通宝_3.0', value: 'vkaiyuan_3'},
    {name: '辽金_1.0', value: 'dynasty_liaojin'},
    {name: '崇宁通宝_2.0', value: 'vchongningt_2'},
    {name: '熙宁模型_2.0', value: 'vxining_2'},
    {name: '祥符模型_2.0', value: 'vxiangfu_2'},
    {name: '开元通宝_2.0', value: 'vkaiyuan_2'},
    {name: '宣和模型_2.0', value: 'vxuanhe_2'},
    {name: '南宋_2.0', value: 'vnansong_2'},
    {name: '开元通宝_1.0', value: 'vkaiyuan_1'},
    {name: '崇宁重宝_3.0', value: 'vchongningz_3'},
    {name: '元祐通宝_2.0', value: 'vyuanyou_2'},
    {name: '绍圣模型_1.0', value: 'vshaosheng_1'},
    {name: '明朝模型_1.0', value: 'vmingchao_1'},
    {name: '宣统通宝_1.0', value: 'vxuantongtongbao_1'},
    {name: '周元通宝_1.0', value: 'vzhouyuantongbao_1'},
    {name: '五代十国_1.0', value: 'vwudaishiguo_1'},
    {name: '元丰通宝_4.0', value: 'vyuanfeng_4'},
    {name: '天圣天禧_2.0', value: 'vtianshengtianxi_2'},
    {name: '天圣天禧_1.0', value: 'vtianshengtianxi_1'},
    {name: '北宋模型_1.0', value: 'vbeisong_1'},
    {name: '治平模型_2.0', value: 'vzhiping_2'},
    {name: '崇宁重宝_2.0', value: 'vchongningz_2'},
    {name: '宋元/太平_2.0', value: 'vsongyuantaiping_2'},
    {name: '明道元宝_2.0', value: 'vmingdao_2'},
    {name: '元符通宝_1.0', value: 'vyuanfu_1'},
    {name: '元祐通宝_1.0', value: 'vyuanyou_1'},
    {name: '熙宁模型_1.0', value: 'vxining_1'},
    {name: '宋元通宝_2.0', value: 'vsongyuan_2'},
    {name: '大观圣宋_1.0', value: 'vdaguan_shengsong_1'},
    {name: '元丰通宝_3.0', value: 'vyuanfeng_3'},
    {name: '治平元宝_1.0', value: 'vzhiping_1'},
    {name: '明道元宝_1.0', value: 'vmingdao_1'},
    {name: '祥符模型_1.0', value: 'vxiangfu_1'},
    {name: '嘉祐模型_1.0', value: 'vjiayou_1'},
    {name: '宋元通宝_1.0', value: 'vsongyuan_1'},
    {name: '南宋_1.0', value: 'vnansong'},
    {name: '同治通宝_1.0', value: 'vtongzhi'},
    {name: '顺治通宝_1.0', value: 'vshunzhi'},
    {name: '咸丰元宝_1.0', value: 'vxianfengy'},
    {name: '咸丰重宝_2.0', value: 'vxianfengz_2'},
    {name: '咸丰重宝_1.0', value: 'vxianfengz'},
    {name: '宣和通宝_1.0', value: 'vxuanhe'},
    {name: '政和通宝_1.0', value: 'vzhenghe'},
    {name: '嘉庆通宝_1.0', value: 'vjiaqing'},
    {name: '乾隆通宝_1.0', value: 'vqianlong'},
    {name: '康熙通宝_1.0', value: 'vkangxi'},
    {name: '元丰通宝_2.0', value: 'vyuanfeng_2'},
    {name: '元丰通宝_1.0', value: 'vyuanfeng'},
    {name: '崇宁重宝_1.0', value: 'vchongningz'},
    {name: '崇宁通宝_1.0', value: 'vchongningt'},
    {name: 'v5', value: 'v5'},
    {name: 'v6', value: 'v6'},
    {name: 'v7', value: 'v7'},
]

/** 错误信息 */
export const errMsgList = [
    {value: '拍照模糊，影响准确度', id: 0},
    {value: '存疑钱币，影响准确度', id: 1},
    {value: '缺少背图，影响准确度', id: 2},
    {value: '铁钱加刀严重，不支持对版', id: 3},
    {value: '钱币角度不规范，影响准确度', id: 4},
    {value: '字体关键特征模糊，影响对版精度', id: 5},
    {value: '土块/锈迹遮挡字体特征，影响对版精度', id: 6},
    {value: '图片反光，币面不清，影响对版精度', id: 7},
    {value: '水渍钱币反光影响对版精度', id: 8},
    {value: '钱币被遮挡，拍照不规范影响对版精度', id: 9},
    {value: '暂未开放后期背双月类开元对版', id: 10},
    {value: '正反面顺序有误，影响对版精度', id: 11},
    {value: '输入其他自定义原因', id: 'auto', icon: 'edit'}
]

/** 问题来源 sourceType 80评级确版，0普通标注确版*/
export const sourceTypeTypeList = [
    {value: 80, name: '评级确版'},
    {value: 0, name: '普通标注确版'},
]
/** 问题来源样式 */
export const sourceTypeClassType = {
    0: 'color-gary',
    80: 'color-orange'
}


export const computedSourceType = (value) => {
    return objArrMapObj('value', 'name', sourceTypeTypeList)[value]
}

