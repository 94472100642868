<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.classfyTitle"
          class="w-200"
          placeholder='类别名称'
      ></a-input>
<!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @search="coinSearch"
          @change="handleChangeBigCoin"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-200 ml-5"
          v-model="params.coinId"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindName + `【${item.id}】` + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
      </a-select>

<!-- 请选择一级分类 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @change="handleChangeLevelOne"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.oneClassfyId"
          placeholder="请选择一级分类"
      >
        <a-select-option
            v-for="item of oneClassfyList"
            :key="item.id"
            :value="item.id"
        >{{ item.classfyName }}</a-select-option>
      </a-select>
<!-- 请选择二级分类 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.twoClassfyId"
          placeholder="请选择二级分类"
      >
        <a-select-option
            v-for="item of twoClassfyList"
            :key="item.id"
            :value="item.id"
        >{{ item.classfyName }}</a-select-option>
      </a-select>


      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>

      
      <a-button
          class="ml-20"
          @click="handleCoinBookByAddImg('insertOne')"
      >新增一级分类</a-button>

      <a-button
          class="ml-20"
          @click="handleCoinBookByAddImg('insertTwo')"
      >新增二级分类</a-button>

    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
    <!--  新增图库  -->
    <AddOrEditorPopup ref="addOrEditorPopupEl" @success="getList"/>

     <!--  新增二级图库  -->
    <AddOrEditorPopupTwo ref="addOrEditorPopupTwoEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/coinBookManage/classifyVersion/_components/TableList/index.vue"
import untils from "@/untils"
import {
  getOneClassfyList,
  getClassifyList,
  getTwoClassfyList
} from "@/views/cmsPage/coinBookManage/classifyVersion/_apis"
import AddOrEditorPopup from "@/views/cmsPage/coinBookManage/classifyVersion/_components/addOrEditorPopup/index.vue"
import AddOrEditorPopupTwo from "@/views/cmsPage/coinBookManage/classifyVersion/_components/addOrEditorPopupTwo/index.vue"

import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    TableList, AddOrEditorPopup,AddOrEditorPopupTwo
  },
  data() {
    return {
      untils,
      data: [],
      tempData: [],
      // 大币种了列表
      coinList: [],
      // 版别列表
      versionList: [],
      oneClassfyList:[],
      // 二级分类
      twoClassfyList: [],
      oneParams: {
        coinId: undefined
      },
      twoParams: {
        coinId: undefined,
        pid: undefined
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        coinId: undefined,
        oneClassfyId: undefined,
        twoClassfyId: undefined,
        classfyTitle: undefined
      }
    };
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    async search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      await this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getClassifyList(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 搜索，大币种搜索 */
    async coinSearch(str) {
      if (!str) return;
      const res = await getBigCoinList(str)
      if (res.status !== "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    /** 选择大币种-搜索一级分类 */
    async handleChangeBigCoin() {
      this.oneParams.coinId = this.params.coinId
      this.twoParams.coinId = this.params.coinId
      this.params.oneClassfyId = undefined
      this.params.twoClassfyId = undefined
      const res = await getOneClassfyList(this.oneParams)
      if (res.status !== '200') return;
      this.oneClassfyList = res.data
    },

    /** 选择一级分类-搜索二级分类 */
    async handleChangeLevelOne() {
      this.twoParams.pid = this.params.oneClassfyId
      const res = await getTwoClassfyList(this.twoParams)
      if (res.status !== '200') return;
      this.twoClassfyList = res.data
    },
    
    /** 新增数据 */
    handleCoinBookByAddImg(type) {
      if(type === 'insertOne'){
          this.$refs.addOrEditorPopupEl.show()
      }
       if(type === 'insertTwo'){
          this.$refs.addOrEditorPopupTwoEl.show()
      }
 
    }
  }
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
