<template>
   <!-- 推送样本库 -->
    <a-modal
      v-model="pushModal"
      :width='800'
      title="推送到底库"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelPushModal"
      @ok="confirmPushModal"
    >
      <div class="push-modal">
        <div>
          <!-- 选择大币种 -->
          <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin"
            @search="coinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="curItem.coinKindId"
            placeholder="请选择大币种"
          >
            <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
            >{{ item.coinKindName + `【${item.id}】` }}</a-select-option>
          </a-select>
          <!-- 选择小币种 -->
          <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 220px"
            v-model="curItem.coinKindItemId"
            placeholder="请选择小币种"
          >
            <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
            >{{ item.coinKindItemName }}</a-select-option>
          </a-select>
          <!-- 选择版别 -->
          <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            @change="changeVersion"
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="curItem.versionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
          >
            <a-select-option
              v-for="item of versionList"
              :key="item.id"
              :value="item.id"
            >{{ `${item.coinKindVersionName}` }}</a-select-option>
          </a-select>
          <a-checkbox class="checked" v-model:checked="isTest">是否作为测试集</a-checkbox>
        </div>
      </div>

    </a-modal>
</template>

<script>
import untils from "@/untils";
export default {
  data(){
    return {
      untils,
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      isTest: false,
      itemId: undefined,
      comeFrom: '',
      currentListId: '',
      currentItemByListData: ''
    }
  },
  methods:{
    show(data, changeTestCheckBox, itemData){
      this.itemId = data.itemId
      this.comeFrom = data.comeFrom
      if (this.comeFrom === 'sampStore') {
        this.currentItemByListData = itemData
      }
      this.curItem = JSON.parse(JSON.stringify(data));
      const {kindId, kindName, itemId, versionId, versionSid} = this.curItem
      this.$set(this.curItem, "coinKindId", kindId)
      this.$set(this.curItem, "coinKindName", kindName)
      this.$set(this.curItem, "coinKindItemId", itemId)
      this.$set(this.curItem, "versionId", versionId)
      this.$set(this.curItem, "coinKindItemVersionSid", versionSid)
      this.setDefault();
      this.isTest = !!changeTestCheckBox
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: this.curItem.coinKindName },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;

      const {versionId, coinKindItemVersionSid} = this.curItem
      const find = this.coinList.find(el => el.id === this.curItem.coinKindId)
      this.curItem.coinKindId = find.id;
      this.curItem.coinSid = find.sid;
      this.curItem.coinKindName = find.coinKindName;
      await this.coinItemSearch();
      const coinItem = this.coinItemList.find(el=>{
        return el.coinKindItemName == this.curItem.itemName
      })
      this.$set(this.curItem, "coinKindItemId", coinItem.id)
      await this.changeCoinItem(coinItem.id)
      this.$set(this.curItem, "versionId", versionId)
      this.$set(this.curItem, "coinKindItemVersionSid", coinKindItemVersionSid)
      
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    confirmPushModal() {
      if (this.curItem.type == 1) {
        this.confirmVersion()
      } else {
        this.pushStore()
      }
    },
    /** /dq_admin/duibanSampleData/updateSampleData */
    async pushStore(){
      const {recordId, versionId} = this.curItem
      let urlStr, params
      if (this.comeFrom === 'sampStore') {
        urlStr = '/dq_admin/duibanSampleData/updateSampleData'
      } else {
        urlStr = '/dq_admin/duibanUserRecord/pushAsynDiku'
      }
      if (this.comeFrom === 'sampStore') {
        const {
          id,
          coinId,
          coinKindName,
          coinKindItemName,
        } = this.currentItemByListData;
        params = {
          id,
          coinKindId: coinId,
          coinKindName,
          coinKindItemId: this.curItem.coinKindItemId,
          coinKindItemName,
          coinKindItemVersionId: versionId,
          operAsycType: 'sampleToDiku'
        }
      } else {
        params = {
          recordId: recordId,
          versionId: versionId,
          isTest: this.isTest ? 1 : 0
        }
      }
      const res = await this.axios(urlStr, {params: params});
      if (res.status != 200) return;
      this.isTest = false
      this.$emit('versionSuccess', this.itemId)
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
    },
    async confirmVersion(){
      const {recordId, coinKindItemVersionSid} = this.curItem
      const res = await this.axios(
        "/dq_admin/ratingSpliderUserAnswer/confirmVersion",
        {
          params: { 
            id: recordId, 
            coinKindItemVersionSid: coinKindItemVersionSid,
            type: 1,
            isTest: this.isTest ? 1 : 0
          },
        }
      );
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
    },
    // 大币种改变
    changeCoin(val) {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.curItem.coinSid = coin.sid;
      this.curItem.coinKindName = coin.coinKindName;

      this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.curItem.versionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion(val) {
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.versionId;
      });
      this.curItem.versionId = version.id;
      this.curItem.coinKindItemVersionSid = version.sid;
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },
    // 删除图片
    deleteImage(index) {
      this.curItem.covers.splice(index, 1);
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
  },
}
</script>

<style lang="scss" scoped>
</style>