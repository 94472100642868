<template>
  <a-modal v-model="showPopup" :width="1000" :layerTitle="popupTitle" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model v-bind="layout">
      <a-form-item label="公告标题">
        <a-input v-model="params.layerTitle" class="w-600" placeholder='请输入公告标题'></a-input>
      </a-form-item>
      <a-form-item label="公告标题内容图片">
        <UploadImage :list="titleImgList" :maxCount="1" @change="handleChangeByTitleImg"></UploadImage>
      </a-form-item>
<!--   公告开始时间  -->
      <a-form-item label="公告开始时间">
        <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            v-model="params.startTime"
            :locale="locale"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            @change="handleSelectedStartTimePicker"
        />
      </a-form-item>
<!--   公告结束时间  -->
      <a-form-item label="公告结束时间">
        <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            v-model="params.endTime"
            :disabled-date="disabledEndDate"
            :locale="locale"
            :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
            @change="handleSelectedEndTimePicker"
        />
      </a-form-item>
<!--   公告展示方式   -->
      <a-form-item label="公告展示方式">
        <a-select
            :filter-option="untils.filterOption"
            allowClear
            class="w-600"
            v-model="params.showType"
            placeholder="请选择公告的展示方式"
        >
          <a-select-option
              v-for="items of appHomeNoticeShowTimeTypeList"
              :key="items.value"
              :value="items.value"
          >{{ items.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--   公告点击遮罩层是否关闭   -->
      <a-form-item label="点击遮罩层是否关闭公告">
        <a-select
            :filter-option="untils.filterOption"
            allowClear
            class="w-600"
            v-model="params.maskCloseStatus"
            placeholder="点击遮罩层是否关闭公告"
        >
          <a-select-option
              v-for="items of noticeOnclickOverlayCloseStatusList"
              :key="items.value"
              :value="items.value"
          >{{ items.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--   排序   -->
      <!--   公告点击遮罩层是否关闭   -->
      <a-form-item label="公告排序">
        <a-input class="w-600" type="number" placeholder="公告排序" v-model="params.sort" />
      </a-form-item>
<!--   公告内容类型   -->
      <a-form-item label="公告内容类型">
        <a-select
            :filter-option="untils.filterOption"
            allowClear
            class="w-600"
            v-model="params.detailType"
            placeholder="公告内容的表现形式"
            @change="handleChangeTemplateType"
        >
          <a-select-option
              v-for="items of appHomeNoticeContentTypeList"
              :key="items.value"
              :value="items.value"
          >{{ items.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--  图片时    -->
      <a-form-item label="公告内容图片" v-if="params.detailType === 2">
        <UploadImage
            :list="contentImgList"
            :maxCount="1"
            @change="handleChangeByContentImg"
        ></UploadImage>
      </a-form-item>
      <!--  富文本时    -->
      <a-form-item label="公告内容" v-if="params.detailType === 1">
        <WangEditor :option="option" v-model="params.detailContent"/>
      </a-form-item>
      <!--  是否需要发布    -->
    </a-form-model>
  </a-modal>
</template>

<script>
import untils from "@/untils"
import UploadImage from "@/components/UploadImage/index.vue"
import WangEditor from "@/components/WangEditor/index.vue"
import {
  appHomeNoticeContentTypeList,
  appHomeNoticeShowTimeTypeList, noticeOnclickOverlayCloseStatusList
} from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_data"
import {returnAntUploadImgObj} from "@/untils/otherEvent"
import {postEditorAppHomeNoticeApi} from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_apis"
import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

export default {
  components: {UploadImage, WangEditor},
  emits: ['success'],
  data() {
    return {
      locale,
      moment,
      untils,
      option: {
        height: 500
      },
      dateFormat: 'YYYY-MM-DD',
      contentImgList: [], // 内容图片
      titleImgList: [], // 标题图片
      popupTitle: '新增首页公告',
      showPopup: false,
      appHomeNoticeContentTypeList, // 公告类型
      appHomeNoticeShowTimeTypeList, // 公告的展示方式
      noticeOnclickOverlayCloseStatusList, // 公告点击遮罩层是否可以关闭
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      params: {
        id: undefined,
        layerTitle: undefined, // 公告标题
        layerPicture: undefined, // 公告图片偏
        detailType: undefined, // 详情页类型
        detailContent: '',  // 内容富文本
        detailPicture: undefined, // 内容图片
        showType: undefined, // 公告展示方式 oneDayOne: 一天一次，alwaysShow：一直显示 ，onlyOnce仅一次
        endTime: undefined,  // 公告结束时间
        startTime: undefined,  // 公告开始时间
        maskCloseStatus: undefined, // 是否可以点击遮罩层关闭
        sort: 1,
      },
      editorType: 'add',
      editorApiUrl: {
        add: '/dq_admin/bannerNotice/addBanner',
        edit: '/dq_admin/bannerNotice/editBanner'
      }
    };
  },
  methods: {
    /** 选择时间--开始时间 */
    handleSelectedStartTimePicker(date, dateString) {
      this.params.startTime = dateString
    },
    /** 选择时间--结束时间 */
    handleSelectedEndTimePicker(date, dateString) {
      this.params.endTime= dateString
    },
    disabledEndDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    /** 改变模版类型 */
    handleChangeTemplateType(e) {
      this.params.detailContent = undefined
      this.params.detailPicture = undefined
    },
    show(data) {
      if (data && data.id) {
        this.editorType = 'edit'
        this.popupTitle = '编辑首页公告';
        this.setData(data)
      } else {
        this.editorType = 'add'
      }
      this.showPopup = true
    },
    /** 设置回显 */
    setData(data) {
      Object.assign(this.params, data)
      if (data.layerPicture) {
        this.titleImgList = returnAntUploadImgObj([data.layerPicture])
      }
      if (data.detailPicture) {
        this.contentImgList = returnAntUploadImgObj([data.detailPicture])
      }
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.layerTitle) return this.$message.warn('公告标题必须填写')
      if (this.titleImgList.length < 1) return this.$message.warn('公告标题图片必须填写')
      if (!this.params.showType) return this.$message.warn('公告展示类型不能为空')
      if (this.params.maskCloseStatus === undefined) return this.$message.warn('请选择是否点击遮罩层可以关闭弹窗')
      if (!this.params.startTime) return this.$message.warn('公告开始时间不能为空')
      if (!this.params.endTime) return this.$message.warn('公告结束时间不能为空')
      if (!this.params.detailType) return this.$message.warn('内容类型不能空')
      if (this.params.detailType === 1 && this.params.detailContent === '<p><br></p>') return this.$message.warn('富文本内容不能空')
      if (this.params.detailType === 2 && this.contentImgList.length < 1) return this.$message.warn('内容图片必须上传')

      if (this.titleImgList.length) {
        this.params.layerPicture = this.titleImgList[0].url
      }
      if (this.contentImgList.length) {
        this.params.detailPicture = this.contentImgList[0].url
      }
      if (this.params.detailType === 1) {
        this.params.detailPicture = undefined
      } else if (this.params.detailType === 2) {
        this.params.detailContent = undefined
      }

      console.log(this.params, '参数')

      this.$loading.show()
      const res = await postEditorAppHomeNoticeApi(this.editorApiUrl[this.editorType], this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.showPopup = false
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.popupTitle = '新增公告'
      this.params = {
        id: undefined,
        layerTitle: undefined,
        layerPicture: undefined,
        detailContent: '',
        detailPicture: undefined, // 内容图片
        detailType: undefined, // 内容类型
        showType: undefined, // 公告展示方式 oneDayOne: 一天一次，alwaysShow：一直显示
        endTime: undefined,  // 公告结束时间
        startTime: undefined,  // 公告开始时间
        maskCloseStatus: undefined, // 是否可以点击遮罩层关闭
        sort: 1,
      }
      this.contentImgList = []
      this.titleImgList = []
    },
    /** 改变标题图片 */
    handleChangeByTitleImg(list) {
      this.titleImgList = list
    },
    /** 改变内容图片 */
    handleChangeByContentImg(list) {
      this.contentImgList = list
    }
  },
};
</script>

<style lang="scss" scoped>
</style>