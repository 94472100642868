<template>
  <a-modal v-model="showPopup" :width='500' title="客服添加举证信息" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model ref="ruleForm" :model="params" v-bind="layout">
      <a-form-item label="图片">
        <a-upload
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="picList"
            :multiple="true"
            @preview="handlePreview"
            @change="handleChange"
        >
          <div v-if="picList.length < 6">
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="描述">
        <a-input
            allowClear
            v-model="params.proofDesc"
            type="textarea"
            :auto-size="{ minRows: 4, maxRows: 10 }"
            class="w-100-w"
            placeholder='描述'
        ></a-input>
      </a-form-item>
      <a-form-item label="发送短信">
        <a-radio-group v-model="params.noticeObject">
          <a-radio-button :value="0">买家</a-radio-button>
          <a-radio-button :value="1">卖家</a-radio-button>
          <a-radio-button :value="2">买卖双方</a-radio-button>
          <a-radio-button :value="null">无需发送</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {postConfirmAddVoucherApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"
import {mapState} from "vuex"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      picList: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        orderNo: undefined,
        proofDesc: undefined,
        noticeObject: null, // 0 买家， 1 卖家， 2 买卖双方， null 不发送
        imgList: []
      }
    }
  },
  computed: {
    ...mapState(["ip"]),
  },
  methods: {
    show(orderNo) {
      this.params.orderNo = orderNo
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.proofDesc && !this.picList.length) return this.$message.warning('凭证或描述不能为空')
      // if (!this.params.noticeObject) return this.$message.warning('请选择需要发送短信的目标')
      this.params.imgList = this.picList.map(el => el.url)
      const res = await postConfirmAddVoucherApi(this.params)
      if (res.status !== '200') return
      this.$message.info('添加成功')
      this.handleCancel()
      this.$emit('success')
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderNo: undefined,
        proofDesc: undefined,
        noticeObject: undefined,
        imgList: []
      }
      this.picList = []
      this.showPopup = false
    },
    /** 图片上传 */
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.picList = fileList;
    },
    /** 查看大图 */
    async handlePreview() {
      let urls = this.picList.map((el) => ({img_url: el.url}))
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    },
  }
}
</script>