import instance from "@/axios"

/** 获取首页公告列表 */
export const getAppHomeNoticeListApi = async (params) => {
    return instance({
        url: '/dq_admin/bannerNotice/getBannerList',
        method: 'GET',
        params: params
    })
}

/** 编辑首页弹窗公告 */
export const postEditorAppHomeNoticeApi = async (apiUrl, params) => {
    return instance({
        url: apiUrl,
        method: 'post',
        data: params
    })
}

/** 删除首页弹窗公告 */
export const delAppHomeNoticeApi = async (params) => {
    return instance({
        url: '/dq_admin/bannerNotice/delBanner',
        method: 'get',
        params: params
    })
}

/** 下架或发布首页弹窗公告 */
export const getNoticeStatusAppHomeNoticeApi = async (params) => {
    return instance({
        url: '/dq_admin/bannerNotice/onSelfOrDown',
        method: 'get',
        params: params
    })
}