<template>
  <a-modal
    v-model="pushModal"
    :width='800'
    title="手动上传"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName + `【${item.id}】` }}</a-select-option>
        </a-select>
      </div>
      <div class="images">
        <div class="images-title">
          <div>正面图</div>
        </div>
        <div class="up-image">
          <div class="up-left">
            <a-upload
                :key="imgKey"
                :action="ip + '/file/upload'"
                list-type="picture-card"
                :file-list="coverList"
                multiple
                @preview="handlePreview"
                @change="handleChangeCoverList"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {
        coinKindId: undefined,
      },
      coverList: [],
      imgKey: "",
      coinList: [],
      coinKindName: ''
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.coverList = [];
        this.curItem = {
          coinKindId: undefined
        };
      }
    },
  },
  methods: {
    async show() {
      this.pushModal = true;
    },
    /** 上传文件列表 */
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {console.log(e)}
      });
      this.coverList = fileList;
    },
    /** 预览文件 */
    handlePreview(file) {
      const picturesList = this.coverList.map((row) => {
        return row.url;
      });
      const curIndex = picturesList.findIndex(el=>{
        return el === file.url
      })
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: curIndex,
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    /** 大币种改变 */
    changeCoin(e) {
      this.curItem.coinKindId = e
      this.coinKindName = this.coinList.find(item => item.id === e).coinKindName
    },

    /** 搜索，大币种搜索 */
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      if (!this.curItem.coinKindId) {
        return this.$message.warning({content: '大币种必须选择'})
      }
      if (this.coverList.length <= 0) {
        return  this.$message.warning({content: '必须上传图片'})
      }

      const coverImages = this.coverList.map(el => {
        return el.url
      });

      const res = await this.axios.post("/dq_admin/duibanCoinTrain/manualBatchUpload", {
        coinKindId: this.curItem.coinKindId,
        coinKindName: this.coinKindName,
        coverImages
      });
      if (res.status != 200) return;
      this.checkBoxTest = []
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.images {
  width: 350px;
  margin-top: 10px;
  .images-title {
    width: 208px;
    display: flex;
    margin-bottom: 10px;
    div {
      width: 104px;
      flex: 1;
      text-align: center;
      margin-right: 10px;
    }
  }
  .up-image {
    display: flex;
    .up-left {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
    }
    .up-right {
      .right-list {
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
      .right-item {
        width: 150px;
        height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>