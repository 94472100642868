<template>
  <div>
    <a-modal
        :width="1000"
        v-model="show"
        :keyboard="false"
        title="质检鉴定"
        ok-text="确认"
        cancel-text="取消"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-item label="送评分类">
          <a-tooltip>
            <template slot="title">
              {{ computedRateServicePayTypeTextByDesc(params.orderRatingServiceRank) }}
            </template>
            <span class="fs-20" :class="dqCheckPayClassType[params.orderRatingServiceRank]">{{ computedRateServicePayTypeText(params.orderRatingServiceRank) }}</span>
          </a-tooltip>
        </a-form-item>
        <a-form-item label="卖家描述瑕疵" v-if="params.productRemark">
          <div class="color-red ml-5">{{ params.productRemark }}</div>
        </a-form-item>
        <a-form-item label="是否进行标版">
          <div>
            <span :class="dqCheckVersionStatusClassType[params.duibanService]">{{ computedVersionStatusTypeText(params.duibanService) }}</span>
          </div>
        </a-form-item>
        <a-form-item label="质检状态">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              style="margin: 5px; width: 180px;"
              v-model="params.reviewStatus"
              placeholder="请选择质检状态"
          >
            <a-select-option
                v-for="items of computedCheckStatusTypeList(params.orderRatingServiceRank)"
                :key="items.value"
                :value="items.value"
            >{{ items.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="质检结果" v-if="params.reviewStatus === 10">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              style="margin: 5px; width: 180px;"
              v-model="params.responsibilityDetermine"
              placeholder="请选择质检结果"
          >
            <a-select-option
                v-for="items of computedCheckResultTypeList(params.orderRatingServiceRank)"
                :key="items.value"
                :value="items.value"
            >{{ items.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item  v-if="params.duibanService" label="标注版别-可选">
          <a-input
              allowClear
              v-model="params.versionName"
              class="w-250"
              placeholder='标注版别-可选'
          ></a-input>
        </a-form-item>
        <a-form-item label="质检图片">
          <a-upload
              :key="imgKey"
              :data="{rotate: 180}"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="coverList"
              :multiple="true"
              @preview="handlePreview"
              @change="handleChangeCoverList"
          >
            <div v-if="coverList.length < 15">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-model-item label="质检描述">
          <a-textarea
              v-model="params.reviewRemarks"
              placeholder="质检描述"
              :rows="4" />
          <div class="color-red">*填写之后买卖双方都将展示，请谨慎填写</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import untils from "@/untils";
import {
  computedVersionStatusType,
  dqCheckPayClassType,
  dqCheckQualityType,
  dqCheckResultTypeList,
  dqCheckStatusTypeList,
  dqCheckVersionStatusClassType,
  findCheckResultTypeList,
  findCheckStatusTypeList,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc
} from "@/views/cmsPage/checkManage/_data"

export default {
  props: ["detail"],
  data() {
    return {
      untils: untils,
      show: false,
      dqCheckResultTypeList,
      dqCheckStatusTypeList,
      dqCheckQualityType,
      dqCheckPayClassType,
      dqCheckVersionStatusClassType,
      params: {
        orderId: undefined,
        // 评级结果
        reviewStatus: undefined,
        // 质检异常结果
        responsibilityDetermine: undefined,
        // 描述
        reviewRemarks: undefined,
        // 图片
        receiptImage: undefined,
        // 可标注版别
        versionName: undefined
      },
      coverList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState(["ip"]),
    computedVersionStatusTypeText() {
      return (value) => computedVersionStatusType(value)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedCheckResultTypeList() {
      return (value) => findCheckResultTypeList(value)
    },
    computedCheckStatusTypeList() {
      return (value) => findCheckStatusTypeList(value)
    }
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
  },
  methods: {
    /** 快速输入 */
    handleInputDesc(item) {
      this.params.reviewRemarks = item
    },
    async showPopup(data) {
      this.resetForms()
      this.coverList = []
      await this.setDetail(data)
      this.show = true
    },
    async setDetail(data) {
      if (!data.reviewStatus) {
        data.reviewStatus = undefined
      }
      if (!data.responsibilityDetermine) {
        data.responsibilityDetermine = undefined
      }
      this.params = Object.assign(this.params, data)
      if (this.params.orderRatingServiceRank === 'D') {
        this.params.reviewStatus = 5
      }
      if (this.params.confirmReceiptImage) {
        this.coverList = this.params.confirmReceiptImage.map((items, i) => {
          const obj = {
            uid: i,
            name: "image",
            status: "done",
            url: items,
          };
          return obj;
        });
      }
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
            row.thumbUrl = row.url
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    handlePreview(file, type) {
      this.$previewImg({
        list: [{img_url: `${file.url}`}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    hideModal() {
      if (this.isLoading) return this.$message.warn('请勿重复提交')
      if (!this.params.reviewStatus) return this.$message.warn('评级结果不能为空')
      if (!this.coverList.length) return this.$message.warn('评级结果图片不能为空')
      if (!this.coverList.every(el => el.status === 'done')) return this.$message.warn('图片上传中，请稍等')
      this.params.receiptImage = this.coverList.map((row) => {
        return row.url;
      })
      if (this.params.reviewStatus === 5) this.params.responsibilityDetermine = undefined
      this.submit()
    },
    async submit() {
      const tempParams = {
        ratingId: this.params.id,
        qualityType: this.params.qualityType,
        reviewStatus: this.params.reviewStatus,
        responsibilityDetermine: this.params.responsibilityDetermine,
        reviewRemarks: this.params.reviewRemarks,
        receiptImage: this.params.receiptImage,
        versionName: this.params.versionName
      }
      const url = '/dq_admin/qualityzhandian/qualityReview'
      this.isLoading = true
      const res = await this.axios.post(
          url,
          tempParams
      );
      this.isLoading = false
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.resetForms()
      this.$emit("success", this.params.expressNo);
    },
    resetForms() {
      this.params = {
        orderId: undefined,
        // 评级平台
        qualityType: undefined,
        // 评级结果
        reviewStatus: undefined,
        responsibilityDetermine: undefined,
        // 描述
        reviewRemarks: undefined,
        // 图片
        receiptImage: undefined,
        versionName: undefined
      }
      this.show = false
      this.$emit('closeModal')
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
.fs-20 {
  font-size: 20px;
}
</style>
