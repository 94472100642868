<template>
  <div class="content">
<!--    :filter-option="untils.filterOption"-->
    <div class="search-attr">
      <div>
        <a-input
            allowClear
            v-model="params.buyerUserName"
            size='small' style="width:250px;margin:5px;"
            placeholder='买家昵称/userId/店铺名'
        ></a-input>
        <a-input
            allowClear
            v-model="params.sellerUserName"
            size='small' style="width:250px;margin:5px;"
            placeholder='卖家昵称/userId/店铺名'
        ></a-input>
        <a-input
            allowClear
            v-model="params.groupId"
            size='small' style="width:200px;margin:5px;"
            placeholder='群groupId搜索'
        ></a-input>
        <a-input v-model="params.orderNo" size='small' style="width:150px;margin:5px;" placeholder='请输入订单号'></a-input>
        <a-select  allowClear v-model="params.chatType" size='small' style="width:120px;margin:5px;" placeholder='群聊or私聊'>
          <a-select-option :value='0'>群聊</a-select-option>
          <a-select-option :value='1'>私聊</a-select-option>
        </a-select>
        <a-button @click="search" size='small' type="primary" style="margin-left:10px;">搜索</a-button>
      </div>
    </div>
    <div class="table-content" >
      <a-table :pagination='false' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
        <div slot="slotProductName" slot-scope="row">
          <a @click="openPage(row)">{{row.productName}}</a>
        </div>
<!--      会话人员  -->
        <div slot="takeUserSlot" slot-scope="row">
          <div class="flex-center-center flex-wrap">
            <span style='color:green'>{{row.userOneName}} <span class="color-orange">【{{ row.oneUserId }}】</span></span>
            <span style='color:green'>----{{ row.userTwoName }} <span class="color-orange">【{{ row.twoUserId }}】</span></span>
          </div>
        </div>

        <div slot="setup" slot-scope="row">

          <a v-if="row.groupId" @click="openModal(row, 'v1')" style='margin-right:5px'>查看记录</a>
          <a @click="openModalV2(row, 'v2')" style='margin-right:5px'>本地记录</a>
          <!-- <a @click="openProduct(row)" style='margin-right:5px'>查看商品</a> -->
        </div>
      </a-table>
      <div style="display: flex;justify-content: flex-end">
        <a-button @click="handleChangePage('up')">上一页</a-button>
        <a-button @click="handleChangePage('down')" class="ml-10">下一页</a-button>
      </div>
    </div>
    <a-modal centered :width='900' v-model="visible" title="聊天记录" @cancel='cancelModal' :footer="null" >
      <div class="take-box">
        <a-table  @change="changePageModal" :pagination='paginationModal' :loading='loadingModal' :columns='takeColumns' :data-source='messages' :rowKey="(record,n)=>n">
          <div slot="itemUserNameSlot" slot-scope="row">
            <span style='color:green'>{{row.userName}} <span class="color-orange">【{{ row.userId }}】</span></span>
          </div>
          <div slot="content" slot-scope="row">
            <div v-if="row.msgType === 'TIMTextElem'">{{row.msg}}</div>
            <img v-if="row.msgType === 'TIMImageElem'" style="max-width:100px;max-height:100px;" :src="row.msg" alt="" @click="previewImg(row)">
            <div class="width-100" v-if="row.msgType === 'TIMSoundElem'">
              <audio controls>
                <source :src="row.msg">
              </audio>
            </div>
            <div v-if="row.msgType === 'TIMVideoFileElem' && row.msg">
              <video controls width="200">
                <source :src="row.msg" type="video/mp4">
              </video>
            </div>
            <div v-if="row.msgType === 'TIMVideoFileElem' && !row.msg" >{{ '抱歉！当前视频路径未找到' }}</div>
          </div>
          <div slot="contentTypeSlot" slot-scope="row">
            <div style='color:green'>{{ contentTypeMapText[row.msgType] }}</div>
          </div>
        </a-table>
      </div>
    </a-modal>
    <Audio src=''></Audio>
  </div>
</template>

<script>
import untils from "@/untils"
export default {
  data(){
    return {
      untils,
      userUserIdSearchStatus: false,
      columns:[
        {title:'商品名称', scopedSlots: { customRender: "slotProductName" } },
        // {title:'groupID',dataIndex:'groupId'},
        {
          title: "groupID",
          customRender: (text, row, index) => {
            const { groupActivePlatType } = row;
            const { groupId } = row;
            return groupActivePlatType == 1 ? (
                <span style="color:red">{groupId}</span>
            ) : (
                <span>{groupId}</span>
            );
          },
        },
        {title:'会话人员',align:'center',scopedSlots: { customRender: 'takeUserSlot' }, width: 400},
        {title: '创建时间',dataIndex: 'createTime'},
        {title: '最后发言时间',dataIndex: 'updateTime'},
        {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
      ],
      data:[],
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      visible:false,
      params:{
        pageSize:10,
        buyerUserName: undefined,
        sellerUserName: undefined,
        orderNo: undefined,
        pageType: '',
        startId: 0,
        groupId: undefined
      },
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      paginationModal:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      messages:[],
      contentTypeMapText: {
        TIMTextElem: '文本',
        TIMImageElem: '图片',
        TIMSoundElem: '语音',
        TIMVideoFileElem: '视频'
      },
      takeColumns:[
        {title:'昵称', scopedSlots: { customRender: 'itemUserNameSlot' }, width: 200},
        {title:'内容',width:400,scopedSlots: { customRender: 'content' },},
        {title: '类型', width: 100, align: 'center', scopedSlots: { customRender: 'contentTypeSlot' }},
        {title:'时间',width:120,align:'center',dataIndex:'sendTime'},
      ],
      modalParams:{},
      v1TotalPages: 0,
      loadingModal:false,
      modalType: '',
      buyerUserInfoList: [],
      sellerUserInfoList: [],
      minId: '',
      maxId: ''
    }
  },
  created(){
    this.getData(this.params)
  },
  methods:{
    /** 操作*/
    async handleChangePage(type) {
      this.params.pageType = type
      if (type === 'up') {
        this.params.startId = this.maxId
      } else {
        this.params.startId = this.minId
      }
      await this.getData(this.params)
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/groupChat/chatList',{params:params})
      this.loading = false
      const list = res.data
      this.data = list
      this.maxId = list[0].id
      this.minId = list[list.length - 1].id
    },
    search(){
      this.params.startId = 0
      this.params.pageType = ''
      this.getData(this.params)
    },
    async openModal(row, type){
      this.modalType = type
      this.paginationModal.current = 0
      this.modalParams.pageNum = 1
      Object.assign(this.modalParams,{pageNum:1,pageSize:10,groupId:row.groupId})
      await this.getTakeMessagesListV1(this.modalParams)
      this.visible = true
    },
    async getTakeMessagesListV1(params) {
      this.loadingModal = true
      const result = await this.axios('/dq_admin/groupChat/getByGroupId',{params:params})
      this.loadingModal = false
      if(result.status == 200){
        const {records=[],total} = result.data
        this.paginationModal.total = total
        this.messages = records
      }
    },
    async openModalV2(row, type){
      this.modalType = type
      this.paginationModal.current = 0
      this.modalParams.pageNum = 1
      this.visible = true
      Object.assign(this.modalParams,{pageNum:1,pageSize:10,groupId:row.groupId,oneUserId:row.oneUserId,twoUserId:row.twoUserId})
      await this.getTakeMessagesV2(this.modalParams)
    },
    async getTakeMessagesV2(params){
      this.loadingModal = true
      const result = await this.axios('/dq_admin/groupChat/getByGroupIdV2',{params:params})
      this.loadingModal = false
      if(result.status === '200'){
        const {records=[],total} = result.data
        this.messages = records
        this.paginationModal.total = total
      }
    },
    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.params.pageNum = current
      this.params.limit = pageSize
      this.getData(this.params)
    },
    changePageModal(page){
      const {current} = page
      if (this.modalType === 'v2') {
        this.paginationModal.current = current
        this.modalParams.pageNum = current
        this.getTakeMessagesV2(this.modalParams)
      } else {
        this.paginationModal.current = current
        this.modalParams.pageNum = current
        this.getTakeMessagesListV1(this.modalParams)
      }
    },
    cancelModal(){
      this.paginationModal.current = 0
      this.modalParams = {}
      this.messages = []
    },
    previewImg(row){
      const arrImg = []
      this.messages.forEach(el => {
        if (el.msgType === 'TIMImageElem') {
          arrImg.push({img_url: el.msg})
        }
      })
      console.log(arrImg, 'slslsl')
      const foundIndex = arrImg.findIndex(el => el.img_url === row.msg)
      console.log(foundIndex, 'sl')
      if(row.msg&&row.msg.length>0){
        this.$previewImg({
          list: arrImg,
          baseImgField:'img_url',
          showMute:false,
          current: foundIndex
        })
      }
    },
    openPage(row) {
      if(row.productKind == 1){
        const routeData = this.$router.resolve({
          path: "/cms/currencymannage/goods",
          query: {
            productName: row.productId,
          },
        });
        window.open(routeData.href, "_blank");
      }
      if(row.productKind == 0){
        const routeData = this.$router.resolve({
          path: "/cms/currencymannage/auctiongoods",
          query: {
            productName: row.productId,
          },
        });
        window.open(routeData.href, "_blank");
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;}
.width-100 {
  width: 100px;
}
</style>