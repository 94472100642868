<template>
  <a-drawer title="店铺报表" placement="right" :width="'60vw'" :closable="true" :visible="showPopup" @close="onClose">
    <div style="background-color: #F4F6F6;">
      <div class="flex-start-center">
        <a-radio-group v-model="params.dateType" @change="getData">
          <a-radio-button :value="1">上周</a-radio-button>
          <a-radio-button :value="3">本月</a-radio-button>
          <a-radio-button :value="2">上月</a-radio-button>
          <a-radio-button :value="4">30天</a-radio-button>
          <a-radio-button :value="6">近三月</a-radio-button>
          <a-radio-button :value="5">近一年</a-radio-button>
        </a-radio-group>
        <div class="flex-start-center ml-40">
          <div class="">笔数：<span class="font-size-24 color-green font-weight-bold">{{ listData.num }}</span></div>
          <div class="ml-20">金额：<span class="font-size-24 color-green font-weight-bold">￥{{ listData.money }}</span></div>
        </div>
      </div>
      <div class="mt-40" v-if="listData.voList">
<!--    alternate    -->
        <a-timeline mode="left">
          <a-timeline-item
              class="mb-20"
              v-for="(item, index) in listData.voList"
              :key="item.timeQuantum"
          >
            <span class="color-black font-weight-bold font-size-20">{{ item.timeQuantum.substring(0, 10) }}</span>
            <span class="ml-20">
              笔数：
              <span
                  :class="item.quantity ? 'color-green' : 'color-red'"
                  class="font-size-24 font-weight-bold"
              >{{ item.quantity }}</span>
            </span>
            <span class="ml-20">
              收款金额：
              <span
                  class="font-size-24 font-weight-bold"
                  :class="item.money ? 'color-green' : 'color-red'"
              >￥{{ item.money }}</span></span>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
  </a-drawer>
</template>
<script>

import {getShopStatementListApi} from "@/views/cmsPage/shopManage/shopInfoList/_apis"

export default {
  components: {},
  data() {
    return {
      showPopup: false,
      params: {
        userId: undefined,
        dateType: 1, // 1:上周 2:上月 3:本月 4:30天 5:近一年
      },
      listData: {}
    }
  },
  computed: {
  },
  methods: {
    async show(data) {
      this.params.userId = data.userId
      await this.getData()
      this.showPopup = true
    },
    /** 获取保证金占用明细数据 */
    async getData() {
      this.$loading.show()
      const res = await getShopStatementListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.listData = res.data
    },
    onClose() {
      this.params = {
        userId: undefined,
        dateType: 1,  // 1:上周 2:上月 3:本月 4:30天 5:近一年
      }
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>