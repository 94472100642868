var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "添加内部备注" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        [
          _c("a-input", {
            staticClass: "w-100-w",
            attrs: {
              allowClear: "",
              size: "small",
              type: "textarea",
              "auto-size": { minRows: 4, maxRows: 10 },
              placeholder: "内部备注，不对外展示"
            },
            model: {
              value: _vm.params.content,
              callback: function($$v) {
                _vm.$set(_vm.params, "content", $$v)
              },
              expression: "params.content"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-20" },
        [
          _c(
            "a-upload",
            {
              attrs: {
                action: _vm.ip + "/file/upload",
                "list-type": "picture-card",
                "file-list": _vm.coverList,
                multiple: true
              },
              on: {
                preview: _vm.handlePreview,
                change: _vm.handleChangeCoverList
              }
            },
            [
              _vm.coverList.length < 15
                ? _c(
                    "div",
                    [
                      _c("a-icon", { attrs: { type: "plus" } }),
                      _c("div", { staticClass: "ant-upload-text" }, [
                        _vm._v("上传图片")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }