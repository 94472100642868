<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  币种    -->
      <div slot="itemCoinVersionSlot" slot-scope="row">
        <div class="font-weight-bold">
          【<span class="color-black">{{ row.coinName }}</span>--（<span class="color-green">{{ row.coinId }}）</span>】
        </div>
        <div class="mt-10 font-weight-bold color-blue">{{ row.recoVersion }}</div>
      </div>
<!--  基本数据    -->
      <div slot="itemBaseDataSlot" slot-scope="row">
        <div class="font-weight-bold color-black">对版有效数：<span class="color-green">{{ row.qualityNum == null ? '处理中' : row.qualityNum }}</span></div>
        <div class="font-weight-bold color-black mt-5">top1=top2记录数：<span class="color-green">{{ row.oneEqTwoNum == null ? '处理中' : row.oneEqTwoNum }}</span></div>
        <div class="font-weight-bold color-black mt-5">top1=实际版记录数：<span class="color-green">{{ row.oneEqRealNum == null ? '处理中' : row.oneEqRealNum }}</span></div>
      </div>
<!--  准确率    -->
      <div slot="itemRateAboutSlot" slot-scope="row">
        <div class="font-weight-bold color-black mt-5">
          弹窗率：
          <span class="color-green">{{ row.oneEqTwoPercent == null ? '处理中' : ((row.oneEqTwoPercent || 0) * 100 + '%') }}</span>
          <span class="color-red">（top1=2）</span>
        </div>
        <div class="font-weight-bold color-black mt-5">
          准确率：
          <span class="color-green">{{ row.oneEqRealPercent == null ? '处理中' : ((row.oneEqRealPercent || 0) * 100 + '%') }}</span>
          <span class="color-red">（top1=真实版别）</span>
        </div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button type="primary" @click="handleShowProgress(row.id)" >查看进度</a-button>
        </div>
        <div class="mt-20" v-if="!row.qualityNum">
          <a-popconfirm
              title="确认执行任务？"
              @confirm="handleExecuteTask(row.id)"
          >
            <a-button type="danger">执行任务</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  进度  -->
    <CoinModelVersionProgressPopup ref="coinModelVersionProgressPopupEl" />
  </div>
</template>
<script>
import CoinModelVersionProgressPopup
  from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_components/CoinModelVersionProgressPopup/index.vue"
import {coinModelVersionTableColumns} from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_data"
import {getModelVersionExecuteTaskApi} from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_apis"


export default {
  components: {CoinModelVersionProgressPopup},
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: coinModelVersionTableColumns,
    }
  },
  methods: {
    /** 执行任务 */
    async handleExecuteTask(id) {
      this.$loading.show()
      const res = await getModelVersionExecuteTaskApi({id: id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message || '开始执行')
      this.$parent.search()
    },
    /** 查看进度 */
    handleShowProgress(id) {
      this.$refs.coinModelVersionProgressPopupEl.show(id)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>