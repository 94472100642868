<template>
  <a-modal
      v-model="pushModal"
      :width='800'
      title="修改大币种"
      ok-text="挪动"
      cancel-text="取消"
      @cancel="close"
      @ok="confirmPushModal"
  >
    <a-select
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        :dropdownMenuStyle="{'max-height': '350px'}"
        allowClear
        showSearch
        @search='searchData'
        class="header-item"
        v-model="coinKindId"
        placeholder="请选择大币种"
        @change="handleChangeCoin($event)"
    >
      <a-select-option
          v-for="items of coinList"
          :key="items.id"
          :value="items.id"
      >{{ items.coinKindName + `【${items.id}】` + (items.coinCategory ? `---${items.coinCategory}` : '' ) }}</a-select-option>
    </a-select>
    <div class="lately-search" v-if="latelySearchBtn && latelySearchBtn.length">
      <span>最近搜索：</span>
      <a-button
          v-for="item in latelySearchBtn"
          :key="item.id"
          @click="handleLatelySearch(item)"
      >{{ item.value }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      untils,
      pushModal: false,
      coinKindId: undefined,
      tempCoinKindId: undefined,
      coinList: [],
      trainIds: '',
      coinKindName: undefined,
      latelySearchBtn: [],
      moveType: ''
    };
  },
  methods: {
    /** 点击最近搜索 */
    handleLatelySearch(item) {
      this.tempCoinKindId = item.id
      this.searchData(item.value)
    },
    /**开启*/
    show(data, type) {
      this.moveType = type ? 'batch' : ''
      this.latelySearchBtn = JSON.parse(localStorage.getItem('lately-search-sure')) || []
      this.trainIds = data
      this.pushModal = true;
    },
    /** 关闭 */
    close() {
      this.pushModal = false;
      this.coinKindId = undefined
    },
    /** 获取大币种列表 */
    async searchData(inner){
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.coinList = records
      const found = this.coinList.find(el => el.id === this.tempCoinKindId)
      this.coinKindId = found.id
      this.coinKindName = found.coinKindName
    },
    /** 确定 */
    async confirmPushModal() {
      if (!this.coinKindId) {
        return this.$message.warning('大币种不能为空')
      }
      this.$loading.show();
      const res = await this.axios(
          "/dq_admin/duibanCoinTrain/scootCoinByTrain",
          {
            params: {
              trainIds: this.trainIds,
              coinKindId: this.coinKindId,
              coinKindName: this.coinKindName
            },
          }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      const findIndex = this.latelySearchBtn.findIndex(el => el.id === this.coinKindId)
      if (findIndex < 0) this.latelySearchBtn.unshift({id: this.coinKindId, value: this.coinKindName})
      if (this.latelySearchBtn && this.latelySearchBtn.length > 10) {
        this.latelySearchBtn.splice(10)
      }
      localStorage.setItem('lately-search-sure', JSON.stringify(this.latelySearchBtn))
      this.close();
      this.$message.success(res.message || "操作成功");
      this.$emit('success', this.trainIds * 1)
    },

    handleChangeCoin(e) {
      this.coinKindId = e
      this.coinKindName = this.coinList.find(el => el.id === e).coinKindName
    },
  },
};
</script>

<style lang="scss" scoped>
.lately-search {
  margin-top: 20px;
  button {
    margin-right: 15px;
  }
}
.header-item {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 260px;
}
</style>