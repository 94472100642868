<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  Id    -->
      <div slot="itemIdSlot" slot-scope="row">
        <div class="font-weight-bold color-black">ID：<span class="color-blue">{{ row.id }}</span></div>
        <div class="font-weight-bold color-black">排序：<span class="color-green">{{ row.sort }}</span></div>
      </div>
<!--  标题    -->
      <div slot="itemTitleSlot" slot-scope="row">
        <div class="font-weight-bold color-black">{{ row.layerTitle }}</div>
      </div>
<!--  内容    -->
      <div slot="itemContentSlot" slot-scope="row">
        <div v-if="row.detailType === 1" class="cur-pot color-blue" @click="handleShowTextContent(row.detailContent)">富文本内容</div>
        <div v-if="row.detailType === 2" class="w-100-w flex-center-center" @click="handleShowBigImg(row.detailPicture)">
          <img class="w-50 text-center" :src="row.detailPicture" alt="">
        </div>
      </div>
<!--  状态    -->
      <div slot="itemStatusSlot" slot-scope="row">
        <div :class="noticeStatusClassType[row.noticeStatus]">{{ computedHomeNoticeStatusListMapText(row.noticeStatus) }}</div>
        <div class="mt-10">
          <a-popconfirm
              :title="`确定${row.noticeStatus ? '下架' : '发布' }当前公告？`"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleChangeNoticeStatusHomeNotice(row.id)"
          >
            <a-button size="small" :type="row.noticeStatus ? 'danger' : 'primary'">{{row.noticeStatus ? '下架公告' : '发布公告' }}</a-button>
          </a-popconfirm>
        </div>
      </div>
<!--   操作时间   -->
      <div slot="itemCreateOrUpdateAboutSlot" slot-scope="row">
        <div class="color-gray">
          创建时间：<span class="color-black">{{ row.createTime }}</span>
        </div>
        <div class="color-gray">
          公告开始时间：<span class="color-black">{{ row.startTime }}</span>
        </div>
        <div class="color-gray">
          公告结束时间：<span class="color-black">{{ row.endTime }}</span>
        </div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button
              class="mt-10"
              size="small"
              type="primary"
              @click="handleEditorHomeNoticePopup(row)"
          >编辑更新</a-button>
        </div>
        <div class="mt-10">
          <a-popconfirm
              title="确定删除当前公告？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteHomeNotice(row.id)"
          >
            <a-button size="small" type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
 <!--  展示富文本内容  -->
    <TextContentPopup ref="textContentPopupEl" />
<!--  编辑公告  -->
    <EditorAppHomeNoticeContentPopup ref="editorAppHomeNoticeContentPopupEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import {
  appHomeNoticeTableColumns,
  homeNoticeStatusListMapText, noticeStatusClassType
} from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_data"
import {
  delAppHomeNoticeApi,
  getNoticeStatusAppHomeNoticeApi
} from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_apis"
import EditorAppHomeNoticeContentPopup
  from "@/views/cmsPage/contentDataMange/appHomePageNoticeManage/_components/EditorAppHomeNoticeContentPopup/index.vue"
import TextContentPopup from "@/views/cmsPage/shopManage/messageTemplateList/_components/TextContentPopup/index.vue"

export default {
  components: {TextContentPopup, EditorAppHomeNoticeContentPopup},
  props: ['pagination', 'list'],
  emits: ['success'],
  data() {
    return {
      noticeStatusClassType, // 公告上线状态
      tableList: appHomeNoticeTableColumns,
    }
  },
  computed: {
    computedHomeNoticeStatusListMapText() { // 公告上线状态
      return (value) => homeNoticeStatusListMapText(value)
    },
  },
  methods: {
    /** 展示富文本内容 */
    handleShowTextContent(content) {
      this.$refs.textContentPopupEl.show(content)
    },
    /** 编辑公告 */
    handleEditorHomeNoticePopup(row) {
      this.$refs.editorAppHomeNoticeContentPopupEl.show(row)
    },
    /** 确定下架或发布公告 */
    async handleChangeNoticeStatusHomeNotice(id) {
      const res = await getNoticeStatusAppHomeNoticeApi({bannerId: id})
      if (res.status !== '200') return
      this.$message.info('修改成功')
      this.handleSuccess()
    },
    /** 确定删除当前公告 */
    async handleDeleteHomeNotice(id) {
      const res = await delAppHomeNoticeApi({bannerId: id})
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 展示大图 */
    handleShowBigImg(img) {
      this.$previewImg({
        list: [{img_url: img}],
        baseImgField: "img_url",
        showMute: false,
        current: 0
      });
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>