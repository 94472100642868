<template>
  <a-modal
      centered
      :width='700'
      :title="title"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
        <a-form-model-item label='用户Id' prop='content'>
          <a-select
              @search="searchUserInfo($event, 'userId')"
              v-model="forms.userId"
              closable
              :filter-option="untils.filterOption"
              showSearch
              placeholder="搜索发起用户昵称或userId"
          >
            <a-select-option
                v-for="(item, index) of userInfoList"
                :key="index"
                :value="item.userId"
            >{{ item.userName + `【${item.userId}】` }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="selectedCoinList && selectedCoinList.length" label='已选择' prop='content'>
          <template  v-for="item in selectedCoinList">
            <a-tag
                color="blue"
                closable
                :key="item.coinKindId"
                @close="handleDeleteTag($event, item)"
            >{{ item.coinKindName }}</a-tag>
          </template>
        </a-form-model-item>
        <a-form-model-item label='选择大币种' prop='degree'>
          <a-select
              @search="searchData"
              v-model="coinIds"
              :filter-option="untils.filterOption"
              showSearch
              mode="multiple"
              placeholder="请选择"
          >
            <a-select-option
                v-for="(item, index) of bigList"
                :key="index"
                :value="item.id"
            >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='用户标版单价'>
          <a-input v-model="forms.labelPrice" type="number" placeholder="用户标版单价"></a-input>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {getBigCoinList} from "@/axios/apis"
import _userInfoListMixin from "@/_mixins/_userInfoListMixin"
export default {
  mixins: [_userInfoListMixin],
  data() {
    return {
      untils,
      isLoading: false,
      title: '添加',
      showModal: false,
      bigList: [],
      forms: {
        id: undefined,
        coinIds: undefined,
        userId: undefined,
        labelPrice: undefined
      },
      coinIds: undefined,
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules: {
        userId:[{ required: true, message: '用户ID不能为空', trigger: 'blur' }],
        coinIds:[{ required: true, message: '大币种不能为空', trigger: 'blur' }],
      },
      expertOrNormalId: '',
      pageType: 'expert',
      selectedCoinList: []
    }
  },
  methods: {
    show(data, type) {
      this.pageType = type
      if (data) {
        this.expertOrNormalId = data.userId
        this.forms.userId = data.userId
        this.forms.labelPrice = data.labelPrice
        this.searchUserInfo(data.userId)
        this.selectedCoinList = JSON.parse(JSON.stringify(data.coinKindList))
        this.title = '编辑'
      }
      this.showModal = true
    },
    /** 删除已经选择的 */
    handleDeleteTag(e, item) {
      e.preventDefault();
      const foundIndex = this.selectedCoinList.findIndex(el => el.coinKindId === item.coinKindId)
      this.selectedCoinList.splice(foundIndex, 1)
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.forms.userId) return this.$message.warn('用户ID不能为空')
      if (!this.coinIds && this.selectedCoinList.length < 1) return this.$message.warn('大币种不能为空')
      if (this.forms.labelPrice === undefined) return this.$message.warn('用户标记单价不能为空')
      if (this.expertOrNormalId) {
        const tempArr = this.selectedCoinList.map(el => el.coinKindId) || []
        const tempArrCoin = this.coinIds || []
        this.forms.coinIds = tempArrCoin.concat(tempArr).join(',')
      } else {
        this.forms.coinIds = this.coinIds.join(',')
      }
      let url
      if (this.isLoading) return this.$message.warn('请勿重复点击')
      this.isLoading = true
      this.$loading.show()
      if (this.expertOrNormalId) {
        url = this.pageType === 'expert' ? '/dq_admin/duibanConfirmAuthCoin/editAuthority' : '/dq_admin/duibanNormalAuthCoin/editAuthority'
      } else {
        url = this.pageType === 'expert' ? '/dq_admin/duibanConfirmAuthCoin/addAuthority' : '/dq_admin/duibanNormalAuthCoin/addAuthority'
      }
      const res = await this.axios(url, {
        params: { ...this.forms }
      })
      this.isLoading = false
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.$emit('addOrEditorSuccess')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.resetForms()
      this.showModal = false
    },
    /** 重置表单 */
    resetForms() {
      this.forms = {
        id: undefined,
        coinIds: undefined,
        userId: undefined,
        labelPrice: undefined
      }
      this.coinIds = undefined
    },
    /** 搜索大币种 */
    async searchData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.bigList = records;
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-form-item-control {
  line-height: 30px !important;
}

</style>