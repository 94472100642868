var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: {
                size: "small",
                "filter-option": _vm.untils.filterOption,
                showSearch: "",
                placeholder: "请选择大币种",
                allowClear: ""
              },
              on: {
                search: _vm.searchBigCoinListData,
                change: _vm.changeVersionlistSearch
              },
              model: {
                value: _vm.params.coinSid,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinSid", $$v)
                },
                expression: "params.coinSid"
              }
            },
            _vm._l(_vm.bigCoinListData, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.sid } },
                [
                  _vm._v(
                    _vm._s(
                      item.coinKindName +
                        "【" +
                        item.id +
                        "】" +
                        (item.coinCategory ? "---" + item.coinCategory : "")
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "180px" },
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                dropdownMenuStyle: { "max-height": "500px" },
                placeholder: "版别"
              },
              on: { select: _vm.search },
              model: {
                value: _vm.params.versionSid,
                callback: function($$v) {
                  _vm.$set(_vm.params, "versionSid", $$v)
                },
                expression: "params.versionSid"
              }
            },
            _vm._l(_vm.versionList, function(item) {
              return _c(
                "a-select-option",
                { key: item.versionSid, attrs: { value: item.versionSid } },
                [
                  _vm._v(
                    _vm._s(
                      (item.versionName || "") +
                        "-" +
                        item.confirmVersionName +
                        "-(" +
                        (item.size || 0) +
                        ")"
                    )
                  )
                ]
              )
            }),
            1
          ),
          false
            ? _c(
                "a-select",
                {
                  staticStyle: { margin: "5px", width: "180px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    allowClear: "",
                    "filter-option": _vm.untils.filterOption,
                    size: "small",
                    placeholder: "授权用户"
                  },
                  on: { select: _vm.search },
                  model: {
                    value: _vm.params.userId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "userId", $$v)
                    },
                    expression: "params.userId"
                  }
                },
                _vm._l(_vm.authUserList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.userId, attrs: { value: item.userId } },
                    [_vm._v(_vm._s(item.userName))]
                  )
                }),
                1
              )
            : _vm._e(),
          _c("a-input", {
            staticStyle: { margin: "5px", width: "180px" },
            attrs: {
              placeholder: "小币种|版别|ID",
              allowClear: "",
              size: "small"
            },
            on: { pressEnter: _vm.search, change: _vm.search },
            model: {
              value: _vm.params.title,
              callback: function($$v) {
                _vm.$set(_vm.params, "title", $$v)
              },
              expression: "params.title"
            }
          }),
          _c("a-input", {
            staticStyle: { margin: "5px", width: "180px" },
            attrs: { placeholder: "第三方ID", allowClear: "", size: "small" },
            on: { pressEnter: _vm.search, change: _vm.search },
            model: {
              value: _vm.params.thirdAuthId,
              callback: function($$v) {
                _vm.$set(_vm.params, "thirdAuthId", $$v)
              },
              expression: "params.thirdAuthId"
            }
          }),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "作答类型" },
              model: {
                value: _vm.params.answerType,
                callback: function($$v) {
                  _vm.$set(_vm.params, "answerType", $$v)
                },
                expression: "params.answerType"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [
                _vm._v("正常标记")
              ]),
              _c("a-select-option", { attrs: { value: 3 } }, [
                _vm._v("手动标记")
              ]),
              _c("a-select-option", { attrs: { value: 4 } }, [_vm._v("假货")]),
              _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("跳过")]),
              _c("a-select-option", { attrs: { value: 2 } }, [
                _vm._v("图片不清晰")
              ])
            ],
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "数据来源" },
              model: {
                value: _vm.params.companyType,
                callback: function($$v) {
                  _vm.$set(_vm.params, "companyType", $$v)
                },
                expression: "params.companyType"
              }
            },
            _vm._l(_vm.companyTypeList, function(item, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "是否延迟" },
              model: {
                value: _vm.params.delayStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "delayStatus", $$v)
                },
                expression: "params.delayStatus"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [_vm._v("否")]),
              _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("是")])
            ],
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "160px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "是否推送至盲审"
              },
              model: {
                value: _vm.params.blindTrialStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "blindTrialStatus", $$v)
                },
                expression: "params.blindTrialStatus"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [
                _vm._v("未推送至盲审")
              ]),
              _c("a-select-option", { attrs: { value: 1 } }, [
                _vm._v("已推送至盲审")
              ])
            ],
            1
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "160px", margin: "5px" },
              attrs: { allowClear: "", size: "small", placeholder: "是否废弃" },
              model: {
                value: _vm.params.abandonmentStatus,
                callback: function($$v) {
                  _vm.$set(_vm.params, "abandonmentStatus", $$v)
                },
                expression: "params.abandonmentStatus"
              }
            },
            [
              _c("a-select-option", { attrs: { value: 0 } }, [
                _vm._v("未废弃")
              ]),
              _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("已废弃")])
            ],
            1
          ),
          _c(
            "a-checkbox-group",
            {
              on: { change: _vm.handleChangeCheckBox },
              model: {
                value: _vm.isAlike,
                callback: function($$v) {
                  _vm.isAlike = $$v
                },
                expression: "isAlike"
              }
            },
            [_c("a-checkbox", { attrs: { value: 1 } }, [_vm._v("答案一致")])],
            1
          ),
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "10px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "确定加入盲审？",
                "ok-text": "确认",
                "cancel-text": "取消",
                disabled: _vm.isDisabled
              },
              on: {
                confirm: function($event) {
                  return _vm.insertConfrim()
                }
              }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "5px" },
                  attrs: { disabled: _vm.isDisabled, size: "small" }
                },
                [_vm._v("加入盲审")]
              )
            ],
            1
          ),
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "确定批量确认？",
                "ok-text": "确定",
                "cancel-text": "取消",
                disabled: _vm.isDisabled
              },
              on: {
                confirm: function($event) {
                  return _vm.handleBatchConfirm()
                }
              }
            },
            [
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "5px" },
                  attrs: { disabled: _vm.isDisabled, size: "small" }
                },
                [_vm._v("批量确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange
              },
              columns: _vm.list,
              "data-source": _vm.data,
              rowKey: function(record, index) {
                return index
              },
              pagination: _vm.pagination
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Id",
                fn: function(row) {
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.ratingSpliderId +
                            "(" +
                            (row.thirdAuthId || "") +
                            "-" +
                            row.dikuConfirmVersionName +
                            ")"
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "itemVersionName",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(
                        _vm._s(row.itemVersionName) +
                          "(" +
                          _vm._s(_vm._f("cmpanyName")(row.itemCompanyType)) +
                          ")"
                      )
                    ]),
                    row.itemVersionAliasName
                      ? _c("div", { staticClass: "name-lable" }, [
                          _vm._v(_vm._s(row.itemVersionAliasName || ""))
                        ])
                      : _vm._e()
                  ])
                }
              },
              {
                key: "images",
                fn: function(row) {
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "50px",
                          "max-height": "50px"
                        },
                        attrs: { src: row.showImageUrl },
                        on: {
                          click: function($event) {
                            return _vm.previewImg(row)
                          }
                        }
                      })
                    ]
                  )
                }
              },
              {
                key: "answers",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("ul", { staticClass: "answers-header" }, [
                        _c("li", [_vm._v("标注时间")]),
                        false ? _c("li", [_vm._v("用户名")]) : _vm._e(),
                        _c("li", [_vm._v("类型")]),
                        _c("li", [_vm._v("答案")]),
                        _c("li", [_vm._v("操作")])
                      ]),
                      _vm._l(row.answers, function(item, index) {
                        return _c(
                          "ul",
                          {
                            key: index,
                            staticClass: "answers-list",
                            class: {
                              red: item.addressType == 1,
                              yellow: item.addressType == 2
                            }
                          },
                          [
                            _c("li", [_vm._v(_vm._s(item.createTime))]),
                            false
                              ? _c("li", [_vm._v(_vm._s(item.userName))])
                              : _vm._e(),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("answerTypeFilter")(item.answerType)
                                )
                              )
                            ]),
                            _c("li", [
                              _vm._v(_vm._s(item.answerVersionName || ""))
                            ]),
                            (item.answerType == 0 ||
                              item.answerType == 3 ||
                              item.answerType == 5) &&
                            !row.confirmVersionName
                              ? _c(
                                  "li",
                                  { staticClass: "action-box" },
                                  [
                                    item.answerType == 3 || item.answerType == 5
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "answers-btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.customClick(
                                                  row.ratingSpliderId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("确认")]
                                        )
                                      : _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确认版别名称么？",
                                              "ok-text": "确认",
                                              "cancel-text": "取消"
                                            },
                                            on: {
                                              confirm: function($event) {
                                                return _vm.confirmVersion(
                                                  row.ratingSpliderId,
                                                  item.coinKindItemVersionSid
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "answers-btn" },
                                              [_vm._v("确认")]
                                            )
                                          ]
                                        ),
                                    item.delayStatus != 1
                                      ? _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确认延迟确认么？",
                                              "ok-text": "确认",
                                              "cancel-text": "取消"
                                            },
                                            on: {
                                              confirm: function($event) {
                                                return _vm.delayConfirmVersion(
                                                  row.ratingSpliderId,
                                                  item.coinKindItemVersionSid
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "answers-btn" },
                                              [_vm._v("延迟确认")]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  )
                }
              },
              {
                key: "name",
                fn: function(text) {
                  return _c("a", {}, [_vm._v(_vm._s(text))])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.taPianViewPicture(row)
                            }
                          }
                        },
                        [_vm._v("拓片比较")]
                      ),
                      row.confirmVersionName == null ||
                      row.confirmVersionName.length == 0
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.customClick(row.ratingSpliderId)
                                }
                              }
                            },
                            [_vm._v("自定义确认")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.viewPicture(row)
                                }
                              }
                            },
                            [_vm._v("原图对版")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要裁剪此项么？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.cutConfrim(row.ratingSpliderId)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: { type: "primary", size: "small" }
                            },
                            [_vm._v("裁剪")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要操作此项么？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.ratingSpliderId)
                            }
                          }
                        },
                        [
                          row.confirmVersionName == null ||
                          row.confirmVersionName.length == 0
                            ? _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-top": "5px" },
                                  attrs: { type: "danger", size: "small" }
                                },
                                [_vm._v("废弃")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "自定义确认",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelCustomModal, ok: _vm.confirmCustomModal },
          model: {
            value: _vm.customModal,
            callback: function($$v) {
              _vm.customModal = $$v
            },
            expression: "customModal"
          }
        },
        [
          _c(
            "a-select",
            {
              staticStyle: { margin: "5px", width: "280px" },
              attrs: {
                showSearch: "",
                allowClear: "",
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "请选择版别"
              },
              on: { search: _vm.getConfirmVersionList },
              model: {
                value: _vm.coinKindItemVersionSid,
                callback: function($$v) {
                  _vm.coinKindItemVersionSid = $$v
                },
                expression: "coinKindItemVersionSid"
              }
            },
            _vm._l(_vm.confirmVersionList, function(item) {
              return _c(
                "a-select-option",
                { key: item.sid, attrs: { value: item.sid } },
                [
                  _vm._v(
                    _vm._s(
                      (item.coinKindVersionName || "") +
                        "-" +
                        (item.coinKindItemName || "")
                    )
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _c("ViewPicture", { ref: "showPicture" }),
      _c("TaPianViewPicture", { ref: "taPianShowPicture" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }