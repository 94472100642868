import {objArrMapObj} from "@/untils/otherEvent"

/** 首页公告弹窗列表 */
export const appHomeNoticeTableColumns = [
    { title: "公告ID", align: "center", scopedSlots: {customRender: 'itemIdSlot'}, width: 120 },
    { title: "公告标题", align: "center", scopedSlots: {customRender: 'itemTitleSlot'}, width: 200 },
    { title: "公告内容", align: "center", scopedSlots: {customRender: 'itemContentSlot'}, width: 300 },
    { title: "发布状态", align: "center", scopedSlots: {customRender: 'itemStatusSlot'}, width: 100 },
    { title: "操作时间", align: "center", scopedSlots: {customRender: 'itemCreateOrUpdateAboutSlot'}, width: 280 },
    { title: "操作相关", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 200 }
]

/** 公告上线状态映射----start */
export const noticeStatusList = [
    { name: '草稿', value: 0 },
    { name: '已发布', value: 1 },
]
/** 样式类 */
export const noticeStatusClassType = {
    0: 'color-red',
    1: 'color-green',
}
export const homeNoticeStatusListMapText = (value) => {
    return objArrMapObj('value', 'name', noticeStatusList)[value]
}
/** 公告上线状态映射----end */

/** 公告是否可以点击遮罩层关闭----start */
export const noticeOnclickOverlayCloseStatusList = [
    { name: '不能关闭', value: 0 },
    { name: '可以关闭', value: 1 },
]
/** 样式类 */
export const noticeOnclickOverlayCloseStatusListClassType = {
    0: 'color-red',
    1: 'color-green',
}
export const noticeOnclickOverlayCloseStatusListMapText = (value) => {
    return objArrMapObj('value', 'name', noticeOnclickOverlayCloseStatusList)[value]
}
/** 公告是否可以点击遮罩层关闭----end */

/** 公告展示期间显示方式----start */
export const appHomeNoticeShowTimeTypeList = [
    { name: '仅一次', value: 'onlyOnce' },
    { name: '一天一次', value: 'oneDayOne' },
    { name: '从不（仅展示横幅）', value: 'noWay' },
    // { name: '一直显示', value: 'alwaysShow' },
]
/** 消息通知模版类型样式类 */
export const appHomeNoticeShowTimeClassType = {
    onlyOnce: 'color-green',
    oneDayOne: 'color-blue',
    noWay: 'color-red',
}

export const appHomeNoticeShowTimeTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', appHomeNoticeShowTimeTypeList)[value]
}
/** 公告展示期间显示方式----end */

/** 公告内容类型----start */
export const appHomeNoticeContentTypeList = [
    { name: '富文本', value: 1 },
    { name: '图片', value: 2 },
]
/** 消息通知模版类型样式类 */
export const appHomeNoticeContentClassType = {
    1: 'color-blue',
    2: 'color-orange',
}

export const messageTemplateContentTypeMapText = (value) => {
    return objArrMapObj('value', 'name', appHomeNoticeContentTypeList)[value]
}
/** 公告内容类型----end */