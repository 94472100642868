var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "币种模型版本进度", width: 500, footer: null },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticStyle: { background: "#ECECEC", padding: "15px" } },
        [
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-card",
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "验证集数量",
                          "value-style": { color: "green" },
                          value: _vm.progressInfo.allNum || 0
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-card",
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "已对版数量",
                          "value-style": { color: "green" },
                          value: _vm.progressInfo.duibanNum || 0
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-card",
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "进度",
                          "value-style": { color: "green" },
                          suffix: "%",
                          value: _vm.progressInfo.percentage || 0
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }