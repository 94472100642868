var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "快递揽收详情", footer: null },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _vm.logisticsData
        ? _c("a-card", [
            _c("p", [
              _vm._v("取件码："),
              _c(
                "span",
                {
                  staticClass: "color-black font-weight-bold font-size-16",
                  class: _vm.logisticsData.pickupCode
                    ? "color-green"
                    : "color-black"
                },
                [_vm._v(_vm._s(_vm.logisticsData.pickupCode || "暂无提供"))]
              )
            ]),
            _c("p", [
              _vm._v("快递员："),
              _c("span", { staticClass: "color-blue font-weight-bold" }, [
                _vm._v(_vm._s(_vm.logisticsData.courierName))
              ])
            ]),
            _c("p", [
              _vm._v("快递员手机号码："),
              _c("span", { staticClass: "color-blue font-weight-bold" }, [
                _vm._v(_vm._s(_vm.logisticsData.courierMobile))
              ])
            ]),
            _c("p", [
              _vm._v("快递预约时间："),
              _c("span", { staticClass: "color-blue font-weight-bold" }, [
                _vm._v(_vm._s(_vm.logisticsData.makeAppointmentTime))
              ])
            ]),
            _c("p", [
              _vm._v("网点电话："),
              _c(
                "span",
                {
                  staticClass: "font-weight-bold",
                  class: _vm.logisticsData.netTel ? "color-blue" : "color-black"
                },
                [_vm._v(_vm._s(_vm.logisticsData.netTel || "暂无提供"))]
              )
            ]),
            _c("p", [
              _vm._v("用户取消原因："),
              _c(
                "span",
                {
                  staticClass: "font-weight-bold",
                  class: _vm.logisticsData.cancelMsg9
                    ? "color-red"
                    : "color-black"
                },
                [_vm._v(_vm._s(_vm.logisticsData.cancelMsg9 || "无"))]
              )
            ]),
            _c("p", [
              _vm._v("系统取消或下单失败原因："),
              _c(
                "span",
                {
                  staticClass: "font-weight-bold",
                  class: _vm.logisticsData.cancelMsg99
                    ? "color-red"
                    : "color-black"
                },
                [_vm._v(_vm._s(_vm.logisticsData.cancelMsg99 || "无"))]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }