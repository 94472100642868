var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-big-small-version-coin-pages" },
    [
      _vm._t("searchParamsBefore"),
      _c(
        "a-select",
        {
          staticClass: "w-150 ml-10",
          attrs: {
            dropdownMatchSelectWidth: false,
            "filter-option": _vm.untils.filterOption,
            allowClear: "",
            showSearch: "",
            size: _vm.boxSize,
            placeholder: "请选择大币种"
          },
          on: { change: _vm.changeCoin, search: _vm.coinSearch },
          model: {
            value: _vm.params.coinKindId,
            callback: function($$v) {
              _vm.$set(_vm.params, "coinKindId", $$v)
            },
            expression: "params.coinKindId"
          }
        },
        _vm._l(_vm.bigCoinList, function(item) {
          return _c(
            "a-select-option",
            { key: item.id, attrs: { value: item.id } },
            [
              _vm._v(
                _vm._s(
                  item.coinKindName +
                    "【" +
                    item.id +
                    "】" +
                    (item.coinCategory ? "---" + item.coinCategory : "")
                )
              )
            ]
          )
        }),
        1
      ),
      _c(
        "a-select",
        {
          staticClass: "w-150 ml-10",
          attrs: {
            dropdownMatchSelectWidth: false,
            "filter-option": _vm.untils.filterOption,
            allowClear: "",
            showSearch: "",
            size: _vm.boxSize,
            placeholder: "请选择小币种"
          },
          on: { change: _vm.changeCoinItem },
          model: {
            value: _vm.params.coinKindItemId,
            callback: function($$v) {
              _vm.$set(_vm.params, "coinKindItemId", $$v)
            },
            expression: "params.coinKindItemId"
          }
        },
        _vm._l(_vm.smallCoinList, function(item) {
          return _c(
            "a-select-option",
            { key: item.id, attrs: { value: item.id } },
            [_vm._v(_vm._s(item.coinKindItemName))]
          )
        }),
        1
      ),
      _c(
        "a-select",
        {
          staticClass: "w-250 ml-10",
          attrs: {
            dropdownMatchSelectWidth: false,
            allowClear: "",
            showSearch: "",
            "filter-option": _vm.untils.filterOption,
            size: _vm.boxSize,
            dropdownMenuStyle: { "max-height": "500px" },
            placeholder: "版别"
          },
          model: {
            value: _vm.params.coinKindItemVersionId,
            callback: function($$v) {
              _vm.$set(_vm.params, "coinKindItemVersionId", $$v)
            },
            expression: "params.coinKindItemVersionId"
          }
        },
        _vm._l(_vm.versionList, function(item) {
          return _c(
            "a-select-option",
            { key: item.coinVersionId, attrs: { value: item.coinVersionId } },
            [
              _vm._v(
                _vm._s(
                  "【" +
                    (item.level ? item.level : "无") +
                    "】 " +
                    item.coinVersionName +
                    "(" +
                    (item.size || 0) +
                    ")"
                )
              )
            ]
          )
        }),
        1
      ),
      _vm._t("searchParamsAfter")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }