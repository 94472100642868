import {cityOptions} from "@/untils/cityOption"

/** 内容列表 */
export const h5AreaJson = {
    province_list: {},
    city_list: {},
    county_list: {},
}

const makeOption = (text, value, children = []) => ({
    text,
    value,
    children,
});



/** h5用省市区导出服务---省份 */
export const h5AreaExportServiceByProvinceList = ($) => {
    cityOptions.forEach(item => {
        h5AreaJson.province_list[item.code.padEnd(6, '0')] = item.name
    })
    console.log(JSON.stringify(h5AreaJson.province_list))
}

/** h5用省市区导出服务---市区 */
export const h5AreaExportServiceByCityList = ($) => {
    cityOptions.forEach(item => {
        item.children.forEach(el => {
            h5AreaJson.city_list[el.code.padEnd(6, '0')] = el.name
        })
    })
    console.log(JSON.stringify(h5AreaJson.city_list))
}

/** h5用省市区导出服务---县区 */
export const h5AreaExportServiceByCountryList = ($) => {
    cityOptions.forEach(item => {
        item.children.forEach(el => {
            el.children.forEach((elSon, index) => {
                const elSonCode = Number(el.code.padEnd(6, '0')) + (index + 1)
                h5AreaJson.county_list[elSonCode] = elSon.name
            })
        })
    })
    console.log(JSON.stringify(h5AreaJson.county_list))
}

/** h5用省市区导出服务---全部 */
export const h5AreaExportServiceByAreaJson = ($) => {
    if (!Object.keys(h5AreaJson.province_list).length) return $.$message.warning('请先导出省份')
    if (!Object.keys(h5AreaJson.city_list).length) return $.$message.warning('请先导出市区')
    if (!Object.keys(h5AreaJson.county_list).length) return $.$message.warning('请先导出县区')

    const {
        city_list: city,
        county_list: county,
        province_list: province,
    } = h5AreaJson;

    const provinceMap = new Map(); // 省份映射
    Object.keys(province).forEach((code) => {
        provinceMap.set(code.slice(0, 2), makeOption(province[code], code, []));
    });

    const cityMap = new Map(); // 市区映射

    Object.keys(city).forEach((code) => {
        const option = makeOption(city[code], code, []);
        cityMap.set(code.slice(0, 4), option);
        const province = provinceMap.get(code.slice(0, 2));
        if (province) {
            province.children.push(option);
        }
    });

    Object.keys(county).forEach((code) => {
        const city = cityMap.get(code.slice(0, 4));
        if (city) {
            city.children.push(makeOption(county[code], code));
        }
    });

    // console.log(JSON.stringify(Array.from(provinceMap.values())))
    console.log(JSON.stringify(h5AreaJson))
}