import instance from "@/axios"

/** 获取OSS文件列表 */
export const getOSSFileListApi = async (params) => {
    return instance({
        url: '/dq_admin/ossFileMaintain/predictDelFileList',
        method: 'GET',
        params: params
    })
}

/** 获取OSS下载文件 */
export const getOSSFileDownloadApi = async (params) => {
    return instance({
        url: '/dq_admin/ossFileMaintain/predictDelFileDownLoad',
        method: 'GET',
        params: params
    })
}

/** 删除OSS文件 */
export const postDelOSSFileApi = async (params) => {
    return instance({
        url: '/dq_admin/ossFileMaintain/delOssFile',
        method: 'get',
        params: params
    })
}