import instance from "@/axios"

/** 获取币种模型校验列表数据 */
export const getCoinModelVersionListApi = async (params) => {
    return instance({
        url: '/dq_admin/modelCheck/listResult',
        method: 'GET',
        params: params
    })
}

/** 添加币种模型校验 */
export const postCoinModelVersionApi = async (params) => {
    return instance({
        url: '/dq_admin/modelCheck/addCoinModelQuality',
        method: 'POST',
        data: params
    })
}

/** 查看币种模型校验进度 */
export const getCoinModelVersionProgressApi = async (params) => {
    return instance({
        url: '/dq_admin/modelCheck/qualityProcessing',
        method: 'get',
        params: params
    })
}

/** 执行任务 */
export const getModelVersionExecuteTaskApi = async (params) => {
    return instance({
        url: '/dq_admin/modelCheck/recoQualityTask',
        method: 'get',
        params: params
    })
}