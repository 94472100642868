/** 字符串时间转化为时间戳 */
export const convertStringToTimestamp = (dateString) => {
    return new Date(dateString).getTime();
}

/** 获取当前日期 */
export const getCurrentDate = function (splitType) {
    const date = new Date();
    //年 getFullYear()：四位数字返回年份
    const year = date.getFullYear(); //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    const month = (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    const day = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    //时 getHours()：(0 ~ 23)
    const hour = date.getHours();
    //分 getMinutes()： (0 ~ 59)
    const minute = date.getMinutes();
    //秒 getSeconds()：(0 ~ 59)
    const second = date.getSeconds();
    if (splitType) {
        return {
            timeStr: year + splitType + month + splitType + day + ' ' + hour + ':' + minute + ':' + second,
            dayStr: year + splitType + month + splitType + day,
            monthStr: year + splitType + month,
        }
    } else {
        return {
            timeStr: year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second,
            dayStr: year + '/' + month + '/' + day,
            monthStr: year + '/' + month,
        }
    }
}