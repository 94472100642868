var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "错误收集" },
      on: { cancel: _vm.cancelModal, ok: _vm.confirmModal },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "大币种" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-200",
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    dropdownMenuStyle: { "max-height": "350px" },
                    allowClear: "",
                    showSearch: "",
                    placeholder: "请选择大币种"
                  },
                  on: { search: _vm.searchData },
                  model: {
                    value: _vm.params.coinId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "coinId", $$v)
                    },
                    expression: "params.coinId"
                  }
                },
                _vm._l(_vm.coinList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.id, attrs: { value: items.id } },
                    [
                      _vm._v(
                        _vm._s(items.coinKindName + "【" + items.id + "】")
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "正反面" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.params.frontBackSide,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "frontBackSide", $$v)
                    },
                    expression: "params.frontBackSide"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v("正面")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v("反面")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "清晰度" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.params.clarity,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "clarity", $$v)
                    },
                    expression: "params.clarity"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v("正常")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v("模糊")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "角度" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.params.angle,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "angle", $$v)
                    },
                    expression: "params.angle"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v("正常")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v("倾斜")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "锈迹" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.params.rust,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "rust", $$v)
                    },
                    expression: "params.rust"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v("无锈")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v("重锈")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }