var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: _vm.shamTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.params, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "假币类型" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { margin: "5px", width: "180px" },
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        dropdownMenuStyle: { "max-height": "350px" },
                        allowClear: "",
                        showSearch: "",
                        placeholder: "假币类型"
                      },
                      on: { click: _vm.handleChangeShamType },
                      model: {
                        value: _vm.params.shamType,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "shamType", $$v)
                        },
                        expression: "params.shamType"
                      }
                    },
                    _vm._l(_vm.shamTypeList, function(items) {
                      return _c(
                        "a-select-option",
                        { key: items.value, attrs: { value: items.value } },
                        [_vm._v(_vm._s(items.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.shamNameText } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: _vm.params.shamType
                        ? "名称"
                        : "请先选择假币类型"
                    },
                    model: {
                      value: _vm.params.shamName,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "shamName", $$v)
                      },
                      expression: "params.shamName"
                    }
                  })
                ],
                1
              ),
              [10].includes(_vm.params.shamType)
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "大币种" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { margin: "5px", width: "180px" },
                          attrs: {
                            dropdownMatchSelectWidth: false,
                            "filter-option": _vm.untils.filterOption,
                            dropdownMenuStyle: { "max-height": "350px" },
                            allowClear: "",
                            showSearch: "",
                            placeholder: "请选择大币种"
                          },
                          on: { search: _vm.searchData },
                          model: {
                            value: _vm.params.coinId,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "coinId", $$v)
                            },
                            expression: "params.coinId"
                          }
                        },
                        _vm._l(_vm.bigCoinList, function(items) {
                          return _c(
                            "a-select-option",
                            { key: items.id, attrs: { value: items.id } },
                            [
                              _vm._v(
                                _vm._s(
                                  items.coinKindName +
                                    "【" +
                                    items.id +
                                    "】" +
                                    (items.coinCategory
                                      ? "---" + items.coinCategory
                                      : "")
                                )
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              [1, 20].includes(_vm.params.shamType)
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "评级公司" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { allowClear: "", placeholder: "评级公司" },
                          model: {
                            value: _vm.params.companyType,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "companyType", $$v)
                            },
                            expression: "params.companyType"
                          }
                        },
                        _vm._l(_vm.ratingCompanyData, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.value))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.params.shamType === 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.codeTypeText } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          placeholder: _vm.params.shamType
                            ? "号段"
                            : "请先选择假币类型"
                        },
                        model: {
                          value: _vm.params.codeBatchMatch,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "codeBatchMatch", $$v)
                          },
                          expression: "params.codeBatchMatch"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.coverList,
                        multiple: true
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChangeCoverList
                      }
                    },
                    [
                      _vm.coverList.length < 15
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传图片")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注描述" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "备注描述", rows: 4 },
                    model: {
                      value: _vm.params.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "remark", $$v)
                      },
                      expression: "params.remark"
                    }
                  })
                ],
                1
              ),
              !_vm.shamId && [10, 20].includes(_vm.params.shamType)
                ? _c("a-divider", [
                    _vm._v(
                      "以下为" +
                        _vm._s(_vm.shamTypeText[_vm.params.shamType]) +
                        "盒子快捷单编号录入"
                    )
                  ])
                : _vm._e(),
              !_vm.shamId && [10].includes(_vm.params.shamType)
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "评级公司" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { allowClear: "", placeholder: "评级公司" },
                          model: {
                            value: _vm.params.boxCompanyType,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "boxCompanyType", $$v)
                            },
                            expression: "params.boxCompanyType"
                          }
                        },
                        _vm._l(_vm.ratingCompanyData, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.value))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.shamId && [10, 20].includes(_vm.params.shamType)
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.codeTypeText } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          placeholder: _vm.params.shamType
                            ? "号段"
                            : "请先选择假币类型"
                        },
                        model: {
                          value: _vm.params.boxCode,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "boxCode", $$v)
                          },
                          expression: "params.boxCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }