var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          scroll: { x: 800 },
          rowKey: function(record) {
            return record.id
          },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "image_1",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticClass: "image",
                  on: {
                    click: function($event) {
                      return _vm.editImage(row)
                    }
                  }
                },
                [
                  _c("img", {
                    staticStyle: {
                      "max-width": "100px",
                      "max-height": "200px"
                    },
                    attrs: { src: row.covers[0] }
                  })
                ]
              )
            }
          },
          {
            key: "image_2",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticClass: "image",
                  on: {
                    click: function($event) {
                      return _vm.editImage(row)
                    }
                  }
                },
                [
                  _c("img", {
                    staticStyle: {
                      "max-width": "100px",
                      "max-height": "200px"
                    },
                    attrs: { src: row.covers[1] }
                  })
                ]
              )
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticStyle: { display: "flex", "flex-direction": "column" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "5px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "是否确认?",
                            "ok-text": "Yes",
                            "cancel-text": "No",
                            disabled: row.markType == 1
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.confirm(row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                size: "small",
                                type: "primary",
                                disabled: row.markType == 1
                              }
                            },
                            [_vm._v("推送待标注")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-right": "5px" },
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: row.markType == 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.pushSampleClick(row)
                            }
                          }
                        },
                        [_vm._v("推送到底库")]
                      )
                    ],
                    1
                  )
                ]
              )
            }
          }
        ])
      }),
      _c(
        "a-modal",
        {
          attrs: {
            width: 1300,
            title: "推送到样本库",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelPushModal, ok: _vm.confirmPushModal },
          model: {
            value: _vm.pushModal,
            callback: function($$v) {
              _vm.pushModal = $$v
            },
            expression: "pushModal"
          }
        },
        [
          _c("div", { staticClass: "push-modal" }, [
            _vm.latelySearchBtnList && _vm.latelySearchBtnList.length
              ? _c(
                  "div",
                  { staticClass: "lately-search" },
                  [
                    _c("span", [_vm._v("最近搜索：")]),
                    _vm._l(_vm.latelySearchBtnList, function(item) {
                      return _c(
                        "a-popconfirm",
                        {
                          key: item.coinKindId,
                          attrs: {
                            title: "确定是为当前币种？",
                            "ok-text": "确定",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.handleLatelySearch(item)
                            }
                          }
                        },
                        [_c("a-button", [_vm._v(_vm._s(item.value))])],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "180px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      "filter-option": _vm.untils.filterOption,
                      allowClear: "",
                      showSearch: "",
                      size: "small",
                      placeholder: "请选择大币种"
                    },
                    on: { change: _vm.changeCoin, search: _vm.coinSearch },
                    model: {
                      value: _vm.curItem.coinKindId,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem, "coinKindId", $$v)
                      },
                      expression: "curItem.coinKindId"
                    }
                  },
                  _vm._l(_vm.coinList, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [
                        _vm._v(
                          _vm._s(item.coinKindName + "【" + item.id + "】")
                        )
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "180px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      "filter-option": _vm.untils.filterOption,
                      allowClear: "",
                      showSearch: "",
                      size: "small",
                      placeholder: "请选择小币种"
                    },
                    on: { change: _vm.changeCoinItem },
                    model: {
                      value: _vm.curItem.coinKindItemId,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem, "coinKindItemId", $$v)
                      },
                      expression: "curItem.coinKindItemId"
                    }
                  },
                  _vm._l(_vm.coinItemList, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.coinKindItemName))]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { margin: "5px", width: "180px" },
                    attrs: {
                      dropdownMatchSelectWidth: false,
                      allowClear: "",
                      showSearch: "",
                      "filter-option": _vm.untils.filterOption,
                      size: "small",
                      dropdownMenuStyle: { "max-height": "500px" },
                      placeholder: "版别"
                    },
                    on: { change: _vm.changeVersion },
                    model: {
                      value: _vm.curItem.coinKindItemVersionId,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem, "coinKindItemVersionId", $$v)
                      },
                      expression: "curItem.coinKindItemVersionId"
                    }
                  },
                  _vm._l(_vm.versionList, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s("" + item.coinKindVersionName))]
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "images" },
              _vm._l(_vm.curItem.covers, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "image-item" },
                  [
                    _c("img", { staticClass: "image", attrs: { src: item } }),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "Are you sure delete this item?",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.deleteImage(index)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          { attrs: { size: "small", type: "danger" } },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        ]
      ),
      _c("ModalEditImage", {
        ref: "edit-image",
        on: { success: _vm.editSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }