var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("a-input", {
          staticStyle: { width: "110px" },
          attrs: { allowClear: "", size: "small", placeholder: "问题ID" },
          model: {
            value: _vm.params.questionId,
            callback: function($$v) {
              _vm.$set(_vm.params, "questionId", $$v)
            },
            expression: "params.questionId"
          }
        }),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "180px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              dropdownMenuStyle: { "max-height": "350px" },
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "请选择大币种"
            },
            on: {
              search: _vm.searchData,
              change: function($event) {
                return _vm.handleChangeCoin($event)
              }
            },
            model: {
              value: _vm.params.coinKindId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinKindId", $$v)
              },
              expression: "params.coinKindId"
            }
          },
          _vm._l(_vm.bigCoinList, function(items) {
            return _c(
              "a-select-option",
              { key: items.id, attrs: { value: items.id } },
              [_vm._v(_vm._s(items.coinKindName + "【" + items.id + "】"))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "180px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              "filter-option": _vm.untils.filterOption,
              allowClear: "",
              showSearch: "",
              size: "small",
              placeholder: "请选择小币种"
            },
            on: { change: _vm.coinItemChange },
            model: {
              value: _vm.params.coinKindItemId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinKindItemId", $$v)
              },
              expression: "params.coinKindItemId"
            }
          },
          _vm._l(_vm.smallCoinList, function(item) {
            return _c(
              "a-select-option",
              {
                key: item.coinKindItemId,
                attrs: { value: item.coinKindItemId }
              },
              [_vm._v(_vm._s(item.coinKindItemName))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "260px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              showSearch: "",
              "filter-option": _vm.untils.filterOption,
              size: "small",
              dropdownMenuStyle: { "max-height": "500px" },
              placeholder: "请选择版别"
            },
            model: {
              value: _vm.params.coinVersionId,
              callback: function($$v) {
                _vm.$set(_vm.params, "coinVersionId", $$v)
              },
              expression: "params.coinVersionId"
            }
          },
          _vm._l(_vm.versionList, function(item) {
            return _c(
              "a-select-option",
              { key: item.coinVersionId, attrs: { value: item.coinVersionId } },
              [
                _vm._v(
                  _vm._s(
                    "【" +
                      (item.level ? item.level : "无") +
                      "】 " +
                      item.coinVersionName
                  )
                )
              ]
            )
          }),
          1
        ),
        false
          ? _c(
              "a-select",
              {
                staticStyle: { margin: "5px", width: "180px" },
                attrs: {
                  dropdownMatchSelectWidth: false,
                  allowClear: "",
                  "filter-option": _vm.untils.filterOption,
                  size: "small",
                  placeholder: "回答老师"
                },
                model: {
                  value: _vm.params.answerUserId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "answerUserId", $$v)
                  },
                  expression: "params.answerUserId"
                }
              },
              _vm._l(_vm.answerUserList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.userId, attrs: { value: item.userId } },
                  [_vm._v(_vm._s(item.userName))]
                )
              }),
              1
            )
          : _vm._e(),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "100px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              "filter-option": _vm.untils.filterOption,
              size: "small",
              placeholder: "标签类型"
            },
            model: {
              value: _vm.params.tagStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "tagStatus", $$v)
              },
              expression: "params.tagStatus"
            }
          },
          _vm._l(_vm.tagTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "100px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              "filter-option": _vm.untils.filterOption,
              size: "small",
              placeholder: "操作类型"
            },
            model: {
              value: _vm.params.operateStatus,
              callback: function($$v) {
                _vm.$set(_vm.params, "operateStatus", $$v)
              },
              expression: "params.operateStatus"
            }
          },
          _vm._l(_vm.operateStatusList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-select",
          {
            staticClass: "m-5 w-150",
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              "filter-option": _vm.untils.filterOption,
              size: "small",
              placeholder: "问题来源"
            },
            model: {
              value: _vm.params.sourceType,
              callback: function($$v) {
                _vm.$set(_vm.params, "sourceType", $$v)
              },
              expression: "params.sourceType"
            }
          },
          _vm._l(_vm.sourceTypeTypeList, function(item) {
            return _c(
              "a-select-option",
              { key: item.value, attrs: { value: item.value } },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          1
        ),
        _c(
          "a-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("搜索")]
        ),
        _c(
          "a-popconfirm",
          {
            attrs: {
              title: "确定要批量处理吗？",
              "ok-text": "确认",
              "cancel-text": "取消"
            },
            on: { confirm: _vm.handleAllConfirm }
          },
          [
            _c(
              "a-button",
              {
                staticClass: "ml-20",
                attrs: {
                  size: "small",
                  disabled: _vm.selectedArrays.length <= 0
                }
              },
              [_vm._v("批量已处理")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("QuestionAnswerTable", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: {
            changePage: _vm.handleChangePage,
            successStore: _vm.editSuccess,
            selectTable: _vm.handleSelectTable,
            changeVersionSuccess: _vm.editSuccess,
            resetVersion: function($event) {
              return _vm.handleResetVersion($event)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }