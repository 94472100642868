export default [
    {
        path: '/cms/coupon/manage',
        name: 'coupon',
        component: resolve => require(['@/views/cmsPage/dqSys/couponManage/index.vue'], resolve)
    },
    {
        path: '/cms/appnotice/pushmanage',
        name: 'pushmanage',
        component: resolve => require(['@/views/cmsPage/dqSys/pushMessageManage/index.vue'], resolve)
    },
    {
        path: '/cms/osspreviewdelete/listmanage',
        name: 'osspreviewdelete',
        component: resolve => require(['@/views/cmsPage/dqSys/OSSFileMange/index.vue'], resolve)
    },
]