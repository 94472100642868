var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleOSSFileDownload(row)
                          }
                        }
                      },
                      [_vm._v("下载")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-20" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "确认删除当前OSS文件？" },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [
                        _c("a-button", { attrs: { type: "danger" } }, [
                          _vm._v("删除")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }