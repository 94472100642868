<template>
  <a-modal
      centered
      :width='700'
      title="批量推送数据"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
        <a-form-model-item label='推送的用户类型' prop='content' v-if="false">
          <a-select
              v-model="userType"
              :filter-option="untils.filterOption"
              showSearch
              allowClear
              placeholder="请选择"
              @change="handleChangeUserType"
          >
            <a-select-option
                v-for="(item, index) of userTypeList"
                :key="index"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
            v-if="currentStorageData.length > 1"
            label='上次已选择用户'
            prop='content'
        >
          <template  v-for="item in currentStorageData">
            <a-tag
                color="blue"
                closable
                :key="item.userId"
                @close="handleDeleteTag($event, item)"
            >{{ item.userName + `【${item.userId}】` }}</a-tag>
          </template>
          <a-checkbox v-model:checked='isUserLocalStoreData' @change="handleChangeCheck">是否使用上次选择的用户</a-checkbox>
        </a-form-model-item>
<!--    切换用户权限    -->
        <a-form-model-item label="授权用户类型">
          <a-radio-group
              v-model="authUserVersionType"
              default-value="quickVersion"
              button-style="solid"
              @change="handleChangeAuthUserVersionType"
          >
            <a-radio-button value="quickVersion">快速标版权限用户</a-radio-button>
            <a-radio-button value="rateVersion">评级确版权限用户</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="userAuthList.length" label='选择用户' prop='content'>
          <a-select
              @search="searchUserData"
              v-model="forms.targetUserIdList"
              :filter-option="untils.filterOption"
              showSearch
              mode="multiple"
              placeholder="请选择"
              @change="handleChangeSelectedUser"
          >
            <a-select-option
                v-for="(item, index) of userAuthList"
                :key="index"
                :value="item.userId"
            >{{ item.userName + `【${item.userId}】` }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {userTypeList} from "@/views/cmsPage/versionManage/duibanQuestionList/_data"
export default {
  computed: {
  },
  data() {
    return {
      untils,
      authUserVersionType: 'quickVersion',
      isUserLocalStoreData: false,
      title: '推送给普通用户',
      showModal: false,
      userAuthList: [],
      userType: undefined,
      userTypeList: userTypeList,
      authParams: {
        userId: undefined,
        userName: undefined,
        coinId: undefined,
        pageNum: 1,
        pageSize: 10
      },
      forms: {
        targetUserIdList: undefined,
        questionIdList: undefined
      },
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules: {},
      localStorageDataByRate: [],
      localStorageDataByNormal: [],
      currentStorageData: [],
      // 选择之后缓存
      selectedStorageData: [],
      searchAuthUserListApi: {
        quickVersion: '/dq_admin/duibanNormalAuthCoin/getAuthorityList',
        rateVersion: '/dq_admin/duibanRatingAuthCoin/getAuthorityList'
      }
    }
  },
  methods: {
     async show(data, coinId) {
       this.$loading.show()
       this.authUserVersionType = 'quickVersion'
       this.authParams.coinId = coinId
       await this.handleChangeUserType()
       this.$loading.hide()
       this.forms.questionIdList = data
       if (localStorage.getItem('question_push_local_data_by_normal')) {
         this.localStorageDataByNormal = JSON.parse(localStorage.getItem('question_push_local_data_by_normal'))
       }
       if (localStorage.getItem('question_push_local_data_by_rate')) {
         this.localStorageDataByRate = JSON.parse(localStorage.getItem('question_push_local_data_by_rate'))
       }
       if (this.authUserVersionType === 'quickVersion') {
         this.forms.targetUserIdList = this.findCommonElements(this.userAuthList.map(el => el.userId), this.localStorageDataByNormal)
       } else if (this.authUserVersionType === 'rateVersion') {
         this.forms.targetUserIdList = this.findCommonElements(this.userAuthList.map(el => el.userId), this.localStorageDataByRate)
       }
       this.showModal = true
    },
    /** 改变用户类型 */
    async handleChangeUserType(val) {
      this.forms.targetUserIdList = undefined
      await this.searchUserData('')
    },
    /** 数组过滤 */
    findCommonElements(array1, array2) {
      return array1.filter(item => array2.includes(item));
    },
    /** 改变选择的用户 */
    handleChangeSelectedUser() {
    },
    /** 删除已经选择的 */
    handleDeleteTag(e, item) {
      e.preventDefault();
      const foundIndex = this.currentStorageData.findIndex(el => el.userId === item.userId)
      this.currentStorageData.splice(foundIndex, 1)
    },
    /** 确定 */
    async handleConfirm() {
      // this.forms.targetUserIdList = this.selectedStorageData.map(el => el.userId)
      const res = await this.axios.post('/dq_admin/duibanPushQuestion/pushTargetUserToAnswer', this.forms)
      if (res.status !== '200') return
      if (this.authUserVersionType === 'quickVersion') {
        localStorage.setItem('question_push_local_data_by_normal', JSON.stringify(this.forms.targetUserIdList))
      } else if (this.authUserVersionType === 'rateVersion') {
        localStorage.setItem('question_push_local_data_by_rate', JSON.stringify(this.forms.targetUserIdList))
      }
      this.$message.info(res.message)
      this.$emit('addOrEditorSuccess')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.resetForms()
      this.showModal = false
    },
    /** 重置表单 */
    resetForms() {
      this.forms = {
        targetUserIdList: undefined,
        questionIdList: undefined
      }
      this.isUserLocalStoreData = false
      this.userType = undefined
      this.selectedStorageData = []
      this.localStorageDataByRate = []
      this.localStorageDataByNormal = []
    },
    /** 搜索用户---快速标版 */
    async searchUserData(inner) {
      this.authParams.userName = inner
      const url = this.searchAuthUserListApi[this.authUserVersionType]
      const res = await this.axios(url, {
        params: this.authParams
      });
      if (res.status !== '200') return;
      this.userAuthList = res.data.records;
    },
    /** 改变授权用户列表 */
    async handleChangeAuthUserVersionType(e) {
      this.authUserVersionType = e.target.value
      await this.searchUserData('')
      if (this.authUserVersionType === 'quickVersion') {
        this.forms.targetUserIdList = this.findCommonElements(this.userAuthList.map(el => el.userId), this.localStorageDataByNormal)
      } else if (this.authUserVersionType === 'rateVersion') {
        this.forms.targetUserIdList = this.findCommonElements(this.userAuthList.map(el => el.userId), this.localStorageDataByRate)
      }
    },
    handleChangeCheck() {

    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-form-item-control {
  line-height: 30px !important;
}

</style>