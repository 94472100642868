var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "延长商家确认收货" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({ ref: "ruleForm" }, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "延长天数（整数）" } },
            [
              _c("a-input", {
                staticClass: "w-300",
                attrs: { type: "number", placeholder: "延长天数（整数）" },
                model: {
                  value: _vm.params.daySize,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "daySize", $$v)
                  },
                  expression: "params.daySize"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }