import instance from "@/axios"

/** 搜索朝代---根据文本搜索 */
export const getDynastyListByName = async (str) => {
    return instance({
        url: '/dq_admin/dynasty/searchDynasty',
        method: 'GET',
        params: { dynastyName: str },
    })
}
/** 根据朝代ID搜索大币种 */
export const getBigCoinListByDynastyId = async (id) => {
    return instance({
        url: '/dq_admin/kind/listCoinListByDynastyId',
        method: 'GET',
        params: { dynastyId: id },
    })
}
/** 根据ID，名称搜索朝代 */
export const getCustomDynastyListByNameApi = async (nameOrId) => {
    return instance({
        url: '/dq_admin/duibanSupportCustomDynasty/list',
        method: 'GET',
        params: {
            customDynastyId: undefined,
            customDynastyName: nameOrId,
            pageNum: 1,
            pageSize: 100,
        }
    })
}

/** 搜索大币种---根据文本搜索
 * /dq_admin/kind/list
 * @param str String 搜索字符
 * @param notCoinTypeList Array<number>  大币种类型，1：古钱币，2：银元，3：铜元，5：花钱，6：邻国，7：杂项，8：近代币，9：纸币
 * @param categoryId {number} 只搜某个币种类型, 与notCoinTypeList 互斥
 * */
export const getBigCoinList = async (str, notCoinTypeList = [], categoryId = undefined) => {
    return instance({
        url: '/dq_admin/kind/list',
        method: 'GET',
        params: {
            coinName: str,
            notContainCategoryIdList: notCoinTypeList.join(','),
            categoryId: categoryId,
            pageSize: 20,
            pageNum: 1
        },
    })
}

/** 搜索小币种  /dq_admin/kind/getItemAndVersionNew 参数：coinSid: 大币种对应的sid
 * @params {String} coinSid - 大币种sid
 * */
export const getSmallCoinList = async (coinSid) => {
    return instance({
        url: '/dq_admin/kind/getItemAndVersionNew',
        method: 'GET',
        params: { sid: coinSid },
    })
}

/** 搜索小币种--根据大币种ID，大币种名字搜索 */
export const getSmallCoinListByBigCoin = async (params) => {
    return instance({
        url: '/dq_admin/kinditem/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 200,
            coinId: params.coinId,
            coinItemName: params.coinItemName,
        }
    })
}

/** 获取版别 /dq_admin/duibanConfirm/listCoinVersions
 * @params {Number} coinId - 大币种ID
 * @params {Number} coinItemId - 小币种ID
 * */
export const getCoinVersionList = async (coinId, coinItemId) => {
    return instance({
        url: '/dq_admin/duibanConfirm/listCoinVersions',
        method: 'GET',
        params: {coinId, coinItemId},
    })
}
export const getCoinVersionListByPriceQuery = async (coinId, coinItemId) => {
    return instance({
        url: '/dq_admin/coinSilverVersionGoods/countPriceVersionsSize',
        method: 'GET',
        params: {coinId, coinItemId},
    })
}

/** 获取图片性质 */
export const getBookImgType = async () => {
    return instance({
        url: '/dq_admin/tukuNatureType/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 100,
            id: undefined,
            natureName: undefined
        }
    })
}

/** 添加活谱图片列表 */
export const addBookImgTypeList = async (params) => {
    return instance({
        url: '/dq_admin/tukuNatureType/addOrUpdate',
        method: 'POST',
        data: params
    })
}

/** 抠图测试 */
export const getImgCut = async (imgUrl, version) => {
    return instance({
        url: '/dq_admin/coinVersionNature/cutoutPointsImage',
        method: 'GET',
        params: {
            imageUrl: imgUrl,
            modelType: version
        }
    })
}

/** 用户检索---支持userID，userName */
export const getUserInfoByUserIdOrUserNameApi = async (searchName) => {
    return instance({
        url: '/dq_admin/user/getUserList',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 10,
            searchName: searchName,
        }
    })
}


/** 获取版别数据---重新对版 */
export const getResetVersionTopApi = async (recordIds) => {
    return instance({
        url: '/dq_admin/duibanTask/batchResetDuibanRecord',
        method: 'GET',
        params: {
            recordIds: recordIds,
        }
    })
}


/** 获取大币种类型分类 */
export const getBigCoinCategoryTypeListApi = async () => {
    return instance({
        url: '/dq_admin/category/list',
        method: 'GET',
        params: {
            pageSize: 100,
        }
    })
}

/** 搜索用户---平台用户 */
export const getUserDataListAPi = async (userName) => {
    return instance({
        url: '/dq_admin/user/list',
        method: 'GET',
        params: {
            pageSize: 20,
            limit: 20,
            userName: userName
        }
    })
}

/** 搜索用户----后台管理用户 */
export const getMangeUserDataListAPi = async (userName) => {
    return instance({
        url: '/platform/platformaccount/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 20,
            userName: userName
        }
    })
}
