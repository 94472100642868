
<template>
  <div class="content">
    <div class="top">
<!--   id搜索   -->
      <a-input
          size='small'
          type = 'text'
          style="margin: 5px; width: 180px"
          placeholder='ID搜索'
          v-model="searchId"
      ></a-input>
      <!--   选择来源   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="sourceTypeId"
          placeholder="请选择来源"
          @change="handleChangeSourceType($event)"
      >
        <a-select-option
            v-for="item of sourceTypeList"
            :key="item.id"
            :value="item.id"
        >{{ item.value }}</a-select-option>
      </a-select>
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 180px"
          v-model="coinKindId"
          placeholder="请选择大币种"
          @change="handleChangeCoin($event)"
      >
        <a-select-option
            v-for="items of coinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName + `【${items.id}】` + (items.coinCategory ? `---${items.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <!--   确认状态   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          size="small"
          style="margin: 5px; width: 180px"
          v-model="confirmType"
          placeholder="请选择确认状态"
          @change="handleConfirmStatus($event)"
      >
        <a-select-option
            v-for="items of confirmStatusList"
            :key="items.id"
            :value="items.id"
        >{{ items.value }}</a-select-option>
      </a-select>

      <a-button
          @click="search"
          size="small"
          type="primary"
      >搜索</a-button>

      <div class="select-all-text">
        <a-checkbox
            v-model="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
        >
          全选
        </a-checkbox>
      </div>
      <div class="change-box">
        <a-popconfirm
            title="确定吗？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleAllConfirm('confirm')"
        >
          <a-button
              size="small"
              type="primary"
              style="margin-right: 5px"
          >批量确定</a-button>
        </a-popconfirm>
        <a-button
            size="small"
            style="margin-right: 5px"
            @click="handleBatchMoveBigCoin"
        >批量挪动</a-button>
      </div>
      <div class="change-box">
        <button
            class="button-search  bgf"
            @click="uploadClick()"
        >手动上传</button>
      </div>
      <div>
        <a-popconfirm
            title="确定删除吗？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleBatchDeleteCoin"
        >
          <a-button
              size="small"
              style="margin-right: 5px"
              type="danger"
          >批量删除</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="table-content">
      <TrainList
          :list="trainList"
          @editSuccess="handleEditSuccess($event)"
          @deleteSuccess="handleDeleteSuccess($event)"
      ></TrainList>
      <div class="pagination">
        <a-pagination
            :pageSize.sync="pagination.pageSize"
            :current="pagination.current"
            :total="pagination.total"
            @change="changePage"
        />

      </div>
    </div>
    <TrainAdd
        ref="trainAdd"
        @success="addSuccess"
    />
    <TrainMove
        ref="trainMoveEl"
        @success="addSuccess"
    />
  </div>
</template>
<script>
import TrainList from "@/views/cmsPage/versionManage/duibanCoinTrain/TrainList";
import TrainAdd from "@/views/cmsPage/versionManage/duibanCoinTrain/TrainAdd";
import untils from "@/untils";
import TrainMove from "@/views/cmsPage/versionManage/duibanCoinTrain/TrainMove"
import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    TrainList,
    TrainAdd,
    TrainMove,
  },
  data() {
    return {
      untils,
      searchId: undefined,
      checkAll: false,
      indeterminate: false,
      trainList: [],
      coinKindId: undefined,
      coinList: [],
      confirmType: undefined,
      confirmStatusList: [
        {value: '未确认', id: 0},
        {value: '已确认', id: 1}
      ],
      sourceTypeId: undefined,
      sourceTypeList: [
        {value: '标注确认', id: 1},
        {value: '手动上传', id: 2},
        {value: 'GB/BC推送', id: 3},
        {value: 'QH 推送', id: 4},
        {value: '对版记录推送', id: 5},
        {value: '基础版别推送', id: 30},
        {value: '拍品推送', id: 60},
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
      },
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    trainList: {
      deep: true,
      handler() {
        let listStatus = []
        const listLength = this.trainList.length
        this.trainList.forEach(item => {
          if (item.checkStatus) {
            listStatus.push(item.id)
          }
        })
        if (listStatus.length === listLength) {
          this.checkAll = true
          this.indeterminate = false
        }
        if (listStatus.length > 0 && listStatus.length < listLength) {
          this.indeterminate = true
        }
        if (listStatus.length === 0) {
          this.checkAll = false
          this.indeterminate = false
        }
      }
    }
  },
  methods: {
    /** 挪动数据 */
    handleEditSuccess(id) {
      const foundIndex = this.trainList.findIndex(el => el.id === id)
      this.trainList.splice(foundIndex, 1)
    },
    /** 修改全选 */
    onCheckAllChange(e) {
      if (e.target.checked) {
        this.trainList.forEach(item => {
          item.checkStatus = true
        })
      } else {
        this.trainList.forEach(item => {
          item.checkStatus = false
        })
      }
    },
    /** 批量删除 */
    async handleBatchDeleteCoin() {
      let trainIds = []
      this.trainList.forEach(el => {
        if (el.checkStatus) {
          trainIds.push(el.id)
        }
      })
      if (trainIds && trainIds.length) {
        const trainIdsStr = trainIds.join(',')
        const res = await this.axios("/dq_admin/duibanCoinTrain/deleteByTrainId", {
          params: {
            trainIds: trainIdsStr
          }
        });
        if (res.status !== '200') return;
        this.$message.success(res.message|| '成功')
        this.handleDeleteSuccess('', trainIds)
      } else {
        return this.$message.warning('请选择需要删除的币种')
      }
    },
    /** 批量挪动 */
    async handleBatchMoveBigCoin() {
      let trainIds = []
      this.trainList.forEach(el => {
        if (el.checkStatus) {
          trainIds.push(el.id)
        }
      })
      const trainIdsStr = trainIds.join(',')
      this.$refs.trainMoveEl.show(trainIdsStr, 'batch')
    },
    /** 批量确认 */
    async handleAllConfirm() {
      let trainIds = []
      this.trainList.forEach(el => {
        if (el.checkStatus) {
          trainIds.push(el.id)
        }
      })
      if (trainIds && trainIds.length) {
        trainIds = trainIds.join(',')
        const res = await this.axios("/dq_admin/duibanCoinTrain/batchConfirm", {
          params: {
            trainIds
          }
        });
        if (res.status != 200) return;
        this.$message.success(res.message|| '成功')
        await this.getList()
      } else {
        return this.$message.warning('请选择需要确定的币种')
      }
    },
    /** 选择来源 */
    handleChangeSourceType(e) {
      this.sourceTypeId = e
      this.getList()
    },
    /** 选择确认状态 */
    handleConfirmStatus(e) {
      this.confirmType = e
      this.getList()
    },
    /** 改变大币种 */
    handleChangeCoin(e) {
      this.coinKindId = e
      this.getList()
    },
    /** 删除成功 */
    handleDeleteSuccess(id, ids) {
      const idArray = id && !ids ? [id] : ids
      this.trainList = this.trainList.filter(el => !idArray.includes(el.id))
    },
    /** 上传成功 */
    addSuccess() {
      this.getList();
    },
    /** 点击上传 */
    uploadClick() {
      this.$refs["trainAdd"].show();
    },
    /** 获取列表数据 */
    async getList() {
      const params = {
        coinId: this.coinKindId || '',
        sourceType: this.sourceTypeId || '',
        confirmType: this.confirmType,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        id: this.searchId
    }
      const res = await this.axios("/dq_admin/duibanCoinTrain/getTrainCoinList", {
        params: params
      });
      if (res.status != 200) return;
      this.checkAll = false
      this.indeterminate = false
      let list = res.data.records;
      list.forEach(item => {
        item.checkStatus = false
      })
      this.trainList = list;
      this.pagination.total = res.data.total;
    },
    /** 获取大币种列表 */
    async searchData(inner){
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.coinList = records
    },
    changePage(page) {
      this.pagination.current = page;
      this.getList();
    },
    search() {
      this.pagination.current = 1
      this.pagination.pageSize = 60
      this.getList()
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  .button-search {
    width: 100px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.bgf {
  background-color: #fff !important;
  border: 1px solid #000c17 !important;
  color: #000c17 !important;
  margin-left: 20px !important;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
