var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 1000,
        title: _vm.titleText,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.cancelPushModal, ok: _vm.confirmPushModal },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "div",
        { staticClass: "push-modal" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.curItem } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "版别信息：" } },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-10 w-150",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        dropdownMenuStyle: { "max-height": "350px" },
                        allowClear: "",
                        showSearch: "",
                        placeholder: "请选择大币种"
                      },
                      on: {
                        search: _vm.searchData,
                        change: function($event) {
                          return _vm.handleChangeCoin($event)
                        }
                      },
                      model: {
                        value: _vm.curItem.coinId,
                        callback: function($$v) {
                          _vm.$set(_vm.curItem, "coinId", $$v)
                        },
                        expression: "curItem.coinId"
                      }
                    },
                    _vm._l(_vm.bigCoinList, function(items) {
                      return _c(
                        "a-select-option",
                        { key: items.id, attrs: { value: items.id } },
                        [
                          _vm._v(
                            _vm._s(items.coinKindName + "【" + items.id + "】")
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-10 w-150",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        allowClear: "",
                        showSearch: "",
                        placeholder: "请选择小币种"
                      },
                      on: { change: _vm.coinItemChange },
                      model: {
                        value: _vm.curItem.coinItemId,
                        callback: function($$v) {
                          _vm.$set(_vm.curItem, "coinItemId", $$v)
                        },
                        expression: "curItem.coinItemId"
                      }
                    },
                    _vm._l(_vm.smallCoinList, function(item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.coinKindItemId,
                          attrs: { value: item.coinKindItemId }
                        },
                        [_vm._v(_vm._s(item.coinKindItemName))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-10 w-300",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        allowClear: "",
                        showSearch: "",
                        "filter-option": _vm.untils.filterOption,
                        dropdownMenuStyle: { "max-height": "500px" },
                        placeholder: "请选择版别"
                      },
                      on: { change: _vm.handleChangeVersion },
                      model: {
                        value: _vm.curItem.coinVersionId,
                        callback: function($$v) {
                          _vm.$set(_vm.curItem, "coinVersionId", $$v)
                        },
                        expression: "curItem.coinVersionId"
                      }
                    },
                    _vm._l(_vm.versionList, function(item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.coinVersionId,
                          attrs: { value: item.coinVersionId }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "【" +
                                (item.level ? item.level : "无") +
                                "】 " +
                                item.coinVersionName
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("a-form-item", { attrs: { label: "选择性质" } }, [
                _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                _c(
                  "div",
                  { staticClass: "nature-text-list" },
                  _vm._l(_vm.natureTypeMapText, function(tag) {
                    return _c(
                      "div",
                      {
                        key: tag.value,
                        staticClass:
                          "mr-10 mb-10 cur-pot font-size-32 p-10 nature-text-tag",
                        class:
                          tag.value === _vm.curItem.natureType
                            ? "active-tag"
                            : "",
                        on: {
                          click: function($event) {
                            return _vm.handleSelectedTag(tag)
                          }
                        }
                      },
                      [_vm._v(_vm._s(tag.name))]
                    )
                  }),
                  0
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "图片标题：" } },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _c("a-input", {
                    staticClass: "ml-10 w-600",
                    attrs: { allowClear: "", placeholder: "图片标题" },
                    model: {
                      value: _vm.curItem.title,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem, "title", $$v)
                      },
                      expression: "curItem.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "评级信息" } },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-10",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "评级公司"
                      },
                      on: { change: _vm.handleChangeSelectCompany },
                      model: {
                        value: _vm.curItem.ratingCompanyType,
                        callback: function($$v) {
                          _vm.$set(_vm.curItem, "ratingCompanyType", $$v)
                        },
                        expression: "curItem.ratingCompanyType"
                      }
                    },
                    _vm._l(_vm.ratingCompanyTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _vm.curItem.ratingCompanyType !== 0
                    ? _c("a-input", {
                        staticClass: "w-250 ml-20",
                        attrs: { allowClear: "", placeholder: "评级编号" },
                        model: {
                          value: _vm.curItem.ratingCompanyCode,
                          callback: function($$v) {
                            _vm.$set(_vm.curItem, "ratingCompanyCode", $$v)
                          },
                          expression: "curItem.ratingCompanyCode"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("a-form-item", { attrs: { label: "钱币数据：" } }, [
                _c(
                  "div",
                  { staticClass: "flex-start data-input" },
                  [
                    _c("a-input", {
                      staticClass: "w-200 ml-10",
                      attrs: { allowClear: "", placeholder: "质量" },
                      model: {
                        value: _vm.curItem.weightSizeInfo.weight,
                        callback: function($$v) {
                          _vm.$set(_vm.curItem.weightSizeInfo, "weight", $$v)
                        },
                        expression: "curItem.weightSizeInfo.weight"
                      }
                    }),
                    _c("span", { staticClass: "ml-5" }, [_vm._v("g")]),
                    _c("a-input", {
                      staticClass: "w-200 ml-20",
                      attrs: { allowClear: "", placeholder: "尺寸" },
                      model: {
                        value: _vm.curItem.weightSizeInfo.size,
                        callback: function($$v) {
                          _vm.$set(_vm.curItem.weightSizeInfo, "size", $$v)
                        },
                        expression: "curItem.weightSizeInfo.size"
                      }
                    }),
                    _c("span", { staticClass: "ml-5" }, [_vm._v("mm")])
                  ],
                  1
                )
              ]),
              _c(
                "a-form-item",
                { attrs: { label: "成交信息" } },
                [
                  _c("a-date-picker", {
                    staticClass: "ml-10",
                    attrs: { placeholder: "成交时间选择" },
                    on: { change: _vm.handleSelectedPicker },
                    model: {
                      value: _vm.curItem.goodPriceInfo.tradeTime,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem.goodPriceInfo, "tradeTime", $$v)
                      },
                      expression: "curItem.goodPriceInfo.tradeTime"
                    }
                  }),
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-20",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "交易平台"
                      },
                      on: { "change-": _vm.handleChangeSelectTradePlatform },
                      model: {
                        value: _vm.curItem.goodPriceInfo.tradePlatformType,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.curItem.goodPriceInfo,
                            "tradePlatformType",
                            $$v
                          )
                        },
                        expression: "curItem.goodPriceInfo.tradePlatformType"
                      }
                    },
                    _vm._l(_vm.tradingPlatformTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _c("a-input", {
                    staticClass: "w-150 ml-20",
                    attrs: { allowClear: "", placeholder: "交易金额" },
                    model: {
                      value: _vm.curItem.goodPriceInfo.tradePrice,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem.goodPriceInfo, "tradePrice", $$v)
                      },
                      expression: "curItem.goodPriceInfo.tradePrice"
                    }
                  }),
                  _c("span", { staticClass: "ml-5" }, [_vm._v("元")])
                ],
                1
              ),
              _c("a-form-model-item", { attrs: { label: "上传图片：" } }, [
                _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                _c(
                  "div",
                  { staticClass: "flex-start ml-10" },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "a-upload",
                          {
                            key: _vm.imgKey,
                            attrs: {
                              action: _vm.ip + "/file/upload",
                              "list-type": "picture-card",
                              "file-list": _vm.coverFrontList
                            },
                            on: {
                              preview: _vm.handlePreview,
                              change: _vm.handleChangeCoverFrontList
                            }
                          },
                          [
                            _vm.coverFrontList.length < 1
                              ? _c(
                                  "div",
                                  [
                                    _c("a-icon", { attrs: { type: "plus" } }),
                                    _c(
                                      "div",
                                      { staticClass: "ant-upload-text" },
                                      [_vm._v("上传正面图")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-10" },
                      [
                        _c(
                          "a-upload",
                          {
                            key: _vm.imgKey,
                            attrs: {
                              action: _vm.ip + "/file/upload",
                              "list-type": "picture-card",
                              "file-list": _vm.coverBackList
                            },
                            on: {
                              preview: _vm.handlePreview,
                              change: _vm.handleChangeCoverBackList
                            }
                          },
                          [
                            _vm.coverBackList.length < 1
                              ? _c(
                                  "div",
                                  [
                                    _c("a-icon", { attrs: { type: "plus" } }),
                                    _c(
                                      "div",
                                      { staticClass: "ant-upload-text" },
                                      [_vm._v("上传反面图")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.coverBackList.length && _vm.coverFrontList.length
                      ? _c(
                          "a-button",
                          {
                            staticClass: "ml-20 mt-30",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleCutImgCover }
                          },
                          [_vm._v("裁剪预览替换")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "a-form-model-item",
                { attrs: { label: "图片说明：" } },
                [
                  _c("a-input", {
                    staticClass: "ml-10",
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      placeholder: "描述说明"
                    },
                    model: {
                      value: _vm.curItem.descRemark,
                      callback: function($$v) {
                        _vm.$set(_vm.curItem, "descRemark", $$v)
                      },
                      expression: "curItem.descRemark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("SetCoverPicPopup", {
        ref: "setCoverPicPopupEl",
        on: {
          success: function($event) {
            return _vm.handleCoverPicSuccess($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }