import {getBigCoinCategoryTypeListApi} from "@/axios/apis"

export default {
    data() {
        return {
            coinTypeList: []
        }
    },
    methods: {
        /** 获取分类列表 */
        async getCoinTypeList() {
            const res = await getBigCoinCategoryTypeListApi()
            const { records } = res.data;
            this.coinTypeList = records.map(el => {
                return {id: el.id, name: el.coinCategoryName}
            }) || [];
        },
    }
}