import coinManage from "@/route/routerMap/coinManage.js"
import dollarManager from "@/route/routerMap/dollarManager.js"
import dqplatform from "@/route/routerMap/dqplatform.js"
import versionManage from "@/route/routerMap/versionManage.js"
import shopManage from "@/route/routerMap/shopManage";
import productManage from "@/route/routerMap/productManage";
import checkManage from "@/route/routerMap/checkManage"
import appVersionManage from "@/route/routerMap/appVersionManage"
import coinBookManage from "@/route/routerMap/coinBookManage"
import priceQueryManage from "@/route/routerMap/priceQueryManage"
import dqSystem from "@/route/routerMap/dqSystem"
import currencyManage from "@/route/routerMap/currencyManage"
import contentDataManage from "@/route/routerMap/contentDataManage"
const cmsRouters = [
    ...contentDataManage,
    ...currencyManage,
    ...dqSystem,
    ...priceQueryManage,
    ...coinBookManage,
    ...appVersionManage,
    ...checkManage,
    ...coinManage,
    ...dollarManager,
    ...dqplatform,
    ...versionManage,
    ...shopManage,
    ...productManage,
    {
        path:'/cms/currencymannage/dqmannage/datamannage',name:'DataMannage',component: resolve => require(['@/views/cmsPage/dqMannage/dataMannage/index'], resolve)
    },
    {
        path:'/cms/dqmannage/subscribe',name:'subscribe',component: resolve => require(['@/views/cmsPage/dqMannage/subscribe/index'], resolve)
    },
    {
        path:'/cms/dqmannage/coingoods',name:'coingoods',component: resolve => require(['@/views/cmsPage/dqMannage/CoinGoods/index'], resolve)
    },
    {
        path:'/cms/dqmannage/coingoods2',name:'coingoods2',component: resolve => require(['@/views/cmsPage/dqMannage/CoinGoods/index2'], resolve)
    },
    {
        path:'/cms/dqmannage/hotword',name:'hotword',component: resolve => require(['@/views/cmsPage/dqMannage/hotword/index'], resolve)
    },
    {
        path:'/cms/dqmannage/textTemplate',name:'textTemplate',component: resolve => require(['@/views/textTemplate/index'], resolve)
    },
    {
        path:'/cms/dqplatform/workinghourse',name:'workingHourse',component: resolve => require(['@/views/cmsPage/dqPlatform/workingHourse'], resolve)
    },
    {
        path:'/cms/dqplatform/takeabout',name:'takeabout',component: resolve => require(['@/views/cmsPage/dqPlatform/takeabout'], resolve)
    },
    
    {
        path:'/cms/dqplatform/usermannage',name:'userMannage',component: resolve => require(['@/views/cmsPage/dqPlatform/userMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/usergraymannage',name:'userGrayMannage',component: resolve => require(['@/views/cmsPage/dqPlatform/userGrayMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/riskconfigmannage',name:'riskconfigmannage',component: resolve => require(['@/views/cmsPage/dqPlatform/riskconfigmannage'], resolve)
    },
    {
        path:'/cms/dqplatform/userwhitelistmannage',name:'whiteListMannage',component: resolve => require(['@/views/cmsPage/dqPlatform/userWhiteListMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/userdepositmannage',name:'depositMannage',component: resolve => require(['@/views/cmsPage/dqPlatform/userDepositMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/ordermannage',name:'orderMannage',component: resolve => require(['@/views/cmsPage/dqPlatform/orderMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/orderreturnmannage',name:'orderReturnMannage',component: resolve => require(['@/views/cmsPage/dqPlatform/orderReturnMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/wdcategory',name:'wdcategory',component: resolve => require(['@/views/cmsPage/dqPlatform/wdCategory'], resolve)
    },	
    {
        path:'/cms/dqplatform/publicarticle',name:'publicArticle',component: resolve => require(['@/views/cmsPage/dqPlatform/publicArticle'], resolve)
    },
    {
        path:'/cms/dqplatform/customcategoryrecommend',name:'customcategoryrecommend',component: resolve => require(['@/views/cmsPage/dqPlatform/customCategoryRecommend'], resolve)
    },
    {
        path:'/cms/dqplatform/blindactivity',name:'blindActivity',component: resolve => require(['@/views/cmsPage/dqPlatform/blindActivity'], resolve)
    },
    {
        path:'/cms/dqsys/account',name:'account',component: resolve => require(['@/views/cmsPage/dqSys/account'], resolve)
    },
    {
        path:'/cms/dqsys/role',name:'role',component: resolve => require(['@/views/cmsPage/dqSys/role'], resolve)
    },
    {
        path:'/cms/dqsys/menu',name:'menu',component: resolve => require(['@/views/cmsPage/dqSys/menu'], resolve)
    },
   {
        path:'/cms/dqplatform/withdrawalmannage',name:'withdrawalmannage',component: resolve => require(['@/views/cmsPage/dqPlatform/WithdrawalMannage'], resolve)
    },
    {
        path:'/cms/dqplatform/recommenduser',name:'recommenduser',component: resolve => require(['@/views/cmsPage/dqPlatform/recommendUsers'], resolve)
    },
    {
        path:'/cms/dqplatform/system',name:'System',component: resolve => require(['@/views/cmsPage/dqPlatform/systemMannage'], resolve)
    },
    // {
    //     path:'/cms/dqmanage/faq',name:'faq',component: resolve => require(['@/views/cmsPage/dqPlatform/faq/faqListManage'], resolve)
    // },
    {
        path:'/cms/dqmanage/coinfeature',name:'feature',component: resolve => require(['@/views/cmsPage/dqMannage/features/featureManage'], resolve)
    },
    {
        path:'/cms/dqmanage/gamequestion',name:'game',component: resolve => require(['@/views/cmsPage/dqMannage/games/questionManage'], resolve)
    },

    {
        path:'/cms/dqplatform/groupmessage',name:'GroupMessage',component: resolve => require(['@/views/cmsPage/dqPlatform/groupMessage'], resolve)
    },
    {
        path:'/cms/dqsys/serviceconfiguremenu',name:'serviceConfigureMenu',component: resolve => require(['@/views/cmsPage/dqSys/serviceConfigureMenu'], resolve)
    },
    {
        path:'/cms/dqplatform/productshelvesofferprice',name:'shelvesOfferPrice',component: resolve => require(['@/views/cmsPage/dqPlatform/productShelvesOfferPrice'], resolve)
    },
    
    {
        path:'/cms/dqmanage/bbs/category',name:'bbscategory',component: resolve => require(['@/views/cmsPage/dqMannage/bbs/categoryManage'], resolve)
    },
    {
        path:'/cms/dqmanage/bbs/article',name:'bbsarticle',component: resolve => require(['@/views/cmsPage/dqMannage/bbs/articleManage'], resolve)
    },
    {
        path:'/cms/dqmanage/knowledge/dynasty',name:'dynasty',component: resolve => require(['@/views/cmsPage/dqMannage/knowledge/dynasty'], resolve)
    },
    {
        path:'/cms/dqmanage/knowledge/itemManage',name:'itemManage',component: resolve => require(['@/views/cmsPage/dqMannage/knowledge/itemManage'], resolve)
    },
    {
        path:'/cms/dqmanage/bbs/post',name:'post',component: resolve => require(['@/views/cmsPage/dqMannage/bbs/postManage/index'], resolve)
    },
    
]

export default cmsRouters