var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          loading: _vm.loading,
          columns: _vm.columns,
          "data-source": _vm.list,
          scroll: { x: 800 },
          rowKey: function(record) {
            return record.spliderId
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "coinNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.coinName))]),
                _c("div", [_vm._v(_vm._s(row.createTime))])
              ])
            }
          },
          {
            key: "userNikiName",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "user-nike-name" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleShowNikeName(row.userName)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.userName))]
                  ),
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.handleShowNikeName(row.userId)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.userId))]
                  )
                ])
              ])
            }
          },
          {
            key: "paySlot",
            fn: function(row) {
              return _c(
                "div",
                {
                  style: {
                    color:
                      row.payStatus === 1
                        ? "green"
                        : row.payStatus === 0
                        ? "red"
                        : "orange"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(row.payMoney) +
                      "(" +
                      _vm._s(
                        row.payStatus == 1
                          ? "已支付"
                          : row.payStatus === 0
                          ? "未支付"
                          : "已退款"
                      ) +
                      ") "
                  )
                ]
              )
            }
          },
          {
            key: "confirmExtend",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.confirmExtend))]),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "10px" } },
                  [
                    row.confirmExtend
                      ? _c(
                          "a-button",
                          {
                            staticClass: "change-btn",
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleChangeExtend(row)
                              }
                            }
                          },
                          [_vm._v("修改老师描述")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "answerSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { style: { color: row.confirmUserName ? "green" : "" } },
                  [_vm._v(_vm._s(row.confirmUserName))]
                ),
                _c("div", { staticStyle: { color: "#001529" } }, [
                  _vm._v(_vm._s(row.confirmTime))
                ])
              ])
            }
          },
          {
            key: "coinSizeSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.diam || row.thickness
                  ? _c("div", { staticClass: "color-black font-weight-bold" }, [
                      _vm._v(_vm._s(row.diam + "mm*" + row.thickness + "mm"))
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "versionSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.customAnswerVersion
                  ? _c("span", [_vm._v(_vm._s(row.customAnswerVersion))])
                  : row.coinVersionId
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(row.coinItemName) +
                          "." +
                          _vm._s(row.coinVersionName)
                      )
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "statusSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.confirmStatus == 0
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("待回答")
                    ])
                  : _vm._e(),
                row.confirmStatus == 1
                  ? _c("span", { staticStyle: { color: "green" } }, [
                      _vm._v("已回答")
                    ])
                  : _vm._e(),
                row.confirmStatus == 10
                  ? _c("span", { staticStyle: { color: "blue" } }, [
                      _vm._v("已修正")
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "handleSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.operateStatus == -1
                  ? _c("span", { staticStyle: { color: "green" } }, [
                      _vm._v("搁置")
                    ])
                  : _vm._e(),
                row.operateStatus == 0
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("待处理")
                    ])
                  : _vm._e(),
                row.operateStatus == 10
                  ? _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("已进样本库")
                    ])
                  : _vm._e(),
                row.operateStatus == 20
                  ? _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v("已进底库")
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "image_1",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticClass: "image",
                  on: {
                    click: function($event) {
                      return _vm.previewImg(row, 1)
                    }
                  }
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "80px", "max-height": "80px" },
                    attrs: { src: row.frontImage }
                  })
                ]
              )
            }
          },
          {
            key: "image_2",
            fn: function(row) {
              return _c(
                "div",
                {
                  staticClass: "image",
                  on: {
                    click: function($event) {
                      return _vm.previewImg(row, 2)
                    }
                  }
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "80px", "max-height": "80px" },
                    attrs: { src: row.backImage }
                  })
                ]
              )
            }
          },
          {
            key: "setup",
            fn: function(row) {
              return _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "5px",
                      display: "flex",
                      "justify-content": "center",
                      "flex-direction": "column",
                      "align-items": "center"
                    }
                  },
                  [
                    row.confirmStatus === 1
                      ? _c(
                          "a-button",
                          {
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleChange(row)
                              }
                            }
                          },
                          [_vm._v("修改答案")]
                        )
                      : _vm._e(),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "是否确认?",
                          "ok-text": "Yes",
                          "cancel-text": "No",
                          disabled:
                            row.operateStatus == 20 || row.operateStatus == 10
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.confirmDuibanData(row.id, 10)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              disabled:
                                row.operateStatus == 20 ||
                                row.operateStatus == 10
                            }
                          },
                          [_vm._v("进样本库")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "是否确认?",
                          "ok-text": "Yes",
                          "cancel-text": "No",
                          disabled:
                            row.operateStatus == 20 || row.operateStatus == 10
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.confirmDuibanData(row.id, 20)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-top": "5px" },
                            attrs: {
                              size: "small",
                              type: "primary",
                              disabled:
                                row.operateStatus == 20 ||
                                row.operateStatus == 10
                            }
                          },
                          [_vm._v("确认进底库")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleGoToDuibanPage(row)
                          }
                        }
                      },
                      [_vm._v("机器对版结果")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          size: "small",
                          type: "danger",
                          disabled: row.payStatus !== 1
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleReturnPrice(row)
                          }
                        }
                      },
                      [_vm._v("退款")]
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("ModalPush", { ref: "pushModal", on: { success: _vm.pushSuccess } }),
      _c("ConfirmLog", { ref: "log" }),
      _c("ChangeData", { ref: "change", on: { success: _vm.editSuccess } }),
      _c("ChangeExtend", {
        ref: "changeExtendPopupEl",
        on: { change: _vm.pushSuccess }
      }),
      _c("ViewPicture", { ref: "showPicture" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }