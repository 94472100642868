var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          pagination: _vm.pagination,
          columns: _vm.columns,
          "data-source": _vm.list,
          scroll: { x: 800 },
          rowKey: function(record, n) {
            return n
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "authUserInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-black" }, [
                  _vm._v(" " + _vm._s(row.userName) + "----【"),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v(_vm._s(row.userId))
                  ]),
                  _vm._v("】 ")
                ]),
                _c(
                  "div",
                  { staticClass: "mt-10 font-weight-bold color-black" },
                  [
                    _vm._v(" 用户标版单价：【"),
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v(
                        _vm._s(
                          row.labelPrice == null
                            ? "暂未设置"
                            : "￥" + row.labelPrice
                        )
                      )
                    ]),
                    _vm._v("】 ")
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleVersionCountList(row)
                          }
                        }
                      },
                      [_vm._v("用户标版统计报表")]
                    )
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "authBigCoinSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.coinKindList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.coinKndId,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [_vm._v(_vm._s(item.coinKindName))]
                  )
                }),
                1
              )
            }
          },
          {
            key: "authActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowEditor(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定删除？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleDeleteAuth(row)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { size: "small", type: "danger" }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("ExpertOrNormalAdd", {
        ref: "expertOrNormalAddEl",
        on: { addOrEditorSuccess: _vm.handleAddOrEditorSuccess }
      }),
      _c("RatingAdd", {
        ref: "ratingAddEl",
        on: { addOrEditorSuccess: _vm.handleAddOrEditorSuccess }
      }),
      _c("VersionCountPopup", { ref: "versionCountPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }