<template>
  <a-drawer title="店铺指标" placement="right" :width="'60vw'" :closable="true" :visible="showPopup" @close="onClose">
    <div style="background-color: #F4F6F6;">
      <div>
        <a-radio-group v-model="params.dateType" @change="getData">
          <a-radio-button :value="1">本月</a-radio-button>
          <a-radio-button :value="2">上月</a-radio-button>
          <a-radio-button :value="3">前3个月</a-radio-button>
        </a-radio-group>
      </div>
      <div class="mt-20">
        <a-row :gutter="16">
          <a-col class="mb-20" :span="8" v-for="item in listData" :key="item.subType">
            <a-card :title="shopQuotaTypeMapTitleText[item.subType]" :bordered="false">
              <span
                  :class="computedRateMapClassType(item.subType, item.percentage)"
                  class="font-weight-bold font-size-32"
              >{{ computedRateDescText(item.subType, item.percentage) }}</span>
              <span v-if="item.subType !== 1" class="font-size-14">%</span>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-drawer>
</template>
<script>

import {getShopQuotaListApi} from "@/views/cmsPage/shopManage/shopInfoList/_apis"
import {shopQuotaTypeMapTitleText} from "@/views/cmsPage/shopManage/shopInfoList/_data"

export default {
  components: {},
  data() {
    return {
      shopQuotaTypeMapTitleText,
      showPopup: false,
      params: {
        userId: undefined,
        dateType: 1, // 1:本月 2:上月 3:前3个月
      },
      listData: []
    }
  },
  computed: {
    computedRateDescText() {
      return (type, num) => {
        if (type === 1) {
          if ((num * 1) === 0.8) return '落后80%商家'
          if ((num * 1) === 0.6) return '落后60%商家'
          if ((num * 1) === 0.4) return '领先40%商家'
          if ((num * 1) === 0.2) return '前20%商家'
          return '垫底'
        } else {
          return (num * 1) * 100
        }
      }
    },
    computedRateMapClassType() {
      return (type, num) => {
        // 1、成交排行 2、瑕疵退款率 3、售后纠纷率 4、精品率  5、0元流拍率 6、驳回
        if ([1, 2, 3, 5, 6].includes(type)) {
          if ((num * 1) >= 0.9) return 'color-red-zu-1'
          if ((num * 1) >= 0.8) return 'color-red-zu-3'
          if ((num * 1) >= 0.6) return 'color-red-zu-5'
          if ((num * 1) >= 0.4) return ( type === 1 ? 'color-blue' : 'color-red-zu-6')
          if ((num * 1) >= 0.2) return ( type === 1 ? 'color-dq-blue' : 'color-red-zu-6')
          if ((num * 1) >= 0.1) return 'color-lime-green'
          return type === 1 && (num * 1) === 0 ? 'color-red' : 'color-green'
        } else {
          if ((num * 1) >= 0.9) return 'color-1-blue'
          if ((num * 1) >= 0.8) return 'color-blue'
          if ((num * 1) >= 0.6) return 'color-yellow'
          if ((num * 1) >= 0.2) return 'color-red-zu-5'
          if ((num * 1) > 0.1) return 'color-red-zu-1'
          return 'color-red'
        }
      }
    }
  },
  methods: {
    async show(data) {
      this.params.userId = data.userId
      await this.getData()
      this.showPopup = true
    },
    /** 获取保证金占用明细数据 */
    async getData() {
      this.$loading.show()
      const res = await getShopQuotaListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.listData = res.data
    },
    onClose() {
      this.params = {
        userId: undefined,
        dateType: 1, // 1:本月 2:上月 3:前3个月
      }
      this.showPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>