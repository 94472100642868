
/** 获取快递单号 */
export const getLogisticsNoByString = (remarkText) => {
    // 使用正则表达式匹配 [] 之间的内容
    const regex = /\[(.*?)\]/;
    const match = remarkText.match(regex);

    if (match) {
        // match[1] 是捕获组中的内容，即 [] 之间的数据
        return {
            num: match[1],
            status: true,
        }
    } else {
        return {
            num: undefined,
            status: false,
        }
    }
}