<template>
  <div class="page">
    <div class="page-header">
      <a-input
        allowClear
        placeholder="ID"
        v-model="params.recordId"
        @pressEnter="search"
        size="small"
        class="header-item"
      />
      <a-select
        @search='searchUserData'
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.userId"
        placeholder="用户"
        @change="handleChangeUserSearchParams"
      >
        <a-select-option
          v-for="items of userList"
          :key="items.userId"
          :value="items.userId"
        >{{ items.userName }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          size="small"
          class="header-item"
          v-model="params.visibleStatus"
          placeholder="对版类型"
      >
        <a-select-option
            v-for="items of visibleStatusTypeList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <!-- <a-input
        allowClear
        placeholder="用户昵称或ID"
        v-model="params.userId"
        @pressEnter="search"
        size="small"
        class="header-item"
      /> -->
      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.manualMachineStatus"
        placeholder="人工Or机器"
      >
        <a-select-option
          v-for="items of manualMachineStatusList"
          :key="items.value"
          :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>

      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.platformType"
        placeholder="对版来源类型"
      >
        <a-select-option
          v-for="items of platformTypeList"
          :key="items.value"
          :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        :dropdownMenuStyle="{'max-height': '300px'}"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.handleStatus"
        placeholder="状态"
      >
        <a-select-option
          v-for="items of statusList"
          :key="items.value"
          :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        :dropdownMenuStyle="{'max-height': '500px'}"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.coinKindId"
        placeholder="请选择大币种"
        @change="coinChange"
        @search="getCoinFilteringList"
      >
        <a-select-option
          v-for="item of coinList"
          :key="item.coinKindId"
          :value="item.coinKindId"
        >{{ item.coinKindName + `【${item.coinKindId}】` }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        :dropdownMenuStyle="{'max-height': '500px'}"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.coinItemId"
        placeholder="请选择小币种"
        @change="coinItemChange"
      >
        <a-select-option
          v-for="items of coinItemList"
          :key="items.id "
          :value="items.id"
        >{{ items.coinKindItemName }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        :dropdownMenuStyle="{'max-height': '500px'}"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.coinVersionId"
        placeholder="请选择版别"

      >
        <a-select-option
          v-for="items of versionList"
          :key="items.id"
          :value="items.id"
        >{{ items.coinKindVersionName }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.sectionType"
        placeholder="推荐类型"
      >
        <a-select-option
          v-for="items of sectionTypeList"
          :key="items.value"
          :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.recognizerVersion"
        placeholder="模型版本"
        :dropdownStyle="{'max-height': '1000px'}"
      >
        <a-select-option
          v-for="items of modelVersionList"
          :key="items.value"
          :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>


      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.markTag"
        placeholder="标签筛选"
        :dropdownMenuStyle="{'max-height': '500px'}"
      >
        <a-select-option
          v-for="items of markTagList"
          :key="items.value"
          :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <a-checkbox-group v-model="isTest" @change="handleChangeCheckBox">
        <a-checkbox :value="1">测试集刷选</a-checkbox>
      </a-checkbox-group>

      <a-checkbox-group v-if="false" v-model="hasFeedback" @change="handleChangeCheckBoxByFeedback">
        <a-checkbox :value="1">反馈内容刷选</a-checkbox>
      </a-checkbox-group>

      <a-button
        @click="search"
        size="small"
        type="primary"
      >搜索</a-button>
      <a-button
        @click="batchProcessing()"
        size="small"
        type="primary"
        class="header-button"
        :disabled="isDisabled"
      >已处理</a-button>
    </div>
    <div class="page-body">
      <PageTable
        ref="pageTable"
        :changeTestCheckBox="changeTestCheckBox"
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @changePage="changePage"
        @success="handleChangeBigCoin"
        @selectChange="onSelectChange"
        @successVersion="handleResetVersionSuccess($event)"
        @versionSuccess="handleVersionSuccess($event)"
      ></PageTable>
    </div>
  </div>
</template>

<script>
import {
  manualMachineStatusList,
  markTagList,
  platformTypeList,
  recognizerVersionList,
  sectionTypeList,
  statusList,
  visibleStatusTypeList
} from "@/views/cmsPage/versionManage/duibanrecord/data.js";
import PageTable from "@/views/cmsPage/versionManage/duibanrecord/PageTable.vue";
import untils from "@/untils";
import {modelVersionList} from "@/views/cmsPage/versionManage/_data"
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      modelVersionList,
      untils,
      isTest: undefined,
      hasFeedback: undefined,
      selctedArrays: [],
      list: [],
      loading: false,
      detail: "",
      params: {
        pageNum: 1,
        pageSize: 20,
        createStartTime: undefined,
        createEndTime: undefined,
        pageType: undefined,
        startId: 0
      },
      pagination: {
        simple: true,
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      statusList: statusList,
      manualMachineStatusList: manualMachineStatusList,
      platformTypeList: platformTypeList,
      visibleStatusTypeList,
      sectionTypeList:sectionTypeList,
      recognizerVersionList:recognizerVersionList,
      markTagList:markTagList,
      userList: [],
      coinList: [],
      coinItemList: [],
      versionList: [],
      times: [],
      changeTestCheckBox: 0,
      maxId: 0,
      minId: 0,
      isLoading: false
    };
  },
  components: {
    PageTable
  },
  computed: {
    isDisabled() {
      return this.selctedArrays.length <= 0;
    },
  },
  async mounted() {
    const { userId, sameParagraphRecordId, recordId } = this.$route.query;
    this.$set(this.params, "recordId", recordId);
    this.$set(this.params, "userId", userId);
    this.$set(this.params, 'sameParagraphRecordId', sameParagraphRecordId)
    await this.getList();
    await this.getListStatusAbout()
    await this.getUserFilteringList();
  },
  methods: {
    /** 根据ID搜索对版列表 */
    async getListItemDataById(id, attr) {
      const tempParams = {
        recordId: id,
        pageSize: 1,
        pageNum: 1
      }
      const res = await this.axios("/dq_admin/duibanUserRecord/getDuibanUserRecordLog", {params: tempParams})
      this.list.forEach(el => {
        if (el.id === id) {
          for (let i = 0; i <= attr.length - 1; i++) {
            el[attr[i]] = res.data.records[0][attr[i]]
          }
        }
      })
    },
    /** 重新对版成功 */
    async handleResetVersionSuccess(id) {
      await this.getListItemDataById(id, ['versionList'])
      await this.getConfirmDataSizeByVersionId()
    },
    /** 原图对版-推送到底库 */
    async handleVersionSuccess(id) {
      await this.getListItemDataById(id, ['confirmVersionName', 'handleStatus'])
    },
    handleChangeCheckBox(e) {
      if (e.length < 1) {
        this.isTest = undefined
        delete this.params.testChoose
      }
      this.changeTestCheckBox = e.length < 1 ? 0 : 1
    },
    handleChangeCheckBoxByFeedback(e) {
      console.log(e, 'jlj ')
      if (e.length < 1) {
        this.hasFeedback = undefined
        delete this.params.hasFeedback
      }
    },
    handleChangeUserSearchParams() {
      this.search()
    },
    // 批量处理
    async batchProcessing() {
      let spliderIds = this.selctedArrays.map((row) => row.id);
      let ids = spliderIds.join(",");
      const res = await this.axios("/dq_admin/duibanUserRecord/batchLooked", {
        params: { recordIds: ids },
      });
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      // spliderIds.forEach(el=>{
      //   deleteItem(el.spliderId)
      // })
      await this.getList();
      await this.getListStatusAbout()
    },
    // 获取table选中数据
    onSelectChange(selctedArrays) {
      this.selctedArrays = JSON.parse(JSON.stringify(selctedArrays));
    },
    // 筛选用户列表
    async getUserFilteringList() {
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/getUserFilteringList"
      );
      if (res.status != 200) return;
      this.userList = res.data;
    },
    // 大币种列表
    async getCoinFilteringList(str) {
      const res = await getBigCoinList(str, [5])
      if (res.status !== '200') return;
      this.coinList = res.data.records.map(el => ({
        coinKindName: el.coinKindName,
        coinKindId: el.id,
        coinKindSid: el.sid
      }));
    },
    async coinChange(val) {
      if (!val) return;
      delete this.params.coinItemId;
      delete this.params.coinVersionId;
      const coin = this.coinList.find((el) => {
        return el.coinKindId === val;
      });
      this.params.coinSid = coin.coinKindSid;
      await this.coinItemSearch();
    },
    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.params.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    // 选择小币种
    async coinItemChange(val) {
      // await this.search();
      delete this.params.coinVersionId;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == val;
      });
      this.versionList = coinItem.coinItemVersionList;
    },
    // 分页
    async changePage(page) {
      const { current } = page;
      const currentPage = this.pagination.current
      const changeDown = currentPage + 1 === current
      const changeUp = currentPage - 1 === current
      if (currentPage + 1 === current || currentPage - 1 === current ) {
        if (changeDown) {
          this.params.startId = this.minId
          this.params.pageType = 'down'
        }
        if (changeUp) {
          this.params.startId = this.maxId
          this.params.pageType = 'up'
        }
        this.pagination.current = current;
        await this.getList();
        await this.getListStatusAbout()
      } else {
        this.$message.warn('请按照顺序切换页码')
      }
    },
    /** 修改大币种 */
    async handleChangeBigCoin() {
      await this.getList()
      await this.getListStatusAbout()
      await this.getUserFilteringList();
    },
    // 数据列表
    async getList() {
      if (this.isTest == 1) {
        this.params.testChoose = 1
      }
      if (this.hasFeedback == 1) {
        this.params.hasFeedback = 1
      }
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/getDuibanUserRecordLog",
        {
          params: this.params,
        }
      );
      this.loading = false;
      this.$loading.hide()
      this.isLoading = false
      if (res.status != 200) return;
      this.pagination.total = res.data.total;
      if (res.data.orders && res.data.orders.length) {
        this.maxId = res.data.orders[0].column
        this.minId = res.data.orders[1].column
      }
      this.list = res.data.records;
    },
    /** 获取列表状态 */
    async getListStatusAbout() {
      await this.getConfirmDataSizeByVersionId()
      await this.getVersionTagByVersionIds()
      await this.judgePushCoinMaintainStatus()
      await this.judgeAttentionGzhByUserIds()
    },
    /** 获取versionId字符串 */
    getVersionIdStr() {
      let versionIds = []
      this.list.forEach(el => {
        versionIds = versionIds.concat(el.versionList.map(item => item.versionId))
      })
      const str = versionIds.join(',')
      return str
    },
    /** 获取底库数量API */
    async getConfirmDataSizeByVersionId() {
      const versionIdStr = this.getVersionIdStr()
      const res = await this.axios('/dq_admin/duibanUserRecord/getConfirmDataSizeByVersionId',{
        params: {
          versionIds: versionIdStr
        }
      })
      if (res.status != 200) return
      const temp = JSON.parse(JSON.stringify(this.list))
      temp.forEach(el => {
        el.versionList.forEach(item => {
          item.sampleSize = res.data[item.versionId]
        })
      })
      this.list = temp
    },
    /** 获取底别名 */
    async getVersionTagByVersionIds() {
      const versionIdStr = this.getVersionIdStr()
      const res = await this.axios('/dq_admin/duibanUserRecord/getVersionTagByVersionIds',{
        params: {
          versionIds: versionIdStr
        }
      })
      if (res.status != 200) return
      const temp = JSON.parse(JSON.stringify(this.list))
      temp.forEach(el => {
        el.versionList.forEach(item => {
          item.tagNames = res.data[item.versionId]
        })
      })
      this.list = temp
    },
    /** 获取错误收集状态API */
    async judgePushCoinMaintainStatus() {
      const recordIds = this.list.map(el => el.id).join(',')
      const res = await this.axios('/dq_admin/duibanUserRecord/judgePushCoinMaintainStatus',{
        params: {
          recordIds: recordIds
        }
      })
      if (res.status != 200) return
      const temp = JSON.parse(JSON.stringify(this.list))
      temp.forEach(el => {
        el.coinTrainStatus = res.data[el.id]
      })
      this.list = temp
    },
    /** 关注公众号API */
    async judgeAttentionGzhByUserIds(){
      const userIds = this.list.map(el => el.userId).join(',')
      const res = await this.axios('/dq_admin/duibanUserRecord/judgeAttentionGzhByUserIds',{
        params: {
          userIds: userIds
        }
      })
      if (res.status != 200) return
      const temp = JSON.parse(JSON.stringify(this.list))
      temp.forEach(el => {
        el.attentionStatus = res.data[el.userId]
      })
      this.list = temp
    },
    // 搜索日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.createStartTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.createEndTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      } else {
        this.params.createStartTime = "";
        this.params.createEndTime = "";
      }
    },
    // 点击搜索按钮
    async search() {
      if (this.isLoading) return
      this.$loading.show()
      this.isLoading = true
      this.$set(this.params, 'sameParagraphRecordId', '')
      this.pagination.current = 1;
      this.params.startId = 0
      this.$set(this.params, "pageNum", 1);
      await this.getList();
      await this.getListStatusAbout()
    },
    searchUserData(inner){
            this.axios('/dq_admin/duibanUserRecord/getUserFilteringList',{params:{searchUserName:inner}}).then(res=>{
                if (res.status != 200) return;
                  this.userList = res.data;
            })
        },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  //display: flex;
  .header-item {
    margin-right: 5px;
    margin-bottom: 5px;
    width: 160px;
  }
}
.header-button {
  margin-left: 10px;
}
.btn-item {
  margin-left: 12px;
}
</style>