<template>
  <a-modal v-model="showPopup" :width='500' title="创建送评申请" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model ref="ruleForm" v-bind="layout">
      <a-form-item label="选择申请原因">
        <a-select
            allowClear
            v-model="params.returnsType"
            placeholder="选择申请原因"
        >
          <a-select-option
              v-for="item of applyRateServiceReasonTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  postChangeRateServiceVersionPriceApi,
  postCreateRateServiceApplyApi
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"
import {applyRateServiceReasonTypeList} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_data"

export default {
  emits: ['success'],
  data() {
    return {
      applyRateServiceReasonTypeList,
      showPopup: false,
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      params: {
        orderNo: undefined,
        returnsType: undefined,
      }
    }
  },
  methods: {
    show(orderNo) {
      this.params.orderNo = orderNo
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      this.$loading.show()
      const res = await postCreateRateServiceApplyApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('申请成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        price: undefined,
        remark: undefined,
      }
      this.showPopup = false
    }
  }
}
</script>