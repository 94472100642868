<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :rowKey="(record, index) => index"
    >
<!--   物流相关   -->
      <div slot="itemLogisticsCompanyAboutSlot" slot-scope="row">
        <div>
          <a-tooltip>
            <template slot="title">
              {{ computedRateServicePayTypeTextByDesc(row.orderRatingServiceRank) }}
            </template>
            <span :class="dqCheckPayClassType[row.orderRatingServiceRank]">{{ computedRateServicePayTypeText(row.orderRatingServiceRank) }}</span>
          </a-tooltip>
        </div>
        <div>{{ row.expressNo }}</div>
        <div v-if="row.ratingUserId">送评人：<span class="color-green">{{ `${row.ratingUserName || ''}【${row.ratingUserId}】` }}</span></div>
        <div v-if="row.orderRatingServiceRank === 'D'">D类送评数量：<span>{{row.coinAmount }}件</span></div>
        <div>{{ row.logisticsCompanyName }}</div>
      </div>
<!--   产品相关   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div>{{ (row.productName || '斗泉渠道代理') + (row.versionName ? row.versionName : '') }}</div>
      </div>
<!--   拍品图片列表   -->
      <div slot="itemAuctionImageSlot" slot-scope="row">
        <div class="img-list" v-if="row.productImageList">
          <img
              v-for="(item, index) in row.productImageList"
              :key="index"
              :src="computedNotShowImage(item)"
              alt=""
              @click="handlePreviewImg(row.productImageList, index)"
          >
        </div>
      </div>
<!--   质检图片   -->
      <div slot="itemCheckAuctionImageSlot" slot-scope="row">
        <div v-if="row.items">
          <a
            id="DTypeMoreImageId"
            href="#"
            @click="handleShowMoreCheckImages(row.items)"
          >查看更多质检图片</a></div>
        <div class="img-list" v-if="row.confirmReceiptImage">
          <img
              v-for="(item, index) in row.confirmReceiptImage"
              :key="index"
              :src="computedNotShowImage(item)"
              alt=""
              @click="handlePreviewImg(row.confirmReceiptImage, index)"
          >
        </div>
      </div>
<!--   订单   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <span v-if="row.orderRatingServiceRank === 'D'" class="color-blue">{{ row.orderNo }}</span>
        <span v-else @click="handleGoToOrder(row.orderNo)" class="cur-pot color-blue">{{ row.orderNo }}</span>
      </div>
<!--  显示买家是否需要    -->
      <div slot="itemBuyerNeedOrNotNeedSlot" slot-scope="row">
        <span v-if="row.orderRatingServiceRank === 'D'">-</span>
        <span
            v-else
            :class="dqCheckResultAfterByBuyerClassType[row.buyerSelectionAfterInitialReview]"
        >{{ computedResultAfterByBuyerTypeText(row.buyerSelectionAfterInitialReview) || '未选择' }}</span>
      </div>
<!--   质检状态   -->
      <div slot="itemCheckStatusSlot" slot-scope="row">
        <span :class="dqCheckStatusClassType[row.reviewStatus]">{{ computedStatusTypeText(row.reviewStatus) }}</span>
      </div>
<!--   是否标版   -->
      <div slot="itemIsVersionCheckSlot" slot-scope="row">
        <span :class="dqCheckVersionStatusClassType[row.duibanService]">{{ computedVersionStatusTypeText(row.duibanService) }}</span>
      </div>
<!--   结果类型   -->
      <div slot="itemCheckResultSlot" slot-scope="row">
        <span :class="dqCheckResultClassType[row.responsibilityDetermine]">{{ computedResultTypeText(row.responsibilityDetermine) }}</span>
      </div>
<!--   结果补充   -->
      <div slot="itemReviewRemarksSlot" slot-scope="row">
        <a-tooltip>
          <template slot="title">
            {{ row.reviewRemarks }}
          </template>
          <span class="color-blue cur-pot">{{ row.reviewRemarks.slice(0, 11) + '...' }}</span>
        </a-tooltip>
      </div>
<!--   操作   :disabled="row.reviewStatus > -1"-->
      <div slot="itemActionSlot" slot-scope="row">
        <div class="button-list">
          <button
              id="showPushButtonId"
              class="bg-blue"
              @click="handleShowPush(row)"
          >质检审核</button>
          <button
              id="showPrintButtonId"
              class="bg-cadetblue"
              style="margin-top: 10px"
              @click="handleShowPrintCode(row)"
          >打印条码</button>
        </div>
      </div>
    </a-table>
    <PushResultPopup ref="pushResultPopupEl" @success="handleChangeSuccess" @closeModal="handleCloseModal"/>
    <DPushResultPopup ref="DPushResultPopupEl" @success="handleChangeSuccess" @closeModal="handleCloseModal"/>
    <PrintCodePopup ref="printCodePopupEl" @closeModal="handleCloseModal"/>
    <ShowMoreImages ref="showMoreImagesEl" @closeModal="handleCloseModal"/>
  </div>
</template>
<script>
import ShowMoreImages from "@/views/cmsPage/checkManage/operate/_components/ShowMoreImages"
import { dqCheckResultTableColumns} from "@/views/cmsPage/checkManage/operate/_data"
import PushResultPopup from "@/views/cmsPage/checkManage/operate/_components/PushResultPopup"
import {
  computedResultAfterByBuyerType,
  computedResultType,
  computedStatusType, computedVersionStatusType, dqCheckPayClassType, dqCheckResultAfterByBuyerClassType,
  dqCheckResultClassType, dqCheckStatusClassType,
  dqCheckStatusTypeList, dqCheckVersionStatusClassType, rateServicePayTypeMapText, rateServicePayTypeMapTextByDesc
} from "@/views/cmsPage/checkManage/_data"
import PrintCodePopup from "@/views/cmsPage/checkManage/operate/_components/PrintCodePopup"
import {addStringIfContains} from "@/untils/otherEvent"
import DPushResultPopup from "@/views/cmsPage/checkManage/operate/_components/DPushResultPopup"

export default {
  props: ['list'],
  components: {PushResultPopup, PrintCodePopup, DPushResultPopup, ShowMoreImages},
  data() {
    return {
      tableList: dqCheckResultTableColumns,
      dqCheckResultClassType,
      dqCheckStatusTypeList,
      dqCheckStatusClassType,
      dqCheckVersionStatusClassType,
      dqCheckPayClassType,
      dqCheckResultAfterByBuyerClassType
    }
  },
  computed: {
    computedNotShowImage() {
      return (value) => addStringIfContains(value, 'x-oss', '?x-oss-process=image/resize,p_100/format,jpg')
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedResultTypeText() {
      return (value) => computedResultType(value)
    },
    computedStatusTypeText() {
      return (value) => computedStatusType(value)
    },
    computedVersionStatusTypeText() {
      return (value) => computedVersionStatusType(value)
    },
    computedResultAfterByBuyerTypeText() {
      return (value) => computedResultAfterByBuyerType(value)
    },
  },
  methods: {
    /** 关闭弹窗触发事件 */
    handleCloseModal() {
      this.$emit('closeModal')
      // 关闭弹窗后，将按钮的焦点移除
      document.getElementById('showPrintButtonId').blur();
      document.getElementById('showPushButtonId').blur();
      // document.getElementById('DTypeMoreImageId').blur();
    },
    /** 查看更多拍品图片 */
    handleShowMoreCheckImages(images) {
      // document.getElementById('DTypeMoreImageId').blur();
      this.$refs.showMoreImagesEl.showPopup(images)
    },
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      // const origin = location.origin
      // const url = `${origin}/#/cms/dqplatform/newordermannage?orderNo=${orderNo}`;
      // window.open(url)
    },
    /** 打印条码 */
    async handleShowPrintCode(row) {
      document.getElementById('showPrintButtonId').blur();
      let url = ''
      if (row.orderRatingServiceRank === 'D') {
        url = '/dq_admin/qualityzhandian/listDBarCode'
      } else {
        url = '/dq_admin/qualityzhandian/generateBarCode'
      }
      const res = await this.axios(url, {params: {ratingId: row.id}})
      if (res.status !== '200') return
      let codeData
      if (row.orderRatingServiceRank === 'D') {
        codeData = res.data
      } else {
        codeData = [res.data]
      }
      await this.$refs.printCodePopupEl.showPopup(codeData)
    },
    /** 录入 */
    handleShowPush(row) {
      document.getElementById('showPushButtonId').blur();
      if (row.reviewStatus === -1) row.reviewStatus = undefined
      if (row.orderRatingServiceRank === 'D') {
        this.$refs.DPushResultPopupEl.showPopup(row)
      } else {
        this.$refs.pushResultPopupEl.showPopup(row)
      }
    },
    handleChangeSuccess(expressNo) {
      this.$emit('success', expressNo)
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: this.computedNotShowImage(el)}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.button-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  button {
    padding: 5px 20px;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
  }
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.color-green {
  color: green;
}
</style>