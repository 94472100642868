<template>
  <div class="content">
    <div class="top">
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          @search='searchData'
          class="m-5 w-200"
          v-model="params.coinId"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="items of bigCoinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName + `【${items.id}】` }}</a-select-option>
      </a-select>
<!--   类型筛选   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          class="header-item"
          v-model="params.type"
          placeholder="选择类型"
          style="margin: 5px; width: 240px;"
      >
        <a-select-option
            v-for="items of taskTypeList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>


<!--   模型版本筛选   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          :filter-option="untils.filterOption"
          showSearch
          class="header-item"
          v-model="params.modelVersion"
          placeholder="模型版本"
          style="margin: 5px; width: 180px;"
          :dropdownStyle="{'max-height': '1000px'}"
      >
        <a-select-option
            v-for="items of modelVersionList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>

<!--   标签筛选   -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          class="header-item"
          v-model="params.tag"
          placeholder="标签筛选"
          style="margin: 5px; width: 180px;"
      >
        <a-select-option
            v-for="items of taskTagList"
            :key="items.value"
            :value="items.value"
        >{{ items.name }}</a-select-option>
      </a-select>
      <a-button
          @click="search"
          type="primary"
          style="margin: 5px;"
      >添加任务</a-button>
    </div>
    <div class="table-content">
      <a-alert message="操作提示:后台会每次按照id从大到小添加到任务列表2000条,添加时保持相同条件的筛选直到添加完之后在切换筛选条件" type="error" />
      <TaskList
        :list="list"
        :list-v2="listV2"
        :show="isShowOtherParams"
        @resetData="handleResetData"
      />
    </div>
  </div>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import TaskList from "@/views/cmsPage/versionManage/duibanTask/_components/TaskList";
import {taskTagList, taskTypeList} from "@/views/cmsPage/versionManage/duibanTask/_data";
import untils from "@/untils";
import {modelVersionList} from "@/views/cmsPage/versionManage/_data"
import {getBigCoinList} from "@/axios/apis"

export default {
  components: {
    TaskList
  },
  data() {
    return {
      untils,
      locale,
      taskTypeList: taskTypeList,
      taskTagList: taskTagList,
      modelVersionList: modelVersionList,
      list: [],
      listV2: [],
      params: {
          // ?coinId=xx?type=xx&tag=xx&duibanMonth=x
        coinId: undefined,
        type: undefined,
        tag: undefined,
        modelVersion: undefined
      },
      bigCoinList: [],
      isShowOtherParams: true,
    }
  },
  async created() {
    await this.getTaskList()
    await this.getTaskListV2()
  },
  methods: {
    /** 刷新数据 */
    handleResetData(type) {
      if (type === 'version') {
        this.getTaskList()
      } else {
        this.getTaskListV2()
      }
    },
    /** 获取大币种列表 */
    async searchData(inner){
      if (!inner) return
      const res = await getBigCoinList(inner, [5])
      if (res.status !== '200') return;
      this.bigCoinList = res.data.records
    },
    /** 获取推送任务列表 */
    async getTaskList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanTask/taskData");
      this.$loading.hide()
      if (res.status != 200) return;
      this.list = res.data;
    },
    /** 获取推送任务列表v2 */
    async getTaskListV2() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanTask/resetCoinTaskData");
      this.$loading.hide()
      if (res.status != 200) return;
      this.listV2 = res.data;
    },
    /** 推送数据 */
    async handlePushData() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/duibanTask/filterData", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.$message.info('推送成功')
      await this.getTaskList()
      await this.getTaskListV2()
    },
    /** 搜索 */
    search() {
      this.handlePushData();
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}
.list-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
