var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "flex-sp-bt-center" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _c(
              "a-select",
              {
                staticClass: "w-300 ml-5",
                attrs: {
                  dropdownMatchSelectWidth: false,
                  "filter-option": _vm.untils.filterOption,
                  allowClear: "",
                  showSearch: "",
                  placeholder: "请选择大币种"
                },
                on: { search: _vm.coinSearch },
                model: {
                  value: _vm.params.coinId,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "coinId", $$v)
                  },
                  expression: "params.coinId"
                }
              },
              _vm._l(_vm.coinList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [
                    _vm._v(
                      _vm._s(
                        item.coinKindName +
                          "【" +
                          item.id +
                          "】" +
                          (item.coinCategory ? "---" + item.coinCategory : "")
                      )
                    )
                  ]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticClass: "mb-5",
                attrs: { type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            ),
            _c(
              "a-button",
              {
                staticClass: "ml-20 mb-5",
                attrs: { type: "primary" },
                on: { click: _vm.handleAdd }
              },
              [_vm._v("添加币种模型版本")]
            ),
            _c(
              "a-popconfirm",
              {
                attrs: { title: "确定同步测试集？" },
                on: { confirm: _vm.handleSyncVersionTest }
              },
              [
                _c(
                  "a-button",
                  { staticClass: "ml-20 mb-5", attrs: { type: "danger" } },
                  [_vm._v("同步测试集")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.data, pagination: _vm.pagination },
            on: { changePage: _vm.handleChangePage }
          })
        ],
        1
      ),
      _c("AddCoinModelVersionPopup", {
        ref: "addCoinModelVersionPopupEl",
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }