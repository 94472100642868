import {getCoinStatusListApi} from "@/views/cmsPage/productManage/_apis"

/** 获取钱币状态列表 */
export const getCoinStatusListData = async () => {
    const res = await getCoinStatusListApi()
    if (res.status !== '200') return
    let tempTagList = []
    res.data.forEach((el, index) => {
        tempTagList.push({ name: el, value: index + 1, type: 'text' })
    })
    return tempTagList
}