<template>
  <!-- 推送样本库 -->
  <a-modal
    v-model="pushModal"
    :width='800'
    title="确认到样本库"
  >
    <template slot="footer">
      <div style="display: flex;justify-content: flex-end;align-items: center">
        <a-button @click="cancelPushModal">取消</a-button>
        <a-button type="primary" @click="confirmPushModal('sampleToWaiting')">确认到困难样本库</a-button>
        <a-button type="primary" @click="confirmPushModal">确认到样本库</a-button>
      </div>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin($event, 'change')"
          @search="coinSearch($event, '', [5])"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName + `【${item.id}】` }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 280px"
          v-model="params.versionId"
          @change="changeItemVersion($event)"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.coinVersionId"
              :value="item.coinVersionId"
          >{{ `${item.coinVersionName}【${item.size}】【${item.level}】` }}</a-select-option>
        </a-select>
        <a-checkbox class="checked" v-model:checked="isTest">是否作为测试集</a-checkbox>
      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils";
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import {updateBaseObjectWithComplex} from "@/untils/otherEvent"
export default {
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      pushModal: false,
      params: {
        id: undefined,
        coinKindName: undefined,
        coinKindId: undefined,
        coinKindItemId: undefined,
        versionId: undefined,
        coinKindItemVersionName: undefined,
      },
      cutItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      isTest: false
    };
  },
  methods: {
    async show(data, changeTestCheckBox) {
      this.cutItem = data
      this.params = updateBaseObjectWithComplex(this.params, data)
      this.isTest = !!changeTestCheckBox
      this.$loading.show()
      await this.setDefault();
      this.$loading.hide()
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      if (this.cutItem.versionList && this.cutItem.versionList[0]) {
        this.params.coinKindItemId = this.cutItem.versionList[0].itemId
        this.params.versionId = this.cutItem.versionList[0].versionId
        this.params.coinKindItemVersionName = this.cutItem.versionList[0].versionName
      }
      await this.coinSearch(this.params.coinKindName, 'setDefault', [5])
    },
    // 关闭
    cancelPushModal() {
      this.params = {
        id: undefined,
        coinKindId: undefined,
        coinKindItemId: undefined,
        versionId: undefined,
        coinKindItemVersionName: undefined,
      }
      this.pushModal = false;
    },
    /** 确认到样本库 */
    async confirmPushModal(type) {
      const { id, coinKindId, coinKindItemId, versionId } = this.params;
      this.$loading.show();
      const params = {
        recordId: id,
        coinId: coinKindId,
        itemId: coinKindItemId,
        versionId,
        type: 5,
        isTest: this.isTest ? 1 : 0
      }
      if (type === 'sampleToWaiting') {
        params.operType = 'sampleToWaiting'
      }
      const res = await this.axios(
        "/dq_admin/duibanUserRecord/confirmToEnterTheSampleLibrary",
        {
          params: {...params},
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.isTest = false
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success", {
        id: id,
        coinKindItemVersionName: this.params.coinKindItemVersionName
            ? this.params.coinKindItemVersionName.split(' ')[1]
            : ''
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checked {
  margin-top: 10px;
}
</style>