var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemActionTagSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: row.markStatus ? "确定取消标记？" : "确定标记？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleConfirmActionOver(
                            row.id,
                            row.markStatus
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "a-checkbox",
                        {
                          class: row.markStatus ? "color-green" : "color-black",
                          attrs: { checked: !!row.markStatus }
                        },
                        [_vm._v("标记")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v("【" + _vm._s(row.id) + "】")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold mt-10",
                    class: _vm.orderTypeClassType[row.orderKind]
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.computedOrderTypeListMapText(row.orderKind))
                    )
                  ]
                ),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("【"),
                  _c(
                    "span",
                    {
                      staticClass: "color-blue cur-pot",
                      on: {
                        click: function($event) {
                          return _vm.handleGoToOrderList(row)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.orderNo))]
                  ),
                  _vm._v("】")
                ]),
                row.remarkNewest.id
                  ? _c("div", { staticClass: "mt-5" }, [
                      _vm._v("客服备注："),
                      _c(
                        "span",
                        { staticClass: "color-black font-weight-bold" },
                        [_vm._v(_vm._s(row.remarkNewest.content))]
                      )
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemProductNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.productReviewStatus
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.orderProductLevelClassType[
                            row.productReviewStatus
                          ]
                      },
                      [
                        _vm._v(
                          "【" +
                            _vm._s(
                              _vm.computedOrderProductLevelMapText(
                                row.productReviewStatus
                              )
                            ) +
                            "】"
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "mt-10 font-weight-bold color-black cur-pot",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToProductList(row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.productName))]
                )
              ])
            }
          },
          {
            key: "itemProductImgListSlot",
            fn: function(row) {
              return _c(
                "div",
                { staticClass: "flex-start-center flex-wrap" },
                _vm._l(row.coverImages, function(item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "lazy-container",
                          rawName: "v-lazy-container",
                          value: { selector: "img" },
                          expression: "{ selector: 'img' }"
                        }
                      ],
                      key: index
                    },
                    [
                      _c("img", {
                        staticClass: "w-50 h-50",
                        attrs: { "data-src": item },
                        on: {
                          click: function($event) {
                            return _vm.handleShowBigImg(row.coverImages, index)
                          }
                        }
                      })
                    ]
                  )
                }),
                0
              )
            }
          },
          {
            key: "itemOrderPriceAndPayTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-red" }, [
                  _vm._v("￥" + _vm._s(row.returnsAmount))
                ])
              ])
            }
          },
          {
            key: "itemOrderStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v("订单状态【"),
                  _c(
                    "span",
                    {
                      class: _vm.orderStatusTypeListClassType[row.orderStatus]
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedOrderStatusTypeListMapText(
                            row.orderStatus
                          ) || "未知"
                        )
                      )
                    ]
                  ),
                  _vm._v("】")
                ]),
                false
                  ? _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                      _vm._v("退单状态【"),
                      _c(
                        "span",
                        {
                          class:
                            _vm.orderReturnPriceStatusClassType[
                              row.refundStatus
                            ]
                        },
                        [_vm._v(_vm._s(row.refundStatusName || "暂无"))]
                      ),
                      _vm._v("】")
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("退款状态【"),
                  _c(
                    "span",
                    {
                      class:
                        _vm.orderReturnPriceStatusClassType[
                          row.returnsReviewStatus
                        ]
                    },
                    [_vm._v(_vm._s(row.returnsReviewStatusName || "暂无"))]
                  ),
                  _vm._v("】")
                ]),
                _c(
                  "div",
                  { staticClass: "mt-20" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowOrderDetail(row)
                          }
                        }
                      },
                      [_vm._v("订单详情")]
                    )
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemBuyerInfoOrSellerInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v("买家信息【"),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v(_vm._s(row.buyerUserName + "---" + row.buyerUserId))
                  ]),
                  _vm._v("】")
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold" }, [
                  _vm._v("卖家信息【"),
                  _c("span", { staticClass: "color-blue" }, [
                    _vm._v(
                      _vm._s(row.sellerUserName + "---" + row.sellerUserId)
                    )
                  ]),
                  _vm._v("】")
                ]),
                row.groupId
                  ? _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.handleShowUserChatList(row)
                              }
                            }
                          },
                          [_vm._v("查看聊天记录")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemServiceJoinStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold",
                    class:
                      _vm.serviceJoinTypeClassType[row.isServiceInterveneDesc]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedServiceJoinTypeListMapText(
                          row.isServiceInterveneDesc
                        )
                      )
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "a-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleShowVoucherList(row)
                          }
                        }
                      },
                      [_vm._v("查看举证/留言列表")]
                    )
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {})
            }
          }
        ])
      }),
      _c("UserChatListModel", { ref: "userChatListModelEl" }),
      _c("ReturnOrderUserVoucherListPopup", {
        ref: "returnOrderUserVoucherListPopupEl"
      }),
      _c("ReturnOrderDetailPopup", {
        ref: "returnOrderDetailPopupEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }