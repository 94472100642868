<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
          allowClear
          v-model="params.orderNo"
          size='small'
          style="width:180px;"
          placeholder='订单号'
        ></a-input>
        <a-input
            allowClear
            v-model="params.expressNo"
            size='small'
            style="width:130px;margin: 5px;"
            placeholder='物流单号'
        ></a-input>
        <a-input
          allowClear
          v-model="params.returnsNo"
          size='small'
          style="width:180px;"
          placeholder='退款编号'
        ></a-input>

        <a-input
            allowClear
            v-model="params.buyerUserName"
            size='small'
            style="margin-left: 10px;width: 155px;"
            placeholder='买家/uId/昵称/店铺'
        ></a-input>
        <a-input
            allowClear
            v-model="params.sellerUserName"
            size='small'
            style="margin-left: 10px;width: 155px;"
            placeholder='卖家/uId/昵称/店铺'
        ></a-input>
        <a-select
          allowClear
          v-model="params.status"
          size='small'
          style="width:150px;margin-left:10px;"
          placeholder='订单状态'
        >
          <a-select-option
            v-for="(item,key) of orderState"
            :value='item.value'
            :key="key"
          >{{item.label}}</a-select-option>
        </a-select>
        <a-select
          allowClear
          v-model="params.returnsReviewStatus"
          size='small'
          style="width:150px;margin-left:10px;"
          placeholder='退款状态'
        >
          <a-select-option
            v-for="(item,key) of resetOrderState"
            :value='item.value'
            :key="key"
          >{{item.label}}</a-select-option>
        </a-select>

        <a-button
          @click="search"
          size='small'
          style="margin-left:10px;"
        >搜索</a-button>
      </div>
    </div>
    <div class="table-content">
      <a-table
        @change="changePage"
        :pagination='pagination'
        :loading='loading'
        :columns="columns"
        :data-source="data"
        :rowKey="(record,n)=>n"
      >
        <div
          slot="orderNoSlot"
          slot-scope="row"
        >
          <span class="order-text" @click="handleGoToOrderListPage(row)">{{ row.orderNo }}</span>
        </div>

        <div
          slot="buyerUserNameSlot"
          slot-scope="row"
        >
          <div>{{ row.buyerUserName }}</div>
          <div style="margin-top: 10px;">
            <a-button
              size="small"
              @click="handleShowChatList(row)"
            >查看聊天记录</a-button>
          </div>
        </div>

        <div
          slot="orderKindSlot"
          slot-scope="row"
        >
          <div v-if="row.orderKind == 0">竞拍单</div>
          <div v-if="row.orderKind == 1">一口价</div>
        </div>

        <div
          slot="snapshotSlot"
          slot-scope="row"
        >
          <div  class="product-img-list">
             <span class="product-img" v-for="(item, index) in row.coverImages" :key="item.id">
            <img :src="item" alt="" @click="handlePreViewImg(row, index)">
          </span>
          </div>
        </div>
        <div
          slot="setup"
          slot-scope="row"
        >
          <a @click="openModal(row)">查看详情</a>
          <a
            @click="openModalSnapshot(row.orderId)"
            style='margin-left:8px'
          >快照</a>
        </div>

      </a-table>
    </div>

    <a-modal
      v-model="show"
      :width='1200'
      centered
      :footer='null'
    >
      <div :style="'max-height:'+ModalHeight+'px;overflow:auto;'">
        <div class="modal-items">
          <div class="title">基本信息</div>
          <div class="modal-content">
            <div class="basic-item">订单编号：<span class="color-blue">{{orderProductInfoVo.orderNo}}</span></div>
            <div class="basic-item">买家：<span class="color-blue">{{orderProductInfoVo.buyerUserName}}</span></div>
            <div class="basic-item">卖家：<span class="color-blue">{{orderProductInfoVo.sellerUserName}}</span></div>
            <div class="basic-item">商户订单号：{{orderProductInfoVo.requestNo||''}}</div>
            <div class="basic-item">下单/截拍时间：{{orderProductInfoVo.createTime||''}}</div>
          </div>

          <div class="modal-content">
            <div class="basic-item">订单类型：<span style="color:red">{{ orderProductInfoVo.orderKind == 0 ? '竞拍单' : '一口价' }}</span></div>
          </div>

          <div class="modal-content">
            <div class="basic-item">订单原价/中拍价：<span style="color:red">{{orderProductInfoVo.orderPrice}}元</span></div>
            <div class="basic-item" v-if="orderProductInfoVo.status !== -1">买家手续费：<span style="color:red">{{ orderProductInfoVo.buyerServiceMoney}}元</span></div>
            <div class="basic-item" v-if="orderProductInfoVo.status !== -1">卖方手续费：<span style="color:red">{{orderProductInfoVo.sellerServiceMoney}}元</span></div>
            <div class="basic-item">发货运费：<span style="color:red">{{orderProductInfoVo.logisticsFee}}元</span></div>
            <div class="basic-item">冻结保证金：<span style="color:red">{{ orderProductInfoVo.deductDepositMoney || 0 }}元</span>（<span class="color-red">{{ orderProductInfoVo.depositReleaseDesc }}</span>）</div>
            <div class="basic-item">订单总金额：<span style="color:red"><span>{{ orderProductInfoVo.orderTotalPrice || 0 }}元</span></span></div>
          </div>

          <div class="dis-flex-start-start mt-20">
            <div class="modal-items" v-if="orderProductInfoVo.payTime">
              <div class="title">支付信息</div>
              <div class="modal-content modal-express">
                <div class="basic-item">支付时间：
                  <span class="color-red">{{orderProductInfoVo.payTime|| '未支付'}}</span>
                  <span v-if="orderProductInfoVo.status === 0">（倒计时<span class="color-red">{{ 'xxxx' }}</span>）</span>
                </div>
                <div class="basic-item">货币支付：<span class="color-red">{{orderProductInfoVo.payMoney||''}}</span>元</div>
                <div class="basic-item">
                  优惠支付：<span
                    class="color-red"
                    v-if="orderProductInfoVo.deductPaymentMoney"
                >{{orderProductInfoVo.deductPaymentMoney + '元'}}</span> <span class="color-red"  v-else>无</span>，
                  优惠方式: <span class="color-red">{{orderProductInfoVo.deductPaymentDesc || '无'}}</span>
                </div>
                <div v-if="[1, 3, 4].includes(orderProductInfoVo.status) && false" class="dis-flex-start" >
                  <a-popconfirm
                      title="确认主动退款么?"
                      ok-text="Yes"
                      cancel-text="No"
                      okType="danger"
                      @confirm="handleReturnAuctionOrder(orderReturnVo.id)"
                  >
                    <a-button
                        type='danger'
                    >主动退款</a-button>
                  </a-popconfirm>
                  <span class="color-red ml-20">（一键退款，本功能无需买家退货，谨慎操作）</span>
                </div>
                <div v-if="orderProductInfoVo.orderKind === 1" class="dis-flex-start mt-20">
                    <a-popconfirm
                        title="确认修改退款金额么？"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="modifyRefundMoneyFunction(orderReturnVo.id,orderReturnVo.returnsReviewStatus)"
                    >
                      <a-button
                          type='primary'
                      >修改退款金额</a-button>
                    </a-popconfirm>
                    <a-input
                        v-model="modifyRefundMoney"
                        size='small'
                        style="width: 120px;margin-left: 10px;height: 30px;"
                        placeholder='输入退款金额'
                    ></a-input>
                    <a-textarea
                        :rows="2"
                        style="width: 120px;margin-left: 10px;height: 30px;"
                        v-model="modifyRefundMark"
                        placeholder='备注原因'
                    />
                </div>
              </div>
            </div>
            <div class="right-pay-message">

            </div>
          </div>
        </div>

        <div class="modal-items mt-20">
          <div class="title">订单状态</div>
          <div class="modal-content modal-express">
            <div class="dis-flex-start-start">
              <div class="left-content">
                <div class="basic-item">订单主状态：
                  <span class="color-red">{{orderProductInfoVo.statusName}}</span>
                  <span
                      v-if="orderProductInfoVo.orderRemainTime"
                  >
                    （<span class="color-red">{{orderProductInfoVo.orderRemainTime}}</span>）
                  </span>
                </div>
              </div>
              <div class="right-content ml-200">
                <div class="basic-item" v-if="orderProductInfoVo.refundStatusName">
                  <span>逆向子状态：<span class="color-red">{{orderProductInfoVo.refundStatusName}}</span></span>
                  <span v-if="orderProductInfoVo.orderReturnRemainTime">（{{orderProductInfoVo.orderReturnRemainTime}}）</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dis-flex-start-start mt-20" v-if="orderProductInfoVo.orderKind == 0">
          <div class="modal-items" style="width: 40%">
            <div class="title">买家侧显示赔付信息</div>
            <div class="modal-content modal-express">
              <div class="express" v-for="(item,index) in buyerCompensationInformation" :key="index">
                <!-- index 为Key-->
                <span class="col-sm-2 col-form-label">{{index}}</span>
                <span class="col-sm-2 col-form-label color-red ml-20">{{item}}</span>
              </div>
            </div>
          </div>

          <div class="modal-items"  style="width: 40%">
            <div class="title">卖家侧显示赔付信息</div>
            <div class="modal-content modal-express">
              <div class="express" v-for="(item,index) in sellerCompensationInformation" :key="index">
                <!-- index 为Key-->
                <span class="col-sm-2 col-form-label">{{index}}</span>
                <span class="col-sm-2 col-form-label color-red ml-20">{{item}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="dis-flex-start-start mt-20">
          <div class="modal-items" style="width: 40%">
            <div class="title">送评物流/凭证</div>
            <div class="modal-content modal-express">
              <div class="express">
                物流公司：<span class="color-red">{{orderGradeLogisticsVo.logisticsName}}</span>
              </div>
              <div class="express" @click="untils.copy_text(orderGradeLogisticsVo.expressNo)">
                物流单号：<span class="color-red">{{orderGradeLogisticsVo.expressNo}}</span>
              </div>
              <div class="express">
                <span>评级图片：</span>
                <img
                    @click="previewImg(orderReturnVo.gradeFile)"
                    v-for='(item,key) of orderReturnVo.gradeFile'
                    :key="key"
                    style="max-width:50px;max-height:50px;margin-right:5px;"
                    :src='item'
                >
              </div>
            </div>
          </div>

          <div
              class="modal-items"
              style="width: 40%"
              v-if="showExpress"
          >
            <div class="title" style="width: 100%;">退款/退货
              <span style="margin-left: 20px;">
                <span style="color: red">买家申请：{{orderReturnVo.receiptType === 'goodsreceived' ? '退货退款' : '仅退款'}}</span>
              </span></div>
            <div class="modal-content modal-express">
              <div v-if="orderProductInfoVo.orderKind == 0 && orderReturnVo.returnsReason" style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
                <a-popconfirm
                    title="确定是否扣除保证金？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleDeductBondPrice(orderReturnVo.id, 1)"
                >
                  <a-button type='primary'>是否扣除保证金</a-button>
                </a-popconfirm>
                <a-radio-group class="ml-20" name="radioGroup" :default-value="-1" @change="handleChangeRadioBondPrice">
                  <a-radio :value="1">扣除</a-radio>
                  <a-radio :value="0">不扣除</a-radio>
                </a-radio-group>
              </div>
<!--     是否扣除手续费         -->
              <div v-if="orderProductInfoVo.orderKind == 0 && orderReturnVo.returnsReason" style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
                <a-popconfirm
                    title="确定是否扣手续费？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleDeductBondPrice(orderReturnVo.id, 2)"
                >
                  <a-button type='danger'>是否扣除手续费</a-button>
                </a-popconfirm>
                <a-radio-group class="ml-20" name="radioGroup" :default-value="-1" @change="handleChangeRadioHanldeFeels">
                  <a-radio :value="1">扣除</a-radio>
                  <a-radio :value="0">不扣除</a-radio>
                </a-radio-group>
              </div>
<!--      修改退货退款类型        -->
              <div v-if="orderProductInfoVo.orderKind == 0 && orderReturnVo.returnsReason" style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
                <a-popconfirm
                    title="确定修改退款类型？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="handleReturnsAndRefundsType(orderReturnVo.id)"
                >
                  <a-button type='danger'>修改退货退款类型</a-button>
                </a-popconfirm>
                <a-radio-group class="ml-20" name="radioGroup" :default-value="-1" @change="handleChangeRadioReturnAndRefundsType">
                  <a-radio value="goodsnotreceived">仅退款</a-radio>
                  <a-radio value="goodsreceived">退货退款</a-radio>
                </a-radio-group>
              </div>
<!--      修改退货退款理由        -->
              <div v-if="orderProductInfoVo.orderKind == 0 && orderReturnVo.returnsReason" style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
                <a-popconfirm
                    title="修改退货退款理由？"
                    ok-text="确定"
                    okType="danger"
                    cancel-text="取消"
                    @confirm="handleReturnsAndRefundsCauseType(orderReturnVo.id)"
                >
                  <a-button type='danger'>修改退货退款理由</a-button>
                </a-popconfirm>
                <a-select
                    allowClear
                    style="width:200px;margin-left:10px;"
                    placeholder='选择修改类型'
                    @change="handleChangeSelectCauseType"
                >
                  <a-select-option value="buyernoreason">不想要了</a-select-option>
                  <a-select-option value="productflaw">瑕疵</a-select-option>
                  <a-select-option value="sham">假货</a-select-option>
                  <a-select-option value="physicaldiscrepancy">图片与实物不符</a-select-option>
                </a-select>
              </div>
              <div class="express">退款发起时间：<span class="color-red">{{orderReturnVo.returnSubmitTime||''}}</span></div>
              <div class="express">申请退款金额：<span class="color-red">{{orderReturnVo.returnsAmount||''}}</span></div>
              <div class="express dis-flex-start" v-if="!isEditorBaseInfoStatus">
                <div>
                  <div class="express">退款原因：<span class="color-red">{{orderReturnVo.returnsReason||''}}</span></div>
                  <div class="express"><span class="color-red">{{orderReturnVo.returnsOfService||''}}</span></div>
                  <div class="express">
                    <span>附件图片：</span>
                    <img
                        @click="previewImg(orderReturnVo.returnsFile)"
                        v-for='(item,key) of orderReturnVo.returnsFile'
                        :key="key"
                        style="max-width:50px;max-height:50px;margin-right:5px;"
                        :src='item'
                    >
                  </div>
                  <div class="express">
                    <span>送评图片：</span>
                    <img
                        @click="previewImg(orderReturnVo.gradeFile)"
                        v-for='(item,key) of orderReturnVo.gradeFile'
                        :key="key"
                        style="max-width:50px;max-height:50px;margin-right:5px;"
                        :src='item'
                    >
                  </div>
                </div>
                <div><a-button type="danger" size="small" @click="handleEditorBaseInfo(orderReturnVo)">编辑</a-button></div>
              </div>
              <div class="express dis-flex-start" v-else>
                <div>
                  <div class="express dis-flex-start">
                    <span>退款原因：</span>
                    <a-input
                        v-model="baseInfoRefundReason"
                        style="width: 300px;"
                        placeholder="请输入退款原因"
                    ></a-input>
                  </div>
                  <div class="express">
                    <span>附件图片：</span>
                    <a-upload
                        key=""
                        :action="ip + '/file/upload'"
                        list-type="picture-card"
                        :file-list="baseInfoReturnsFile"
                        @preview="handlePreview(baseInfoGradeFile)"
                        @change="handleChangeReturnsFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传图片</div>
                      </div>
                    </a-upload>
                  </div>
                  <div class="express">
                    <span>送评图片：</span>
                    <a-upload
                        key=""
                        :action="ip + '/file/upload'"
                        list-type="picture-card"
                        :file-list="baseInfoGradeFile"
                        @preview="handlePreview(baseInfoGradeFile)"
                        @change="handleChangeGradeFile"
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传图片</div>
                      </div>
                    </a-upload>
                  </div>
                </div>
                <div>
                  <a-popconfirm
                      title="确定修改？"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="handleConfirmEditorBaseInfo(orderReturnVo.id)"
                  >
                    <a-button type="danger" size="small">确定编辑</a-button>
                  </a-popconfirm>
                </div>
              </div>
              <div class="express" v-if="orderProductInfoVo.orderKind == 0 && orderReturnVo.returnsReason">
                <div>
                  <span>运费：</span>
                  <a-input v-model="orderReturnVo.logisticsFee" style="width:80px;"></a-input>
                  <a-popconfirm
                      title="确定修改运费？"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="modifyExtendFunction(orderReturnVo.id,'logisticsFee')"
                  >
                    <a-button type='primary' style="margin-left:10px;">确定修改运费</a-button>
                  </a-popconfirm>
                </div>

                <div style="margin-top:5px;">
                  <span>额外费用：</span>
                  <a-input v-model="orderReturnVo.certificateMoney" style="width:80px;"></a-input>
                  <a-popconfirm
                      title="确定修改额外费用？"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="modifyExtendFunction(orderReturnVo.id,'certificate')"
                  >
                    <a-button type='primary' style="margin-left:10px;">确定修改额外费用</a-button>
                  </a-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-items mt-20">
          <div class="title">商品信息</div>
          <div class="modal-content modal-express">
            <div class="dis-flex-start-start">
              <div class="left-content">
                <img
                    style="margin-right: 20px;width: 30px;"
                    mode="widthFix"
                    v-for="(item, index) in productSnapshot.files"
                    :src="item"
                    :key="index"
                    alt=""
                    @click="handleShowBigImg(index)"
                />
                <div class="basic-item mt-20">尺寸：{{ productSnapshot.productSize }}</div>
                <div class="basic-item">评级公司：{{ productSnapshot.ratingCompanyName || '无' }}</div>
                <div class="basic-item">评级编号：{{ productSnapshot.ratingCompanyName || '无'}}</div>
                <div class="basic-item" style="color: orange">
                  承诺服务：
                  <span
                      v-for="(item, index) in productSnapshot.serviceList"
                      :key="index"
                  >{{ item.title }}/</span>
                </div>
              </div>
              <div class="right-content ml-20">
                {{ productSnapshot.productName }}
              </div>
            </div>
          </div>
        </div>

        <div class="dis-flex-start-start mt-20">
          <div class="modal-items" style="width: 40%;">
            <div class="title">发货与物流</div>
            <div class="modal-content modal-express">
              <div class="express">
                <span>收件人：{{ orderLogisticsVo.consigneeRealname }}</span>
                <span style="margin-left: 40px">收件人电话：{{ orderLogisticsVo.consigneeTelphone }}</span>
              </div>
              <div class="express">详细地址：{{orderLogisticsVo.consigneeAddress}}</div>
              <div class="express">发货物流：{{orderLogisticsVo.logisticsName}}</div>
              <div
                  class="express"
                  @click="untils.copy_text(orderLogisticsVo.expressNo)"
              >物流单号：{{orderLogisticsVo.expressNo}}</div>
            </div>
          </div>
          <div class="modal-items" style="width: 40%;">
            <div class="title">退货物流</div>
            <div class="modal-content modal-express">
              <div class="express">
                <span>收件人：{{ orderReturnVo.consigneeRealname }}</span>
                <span style="margin-left: 40px">收件人电话：{{ orderReturnVo.consigneeTelphone }}</span>
              </div>
              <div class="express">详细地址：{{orderReturnVo.consigneeAddress}}</div>
              <div class="express">退货物流：{{orderReturnVo.logisticsName}}</div>
              <div
                  class="express"
                  @click="untils.copy_text(orderReturnVo.expressNo)"
              >物流单号：{{orderReturnVo.expressNo}}</div>
              <div class="express">物流轨迹：</div>
              <div
                  class="order-log"
                  v-for="(item,index) of travels"
                  :key="index"
              >
                <div>{{item.time}}</div>
                <div>{{item.context}}</div>
              </div>
            </div>
          </div>
        </div>

<!--    v-if="[2, 5].includes(orderReturnVo.returnsReviewStatus) || orderProductInfoVo.status == 3"    -->
        <div class="modal-items" v-if="[2, 5].includes(orderReturnVo.returnsReviewStatus) || orderProductInfoVo.status == 3">
          <div class="title">售后操作</div>
          <div class="modal-content">
            <a-textarea
                v-model="remarkText"
                style="width:500px"
                placeholder="请输入操作原因"
                auto-size
            />
          </div>
<!--    v-if="orderReturnVo.returnsReviewStatus == 2"      -->
          <div class="dis-flex-start mt-20" v-if="orderReturnVo.returnsReviewStatus == 2">
            <a-popconfirm
                title="确认同意退货申请么？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="operationRefund(orderReturnVo.returnsNo,3)"
            >
              <a-button
                  type='primary'
              >同意退货申请</a-button>
            </a-popconfirm>
            <span class="color-red ml-20">（替卖家同意退货，同意后，买家将看见退货地址，逆向状态将是等待买家退货）</span>
          </div>
<!--     v-if="orderReturnVo.returnsReviewStatus == 2"     -->
          <div class="dis-flex-start mt-20" v-if="orderReturnVo.returnsReviewStatus == 2">
            <a-popconfirm
                title="确认拒绝退货申请么？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="operationRefund(orderReturnVo.returnsNo,-1)"
            >
              <a-button

                  type='primary'
                >拒绝退货申请</a-button>
            </a-popconfirm>
            <span class="color-red ml-20">（替卖家拒绝退货，拒绝后，买家将不能再次发起退货申请，谨慎操作！）</span>
          </div>
<!--   TODO  v-if="orderProductInfoVo.status == 3"     -->
          <div class="dis-flex-start mt-20" v-if="orderProductInfoVo.status == 3">
            <a-popconfirm
                title="确认代替买家同意收货么？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="operationRefund(orderReturnVo.returnsNo,10)"
            >
              <a-button
                  type='primary'
              >替买家同意收货</a-button>
            </a-popconfirm>
            <span class="color-red ml-20">（TODO 待开发，目前还是保持之前的逻辑）</span>
          </div>
<!-- TODO   v-if="orderProductInfoVo.status == 3"      -->
          <div class="dis-flex-start mt-20" v-if="orderProductInfoVo.status == 3">
            <a-popconfirm
                title="确认代替买家拒绝收货么？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="operationRefund(orderReturnVo.returnsNo,11)"
            >
              <a-button
                  type='primary'
              >替买家拒绝收货</a-button>
            </a-popconfirm>
            <span class="color-red ml-20">（TODO 待开发，目前还是保持之前的逻辑）</span>
          </div>
<!--     v-if="[1, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)"      -->
          <div class="dis-flex-start mt-20" v-if="[1, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)">
            <a-popconfirm
                title="确认替卖家同意收货么？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="operationRefund(orderReturnVo.returnsNo,6)"
            >
              <a-button
                  type='primary'
              >替卖家同意收货</a-button>
            </a-popconfirm>
            <span class="color-red ml-20">（替卖家确认收货，确认后，退货完成，款项原路退给买家）</span>
          </div>
<!--     v-if="[1, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)"      -->
          <div class="dis-flex-start mt-20" v-if="[1, 3, 4, 5].includes(orderReturnVo.returnsReviewStatus)">
            <a-popconfirm
                title="确认替卖家拒绝确认收货？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="operationRefund(orderReturnVo.returnsNo,7)"
            >
              <a-button type='primary'>
                替卖家拒绝确认收货
              </a-button>
            </a-popconfirm>
            <span class="color-red ml-20">（替卖家拒绝确认收货，拒绝后，订单将重回正向流程）</span>
          </div>

        </div>

        <div class="modal-items mt-20">
          <div class="title">退货退款日志</div>
          <div class="modal-content modal-express">
            <div
              class="order-log"
              v-for="(item,index) of orderLogVo"
              :key="index"
            >
              <div>{{item.createTime}}</div>
              <div>{{item.userName}}</div>
              <div v-if="item.content">内容:{{item.content}}</div>
              <div
                v-if="item.files !==undefined && item.files != null  && item.files.length > 0"
                class="express"
              >
                <span>附加图片：</span>
                <img
                  @click="previewImg(item.files)"
                  v-for='(item,key) of item.files'
                  :key="key"
                  style="max-width:50px;max-height:50px;margin-right:5px;"
                  :src='item'
                >
                
              </div>
              <div>{{item.type == 1 || item.type == 2 ? '系统提示' : (item.type == 3 ? '后台操作' : '备注')}}：{{item.statusMessage||''}}</div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="snapshotshow"
      :width='500'
      centered
      :footer='null'
    >
      <div :style="'max-height:'+ModalHeight+'px;overflow:auto;'">
        <div class="modal-items">
          <div class="title">快照信息</div>

          <div class="modal-content modal-express">

            <div class="basic-item">商品编号：{{productVersion.productNo}}</div>
            <div class="basic-item">商品名称：{{productVersion.productName||''}}</div>
            <div class="basic-item">商品价格：<span style="color:red">￥{{productVersion.finalPrice}}</span></div>
            <div class="basic-item">运费：<span style="color:red">￥{{productVersion.logisticsFee}}</span></div>
            <div class="express">
              <span>图片：</span>
              <img
                @click="previewImg(productVersion.files)"
                v-for='(item,key) of productVersion.files'
                :key="key"
                style="max-width:50px;max-height:50px;margin-right:5px;"
                :src='item'
              >
            </div>
            <div class="basic-item">
              商品服务：
              <a-tag
                v-for="(tag, index) in productVersion.serviceList"
                :key="index"
                color="blue"
              >
                {{tag.title}}
              </a-tag>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <UserChatListModel ref="chat-list"/>
  </div>
</template>

<script>
import UserChatListModel from "@/views/cmsPage/dqPlatform/components/UserChatListModel";
import {mapState} from "vuex"
export default {
  components: {UserChatListModel},
  data() {
    return {
      baseInfoRefundReason: undefined,
      baseInfoReturnsFile: [],
      baseInfoGradeFile: [],
      isEditorBaseInfoStatus: false,
      /** 修改退货类型 */
      selectCauseType: null,
      productSnapshot: '',
      columns: [
        { title: "订单号", align: 'left', scopedSlots: {customRender: 'orderNoSlot'} },
        { title: '产品名称', dataIndex: 'productName'},
        { title: '快照', align: "center", scopedSlots: { customRender: "snapshotSlot" }, width: 300},
        { title: "退款金额", align: "center", dataIndex: "returnsAmount" },
        { title: "订单状态", dataIndex: "orderStatusName" },
        {title: "订单类型", align: 'center', scopedSlots: {customRender: 'orderKindSlot'}},
        {title: "买家", align: 'center', scopedSlots: {customRender: 'buyerUserNameSlot'}},
        {title: "卖家", dataIndex: 'sellerUserName'},
        {
          title: "退款状态",
          align: "center",
          dataIndex: "returnsReviewStatusName",
        },
        {
          title: "退货申请时间",
          align: "center",
          dataIndex: "returnSubmitTime",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      visible: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        buyerUserName: "",
        sellerUserName: "",
        productName: "",
        orderKind: 1,
      },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      orderState: [
        { label: "已取消", value: -1 },
        { label: "待付款", value: 0 },
        { label: "待发货", value: 1 },
        { label: "待收货", value: 3 },
        { label: "待评价", value: 5 },
        { label: "已完成", value: 7 },
      ],
      resetOrderState: [
        { label: "退款申请", value: "apply" },
        { label: "退款申请被拒绝", value: "refuse" },
        { label: "等待买家退货", value: "waitReturn" },
        { label: "等待商家确认收货", value: "waitConfirm" },
        { label: "拒绝确认收货", value: "refuseConfirm" },
        { label: "退款取消", value: "cancel" },
        { label: "退款完成", value: "confirm" },
      ],
      loading: false,
      show: false,
      snapshotshow: false,
      orderProductInfoVo: {},
      orderLogisticsVo: {},
      orderLogVo: [],
      orderReturnVo: {},
      sellerDefaultReturnAddressVo: {},
      travels: {},
      buyerCompensationInformation: {},
      sellerCompensationInformation: {},
      orderGradeLogisticsVo: {},
      ModalHeight: null,
      remarkText: "",
      orderRerurnID: null,
      orderId: null,
      productVersion: {},
      modifyRefundMoney: "",
      modifyRefundMark: "",
      isDeductDeposit: null,
      radioReturnAndRefundsType: null
    };
  },
  created() {
    const { orderNo } = this.$route.query;
    this.$set(this.params, "orderNo", orderNo);
    this.getData(this.params);
    const { innerHeight } = window;
    this.ModalHeight = innerHeight - 100;
  },
  computed: {
    ...mapState(['ip']),
    showExpress() {
      const { refundStatus = null } = this.orderProductInfoVo;
      return refundStatus != null;
    },
    showSet() {
      const { refundStatus = null } = this.orderProductInfoVo;
      return refundStatus === -10;
    },
  },
  methods: {
    /** 确定编辑基础信息 */
    async handleConfirmEditorBaseInfo(id) {
      const res = await  this.axios.post("/dq_admin/orderReturns/updateOrderReturn", {
        id: id,
        returnsFile: this.baseInfoReturnsFile.map(item => item.url).join(','),
        returnsReason: this.baseInfoRefundReason,
        gradeFile: this.baseInfoGradeFile.map(item => item.url).join(',')
      })
      if (res.status !== '200') return
      this.$message.info('成功')
      await this.getDetail(this.orderRerurnID)
      this.isEditorBaseInfoStatus = false
    },
    /** 编辑退款基础信息 */
    handleEditorBaseInfo(info) {
      this.baseInfoRefundReason = info.returnsReason
      this.baseInfoReturnsFile = info.returnsFile && info.returnsFile.length ? info.returnsFile.map(el => {
            return {
              uid: Math.random() * 10,
              name: "image",
              status: "done",
              url: el,
            }
          }) : []
      this.baseInfoGradeFilee = info.gradeFile && info.gradeFile.length ? info.gradeFile.map(el => {
          return {
            uid: Math.random() * 10,
            name: "image",
            status: "done",
            url: el,
          }
        }) : []
      this.isEditorBaseInfoStatus = true
    },
    /** 上传送评图片 */
    handleChangeReturnsFile({fileList}) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.baseInfoReturnsFile = fileList;
    },
    /** 上传送评图片 */
    handleChangeGradeFile({fileList}) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.baseInfoGradeFile = fileList;
    },
    async handlePreview(list) {
      let urls = list.map((el) => {
        return {
          img_url: el.url,
        };
      });
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 改变理由类型 */
    handleChangeSelectCauseType(e) {
      this.selectCauseType = e
    },
    /** 修改退货退款理由 */
    async handleReturnsAndRefundsCauseType(id) {
      if (!this.selectCauseType) {
        return this.$message.warn('请选择要修改的类型')
      }
      const res = await this.axios.get("/dq_admin/orderReturns/editReceiptOrReturnType", {
        params: {
          type: 2,
          returnId: id,
          value: this.selectCauseType
        }
      })
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.show = false
        // await this.getDetail(id);
        await this.getData(this.params);
      }
    },
    /** 修改退货退款类型 */
    async handleReturnsAndRefundsType(id) {
      if (this.radioReturnAndRefundsType == null || this.radioReturnAndRefundsType == undefined) {
        return this.$message.warn('请选择退货退款类型')
      }
      const res = await this.axios.get("/dq_admin/orderReturns/editReceiptOrReturnType", {
        params: {
          type: 1,
          returnId: id,
          value: this.radioReturnAndRefundsType
        }
      })
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.show = false
        // await this.getDetail(id);
        await this.getData(this.params);
      }
    },
    /** 改变退货退款类型 */
    handleChangeRadioReturnAndRefundsType(e) {
      this.radioReturnAndRefundsType = e.target.value
    },
    /** 是否扣除手续费 */
    handleChangeRadioHanldeFeels(e) {
      this.isDeductService = e.target.value
    },
    /** 确定扣除保证及 */
    async handleDeductBondPrice(id, type) {
      let tempData = {
        deductDepositStatus: this.isDeductDeposit,
        deductServiceStatus: this.isDeductService
      }
      if (type === 1) {
        if (this.isDeductDeposit == null || this.isDeductDeposit == undefined) {
          return this.$message.warn('请选择是否扣除保证金')
        }
        delete tempData.deductServiceStatus
      } else {
        if (this.isDeductService == null || this.isDeductService == undefined) {
          return this.$message.warn('请选择是否扣除手续费')
        }
        delete tempData.deductDepositStatus
      }
      const res = await this.axios.post("/dq_admin/orderReturns/modifyDeductDepositStatus", {
        returnsId: id,
        ...tempData
      })
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.show = false
        await this.getDetail(id);
        await this.getData(this.params);
      }
    },
    /** 改变保证金是否扣除 */
    handleChangeRadioBondPrice(e) {
      this.isDeductDeposit = e.target.value
    },
    /** 查看大图 */
    handleShowBigImg(index) {
      const list = this.productSnapshot.files.map((el) => {
        return {img_url: el};
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    async activeRefund(orderId) {
      if (!orderId) return this.$message.warning("orderId");
      const params = { orderId };
      const res = await this.axios.post(
          "/dq_admin/orderReturns/activeReturn",
          params
      );
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        await this.getDetail(this.orderID);
        await this.getData(this.params);
      }
    },
    /** 点击查看聊天记录 */
    handleShowChatList(row) {
      const groupId = row.groupId
      const oneUserId = row.sellerUserId
      const twoUserId = row.buyerUserId
      this.$refs['chat-list'].show(JSON.parse(JSON.stringify(groupId)),JSON.parse(JSON.stringify(oneUserId)),JSON.parse(JSON.stringify(twoUserId)))
    },
    /** 去往订单列表页面 */
    handleGoToOrderListPage(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/newordermannage?orderNo=${row.orderNo}`;
      window.open(url)
    },
    /** 放大图片 */
    handlePreViewImg(row, index) {
      const list = row.coverImages.map(item => ({img_url: item}))
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
        currentIndex: index
      });
    },
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/orderProduct/returnsList", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      this.params.pageNum = 1;
      this.getData(this.params);
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.limit = pageSize;
      this.getData(this.params);
    },
    openModal(row) {
      this.show = true;
      const { id } = row;
      this.orderRerurnID = id;
      this.getDetail(this.orderRerurnID);
    },

    async getDetail(id) {
      const res = await this.axios(
        "/dq_admin/orderProduct/getOrderReturnDetail",
        { params: { id } }
      );
      if (res.status == 200) {
        const {
          orderProductInfoVo,
          orderLogisticsVo,
          orderLogVo,
          orderReturnVo,
          sellerDefaultReturnAddressVo,
          travels,
          buyerCompensationInformation,
          sellerCompensationInformation,
          orderGradeLogisticsVo,
          productSnapshot
        } = res.data;
        this.orderProductInfoVo = orderProductInfoVo;
        this.orderLogisticsVo = orderLogisticsVo;
        this.orderLogVo = orderLogVo;
        this.orderReturnVo = orderReturnVo;
        this.sellerDefaultReturnAddressVo = sellerDefaultReturnAddressVo;
        this.travels = travels;
        this.buyerCompensationInformation = buyerCompensationInformation;
        this.sellerCompensationInformation = sellerCompensationInformation;
        this.orderGradeLogisticsVo = orderGradeLogisticsVo;
        this.productSnapshot = productSnapshot
      }
    },

    openModalSnapshot(orderId) {
      this.snapshotshow = true;
      this.orderId = orderId;
      this.getSnapshot(this.orderId);
    },

    async getSnapshot(orderId) {
      const res = await this.axios("/dq_admin/orderProduct/getOrderSnapshot", {
        params: { orderId },
      });
      if (res.status == 200) {
        const { productVersion } = res.data;
        this.productVersion = productVersion;
      }
    },

    /** 拍卖单子执行退款 */
    async handleReturnAuctionOrder(orderReturnId) {
      this.$loading.show();
      const params = {orderReturnId: orderReturnId}
      const res = await this.axios.post(
          "/dq_admin/orderReturns/bidOrderNotSendRefund",
          params
      );
      this.$loading.hide();
      if (res.status == 200) {
        this.$message.success('成功');
        await this.getDetail(this.orderRerurnID);
        await this.getData(this.params);
        this.show = false;
      }
    },

    async operationRefund(returnsNo, status) {
      if (!returnsNo) return this.$message.warning("缺少该参数returnsNo");
      if (!this.remarkText) return this.$message.warning("请输入操作说明");

      return this.$confirm({
        title: '确定要这么做吗?',
        content: h => <div style="color:red;">这么做可能会导致钱款直接退回，请谨慎操作！</div>,
        onOk: async () => {
          await this.handleConfirmButton(returnsNo, status)
        },
        onCancel() {
        },
        class: 'test',
      })
    },
    async handleConfirmButton(returnsNo, status) {
      const params = { returnsNo, status, content: this.remarkText };
      const res = await this.axios.post(
          "/dq_admin/orderReturns/operationRefund",
          params
      );
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message || '成功');
        await this.getDetail(this.orderRerurnID);
        await this.getData(this.params);
        this.show = false;
      }
    },
    modifyRefundMoneyFunction(returnsId, status) {
      if (!returnsId) return this.$message.warning("缺少该参数returnsId");
      if (!this.modifyRefundMoney)
        return this.$message.warning("请输入退款金额");
      if (!this.modifyRefundMark)
        return this.$message.warning("请输入备注修改原因");

      const params = {
        returnsId,
        status,
        modifyRefundMoney: this.modifyRefundMoney,
        modifyRefundMark: this.modifyRefundMark,
      };

      this.axios
        .post("/dq_admin/orderReturns/modifyRefundMoney", params)
        .then((res) => {
          if (res.status == "200") {
            const { message } = res;
            this.$message.success(message);
            this.modifyRefundMoney = "";
            this.modifyRefundMark = "";
            this.show = false;
          }
        });
    },

    modifyExtendFunction(returnsId, type) {
      if (!returnsId) return this.$message.warning("缺少该参数returnsId");
      var modifyMoney;
      if(type == "certificate"){
        if (!this.orderReturnVo.certificateMoney){
          return this.$message.warning("请输入额外费用");
        }
        modifyMoney  = this.orderReturnVo.certificateMoney;
      }
      if(type == "logisticsFee"){
        if (!this.orderReturnVo.logisticsFee){
          return this.$message.warning("请输入运费");
        }
        modifyMoney  = this.orderReturnVo.logisticsFee
      }
      if (Number(modifyMoney) > this.orderProductInfoVo.orderTotalPrice) {
        return this.$message.warning(`输入的金额不能大于支付的总金额：${this.orderProductInfoVo.orderTotalPrice}` )
      }
      const params = {
        returnsId,
        type,
        modifyMoney: modifyMoney,
      };

      this.axios
        .post("/dq_admin/orderReturns/modifyExtendMoney", params)
        .then((res) => {
          if (res.status == "200") {
            const { message } = res;
            this.$message.success(message);
            this.modifyRefundMoney = "";
            this.modifyRefundMark = "";
            this.show = false;
          }
        });
    },

    async remark(orderId) {
      const params = { orderId, content: this.remarkText };
      const res = await this.axios.post("/dq_admin/orderLog/add", params);
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.remarkText = "";
        this.getDetail(this.orderID);
      }
    },
    previewImg(list) {
      if (list && list.length > 0) {
        this.$previewImg({
          list: list.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-img-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.order-text {
  color: #2d8cf0;
  cursor: pointer;
  text-decoration: underline;
}
.product-img {
  img {
    width: 50px;
    height: 100px;
  }
}
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.modal-items {
  width: 100%;
  .title {
    width: 30%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-weight: bold;
  }
  .modal-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .basic-item {
      margin-right: 40px;
      margin-bottom: 10px;
    }
  }
  .modal-express {
    display: flex;
    flex-direction: column;
    .express {
      margin-bottom: 20px;
    }
    .order-log {
      margin-bottom: 20px;
    }
  }
}
.color-blue {
  color: #2f54eb !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.color-red {
  color: red !important;
}
.dis-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dis-flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-200 {
  margin-left: 200px !important;
}
</style>