<template>
  <!-- 推送样本库 -->
  <a-modal
      v-model="pushModal"
      :width='800'
      title="确认到样本库"
  >
    <template #footer>
      <a-button key="back" @click="cancelPushModal">取消</a-button>
      <a-button key="submit-wait" type="primary" @click="confirmPushModal('sampleToWaiting')">确认到到困难样本</a-button>
      <a-button key="submit-diku" type="primary" @click="confirmPushModal('sampleToRemoveDiku')">确认到样本库</a-button>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin($event, 'change')"
            @search="coinSearch($event, '', [5])"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.coinKindId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName + `【${item.id}】` }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.coinKindItemId"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            showSearch
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="params.versionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.coinVersionId"
              :value="item.coinVersionId"
          >{{ `${item.coinVersionName}【${item.size}】【${item.level}】` }}</a-select-option>
        </a-select>
      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
export default {
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      pushModal: false,
      params: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
    };
  },
  methods: {
     async show(data) {
      this.params = JSON.parse(JSON.stringify(data));
      this.$loading.show()
      await this.setDefault();
      this.$loading.hide()
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      await this.coinSearch(this.params.coinKindName, 'setDefault', [5])
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal(type) {
      const { duibanId, coinKindId, coinKindItemId, versionId, questionId } = this.params;
      this.$loading.show();
       const params = {
         recordId: duibanId,
         coinId: coinKindId,
         itemId: coinKindItemId,
         questionId: questionId,
         versionId,
         type: 5,
         isTest: 0
       }
       if (type === 'sampleToWaiting') {
         params.operType = type
       }
      const res = await this.axios("/dq_admin/duibanUserRecord/confirmToEnterTheSampleLibrary", {
        params: params
      });
      this.$loading.hide();
      if (res.status !== '200') return;
      this.cancelPushModal();
      this.$emit('success')
      this.$message.success(res.message || "操作成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.checked {
  margin-top: 10px;
}
</style>