<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   大币种   -->
      <div slot="itemBigCoinSlot" slot-scope="row">
        <div class="color-black">
          【
          <span class="color-blue font-weight-bold">{{ row.coinName }}</span>
          --
          <span class="color-green font-weight-bold">{{ row.coinId }}</span>
          】
        </div>
        <div class="mt-5 color-black">开放状态【<span class="font-weight-bold" :class="versionOpenBigCoinOpenStatusClassType[row.openStatus]">{{ computedVersionOpenBigCoinOpenStatusTypeMapText(row.openStatus) }}</span>】</div>
        <div class="mt-5 color-black">【<span class="color-blue font-weight-bold">{{ row.customDynastyName }}</span>】</div>
      </div>
<!--   负责人   -->
      <div slot="itemBusinessUserSlot" slot-scope="row">
        <div class="mt-5 color-black" v-if="row.correctRate">准确率【<span class="color-orange font-weight-bold">{{ row.correctRate }}</span>】</div>
        <div class="mt-5 font-weight-bold color-black">{{ row.businessUser }}</div>
        <a-button class="mt-5" type="danger" size="small" @click="handleUpdateCountData(row, 'rate')">更准确率统计</a-button>
      </div>
<!--   统计   -->
      <div slot="itemCountSlot" slot-scope="row">
        <div class="color-black" v-if="row.sampleTotalCount">样本总数量【<span class="color-blue font-weight-bold">{{ row.sampleTotalCount }}</span>】</div>
        <div class="mt-5 color-black" v-if="row.duibanVersionCount">对版版别数量【<span class="color-blue font-weight-bold">{{ row.duibanVersionCount }}</span>】</div>
        <a-button class="mt-5" type="danger" size="small" @click="handleUpdateCountData(row, 'version')">更新版别/样本数据</a-button>
      </div>
<!--   更新数据   -->
      <div slot="itemUpdateDataSlot" slot-scope="row">
        <div class="color-black" v-if="row.modelVersion">模型版本【<span class="color-green font-weight-bold">{{ row.modelVersion }}</span>】</div>
        <div class="mt-5 color-black" v-if="row.trainScale">训练比【<span class="color-orange font-weight-bold">{{ row.trainScale }}</span>】</div>
        <div class="mt-5 color-black">样本更新数量【<span class="color-blue font-weight-bold">{{ row.sampleUpdateCount == null ? '无' : row.sampleUpdateCount }}</span>】</div>
        <div class="mt-5 color-black" v-if="row.modelUpdateTime">模型上线时间【<span class="color-blue font-weight-bold">{{ row.modelUpdateTime.slice(0, 10) }}</span>】</div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button class="mt-5" type="primary" @click="handleShowEditorPopup(row)">编辑</a-button>
      </div>
    </a-table>
<!--  编辑  -->
    <EditorVersionOpenBigCoinPopup
        :businessUserList="businessUserList"
        ref="editorVersionOpenBigCoinPopupEl"
        @success="handleSuccess"
    />
  </div>
</template>
<script>
import {
  versionOpenBigCoinOpenStatusClassType,
  versionOpenBigCoinOpenStatusTypeMapText,
  versionOpenBigCoinTableColumns
} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_data"
import EditorVersionOpenBigCoinPopup
  from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_components/EditorVersionOpenBigCoinPopup/index.vue"
import {getRateDataCountApi, getVersionDataCountApi} from "@/views/cmsPage/currencyMannage/versionOpenBigCoin/_apis"

export default {
  components: {EditorVersionOpenBigCoinPopup},
  props: ['pagination', 'list', 'businessUserList'],
  data() {
    return {
      versionOpenBigCoinOpenStatusClassType,
      tableList: versionOpenBigCoinTableColumns,
    }
  },
  computed: {
    computedVersionOpenBigCoinOpenStatusTypeMapText() {
      return (status) => versionOpenBigCoinOpenStatusTypeMapText(status)
    }
  },
  methods: {
    handleSuccess() {
      this.$emit('success')
    },
    /** 更新数据 */
    async handleUpdateCountData(row, type) {
      let res
      if (type === 'rate') {
        res = await getRateDataCountApi(row.coinId)
      } else if (type === 'version') {
        res = await getVersionDataCountApi(row.coinId)
      }
      if (res.status !== '200') return
      this.$message.info('更新成功')
      this.handleSuccess()
    },
    /** 编辑 */
    handleShowEditorPopup(row) {
      const temp = JSON.parse(JSON.stringify(row))
      temp.modelVersionUpNewTime = temp.modelUpdateTime ? temp.modelUpdateTime.slice(0, 10) : ''
      this.$refs.editorVersionOpenBigCoinPopupEl.show(temp)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>