<template>
  <a-modal v-model="showPopup" :width='500' title="快递揽收详情" :footer="null">
    <a-card v-if="logisticsData">
      <p>取件码：<span class="color-black font-weight-bold font-size-16" :class="logisticsData.pickupCode ? 'color-green' : 'color-black'">{{ logisticsData.pickupCode || '暂无提供' }}</span></p>
      <p>快递员：<span class="color-blue font-weight-bold">{{ logisticsData.courierName }}</span></p>
      <p>快递员手机号码：<span class="color-blue font-weight-bold">{{ logisticsData.courierMobile }}</span></p>
      <p>快递预约时间：<span class="color-blue font-weight-bold">{{ logisticsData.makeAppointmentTime }}</span></p>
      <p>网点电话：<span class="font-weight-bold" :class="logisticsData.netTel ? 'color-blue' : 'color-black'">{{ logisticsData.netTel || '暂无提供' }}</span></p>
      <p>用户取消原因：<span class="font-weight-bold" :class="logisticsData.cancelMsg9 ? 'color-red' : 'color-black'">{{ logisticsData.cancelMsg9 || '无' }}</span></p>
      <p>系统取消或下单失败原因：<span class="font-weight-bold" :class="logisticsData.cancelMsg99 ? 'color-red' : 'color-black'">{{ logisticsData.cancelMsg99 || '无' }}</span></p>
    </a-card>
  </a-modal>
</template>
<script>
import {
  getUserOnLineCreateLogisticsInfoApi,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      logisticsNum: undefined,  // 快递单号
      logisticsData: undefined,
    }
  },
  methods: {
    async show(num) {
      this.logisticsNum = num
      await this.getData()
      this.showPopup = true
    },
    async getData() {
      this.$loading.show()
      const res = await getUserOnLineCreateLogisticsInfoApi(this.logisticsNum)
      this.$loading.hide()
      if (res.status !== '200') return
      this.logisticsData = res.data
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        content: undefined,
        pictureList: []
      }
      this.showPopup = false
    },
  }
}
</script>