<template>
  <a-modal v-model="showPopup" title="币种模型版本进度" :width="500" :footer="null">
    <div style="background: #ECECEC; padding: 15px">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-card>
            <a-statistic title="验证集数量" :value-style="{ color: 'green' }" :value="progressInfo.allNum || 0"  />
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card>
            <a-statistic title="已对版数量" :value-style="{ color: 'green' }" :value="progressInfo.duibanNum || 0" />
          </a-card>
        </a-col>
        <a-col :span="8">
          <a-card>
            <a-statistic title="进度" :value-style="{ color: 'green' }" suffix="%" :value="(progressInfo.percentage || 0)" />
          </a-card>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
import {
  getCoinModelVersionProgressApi,
} from "@/views/cmsPage/currencyMannage/coinModelVersionManage/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        id: undefined,
      },
      progressInfo: {},
    }
  },
  methods: {
    /** 展示弹窗 */
    async show(data) {
      this.params.id = data
      await this.getData()
      this.showPopup = true
    },
    /** 确定 */
    async getData() {
      this.$loading.show()
      const res = await getCoinModelVersionProgressApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.progressInfo = res.data
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
      }
      this.showPopup = false
    },
  }
}
</script>