<template>
  <a-modal
      :width="800"
      centered
      v-model="showPopup"
      title="修改地址信息"
      @ok='handleConfirmAddress'
      @cancel='handleCancelAddress'
  >
    <div class="flex-start-center" v-if="!customAreaStatus">
      <span>省市区：</span>
      <a-cascader
          class="w-500"
          :value="defaultList"
          :fieldNames="addressListAttMapNewAtt"
          :options="cityOptions"
          placeholder="请选择省市区"
          :popupStyle="{height: '400px'}"
          @change="handleChangeArea"
      />
      <div class="ml-30">
        <a-button type="danger" @click="handleUserCustomerAddress" size="small">地址没找到</a-button>
      </div>
    </div>
    <div class="flex-start-center" v-else>
      <span>自定义省市区：</span>
      <a-input allowClear v-model="addressInfo.provinceName" class="w-150" placeholder='输入省份'/><span class="font-weight-bold color-black mr-10">省</span>
      <a-input allowClear v-model="addressInfo.cityName" class="w-150" placeholder="输入市区"/><span class="font-weight-bold color-black mr-10">市</span>
      <a-input allowClear v-model="addressInfo.countyName" class="w-100" placeholder='输入县区'/><span class="font-weight-bold color-black">区</span>
      <div class="ml-30">
        <a-button type="danger" size="small" @click="handleCancelCustomAddress">取消自定义</a-button>
      </div>
    </div>
    <div class="flex-start-center mt-10">
      <span>详情地址：</span>
      <a-input
          allowClear
          v-model="addressDetailInfoText"
          class="w-400"
          placeholder='输入详情地址'
      ></a-input>
    </div>
  </a-modal>
</template>
<script>
import {cityOptions} from "@/untils/cityOption"
import {addressListAttMapNewAtt} from "@/_data"
export default {
  emits: ['edit'],
  data() {
    return {
      addressListAttMapNewAtt,
      customAreaStatus: false,
      showPopup: false,
      cityOptions,
      defaultList: [],
      addressDetailInfoText: '',
      addressInfo: {
        provinceName: '',
        cityName: '',
        countyName: ''
      }
    }
  },
  methods: {
    /** 取消自定义地址 */
    handleCancelCustomAddress() {
      this.customAreaStatus = false
    },
    /** 自定义地址省市区 */
    handleUserCustomerAddress() {
      this.customAreaStatus = true
    },
    show() {
      this.showPopup = true

    },
    /** 确定修改 */
    handleConfirmAddress() {
      if (this.defaultList.length <= 0 && !this.customAreaStatus) return this.$message.warn('请选择省市区')
      if (!this.addressDetailInfoText) return this.$message.warn('请输入地址详情')
      if (!this.customAreaStatus) {
        this.addressInfo.provinceName = this.defaultList[0]
        this.addressInfo.cityName = this.defaultList[1]
        if (this.defaultList[2]) {
          this.addressInfo.countyName = this.defaultList[2]
        }
      } else {
        if (!this.addressInfo.provinceName) return this.$message.warning('省份必须填写')
        if (!this.addressInfo.cityName) return this.$message.warning('市区必须填写')
        if (!this.addressInfo.countyName) return this.$message.warning('县区必须填写')
      }
      this.$emit('edit', {
        detailAddressInfo: this.addressDetailInfoText,
        addressObjInfo: this.addressInfo
      })
      this.handleCancelAddress()
    },
    /** 取消修改 */
    handleCancelAddress() {
      this.defaultList = []
      this.addressDetailInfoText = ''
      this.addressInfo = {
        provinceName: '',
        cityName: '',
        countyName: ''
      }
      this.showPopup = false
    },
    /** 改变省市区 */
    handleChangeArea(e) {
      this.defaultList = e
    }
  }
}
</script>
<style scoped lang="scss">
.change-address-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>