<template>
  <div>
    <a-table
        @change="changePage"
        :pagination="pagination"
        :columns="columns"
        :data-source="list"
        :scroll="{ x: 800 }"
        :rowKey="(record,n) => n"
    >
<!--   用户信息   -->
      <div slot="authUserInfoSlot" slot-scope="row">
        <div class="font-weight-bold color-black">
          {{ row.userName }}----【<span class="color-green">{{ row.userId }}</span>】
        </div>
        <div class="mt-10 font-weight-bold color-black">
          用户标版单价：【<span class="color-red">{{ row.labelPrice == null ? '暂未设置' : `￥${row.labelPrice}` }}</span>】
        </div>
        <div class="mt-10">
          <a-button size="small" type="primary" @click="handleVersionCountList(row)">用户标版统计报表</a-button>
        </div>
      </div>
      <div slot="authBigCoinSlot" slot-scope="row">
        <a-tag color="blue" class="tag-class" v-for="item in row.coinKindList" :key="item.coinKndId">{{ item.coinKindName }}</a-tag>
      </div>
      <div slot="authActionSlot" slot-scope="row">
        <a-button
            size="small"
            type="primary"
            @click="handleShowEditor(row)"
        >编辑</a-button>
        <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteAuth(row)"
        >
          <a-button
              size="small"
              type="danger"
              style='margin-left: 10px;'
          >删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <ExpertOrNormalAdd
      ref="expertOrNormalAddEl"
      @addOrEditorSuccess="handleAddOrEditorSuccess"
    />
    <RatingAdd
        ref="ratingAddEl"
        @addOrEditorSuccess="handleAddOrEditorSuccess"
    />
<!--  统计  -->
    <VersionCountPopup ref="versionCountPopupEl"/>
  </div>
</template>

<script>
import {AUTH_EXPERT_OR_NORMAL_COLUMNS} from "@/views/cmsPage/versionManage/versionAuthAbout/_data"
import ExpertOrNormalAdd from "@/views/cmsPage/versionManage/versionAuthAbout/_components/ExpertOrNormalAdd"
import RatingAdd from "@/views/cmsPage/versionManage/versionAuthAbout/_components/RatingAdd"
import {deleteRatingAuthList} from "@/views/cmsPage/versionManage/versionAuthAbout/_apis"
import VersionCountPopup from "@/views/cmsPage/versionManage/versionAuthAbout/_components/VersionCountPopup/index.vue"

export default {
  props: ["list", "pagination", 'pageType'],
  components: {
    ExpertOrNormalAdd, RatingAdd, VersionCountPopup
  },
  data() {
    return {
      columns: AUTH_EXPERT_OR_NORMAL_COLUMNS,
      apiUrlByDelete: {
        expert: '/dq_admin/duibanConfirmAuthCoin/delAuthority',
        normal: '/dq_admin/duibanNormalAuthCoin/delAuthority',
        rating: '/dq_admin/duibanRatingAuthCoin/delAuthority',
      }
    };
  },
  methods: {
    /** 用户标版统计 */
    handleVersionCountList(row) {
      this.$refs.versionCountPopupEl.show(row, this.pageType)
    },
    /** 编辑 */
    handleShowEditor(row) {
      if (this.pageType === 'rating') {
        this.$refs.ratingAddEl.show(row)
      } else {
        this.$refs.expertOrNormalAddEl.show(row, this.pageType)
      }
    },
    /** 删除 */
    async handleDeleteAuth(row) {
      this.$loading.show();
      const url = this.apiUrlByDelete[this.pageType]
      const res = await deleteRatingAuthList(url, {userId: row.userId})
      this.$loading.hide();
      if (res.status !== '200') return;
      this.$message.success(res.message || "操作成功");
      this.$emit("deleteItem");
    },
    handleAddOrEditorSuccess() {
      this.$emit("deleteItem");
    },
    // 切换分页
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-class {
  margin-bottom: 5px;
}
.title-content {
  max-width: 600px;
}
</style>