var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "修改大币种",
        "ok-text": "挪动",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.close, ok: _vm.confirmPushModal },
      model: {
        value: _vm.pushModal,
        callback: function($$v) {
          _vm.pushModal = $$v
        },
        expression: "pushModal"
      }
    },
    [
      _c(
        "a-select",
        {
          staticClass: "header-item",
          attrs: {
            dropdownMatchSelectWidth: false,
            "filter-option": _vm.untils.filterOption,
            dropdownMenuStyle: { "max-height": "350px" },
            allowClear: "",
            showSearch: "",
            placeholder: "请选择大币种"
          },
          on: {
            search: _vm.searchData,
            change: function($event) {
              return _vm.handleChangeCoin($event)
            }
          },
          model: {
            value: _vm.coinKindId,
            callback: function($$v) {
              _vm.coinKindId = $$v
            },
            expression: "coinKindId"
          }
        },
        _vm._l(_vm.coinList, function(items) {
          return _c(
            "a-select-option",
            { key: items.id, attrs: { value: items.id } },
            [
              _vm._v(
                _vm._s(
                  items.coinKindName +
                    "【" +
                    items.id +
                    "】" +
                    (items.coinCategory ? "---" + items.coinCategory : "")
                )
              )
            ]
          )
        }),
        1
      ),
      _vm.latelySearchBtn && _vm.latelySearchBtn.length
        ? _c(
            "div",
            { staticClass: "lately-search" },
            [
              _c("span", [_vm._v("最近搜索：")]),
              _vm._l(_vm.latelySearchBtn, function(item) {
                return _c(
                  "a-button",
                  {
                    key: item.id,
                    on: {
                      click: function($event) {
                        return _vm.handleLatelySearch(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.value))]
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }