<template>
  <a-drawer title="用户标版统计报表" placement="right" :width="'60vw'" :closable="true" :visible="showPopup" @close="onClose">
    <div style="background-color: #F4F6F6;">
      <div class="flex-start-center">
        <a-month-picker
            placeholder="选择日期"
            @change="handleChangeMonthPicker"
            :locale="locale"
            :allowClear="false"
            :default-value="moment(currentMonth, monthFormat)"
        />
        <div class="flex-start-center ml-40">
          <div class="">总标版数量：<span class="font-size-24 color-green font-weight-bold">{{ listData.countSum }}</span></div>
          <div class="ml-20">总金额：<span class="font-size-24 color-green font-weight-bold">￥{{ listData.priceSum }}</span></div>
          <div class="ml-20">标版单价：<span class="font-size-24 color-green font-weight-bold">￥{{ labelPrice || 0 }}</span></div>
        </div>
      </div>
      <div class="mt-40" v-if="listData.subVoList">
        <!--    alternate    -->
        <a-timeline mode="left">
          <a-timeline-item
              class="mb-20"
              v-for="(item, index) in listData.subVoList"
              :key="item.time"
          >
            <span class="color-black font-weight-bold font-size-20">{{ item.time }}</span>
            <span class="ml-20">
              标版数量：
              <span
                  :class="item.count ? 'color-green' : 'color-red'"
                  class="font-size-24 font-weight-bold"
              >{{ item.count }}</span>
            </span>
            <span class="ml-20">
              金额：
              <span
                  class="font-size-24 font-weight-bold"
                  :class="item.price ? 'color-green' : 'color-red'"
              >￥{{ item.price }}</span></span>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import {getUserVersionCountListApi} from "@/views/cmsPage/versionManage/versionAuthAbout/_apis"
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {getCurrentDate} from "@/untils/timeAbout"
import {userVersionCountListTypeMapType} from "@/views/cmsPage/versionManage/versionAuthAbout/_data"

export default {
  components: {},
  data() {
    return {
      locale,
      moment,
      currentMonth: getCurrentDate('-').monthStr,
      monthFormat: 'YYYY-MM',
      showPopup: false,
      params: {
        userId: undefined,
        time: undefined,
        type: undefined,
      },
      listData: {},
      labelPrice: 0
    }
  },
  computed: {
  },
  methods: {
    async show(data, versionType) {
      this.params.type = userVersionCountListTypeMapType[versionType]
      this.params.userId = data.userId
      this.params.time = new Date(getCurrentDate('-').monthStr).getTime()
      this.labelPrice = data.labelPrice
      await this.getData()
      this.showPopup = true
    },
    /** 获取保证金占用明细数据 */
    async getData() {
      this.$loading.show()
      const res = await getUserVersionCountListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.listData = res.data
    },
    onClose() {
      this.params = {
        userId: undefined,
        time: undefined,
        type: undefined
      }
      this.showPopup = false
    },
    /** 修改日期 */
    async handleChangeMonthPicker(time, timeStr) {
      this.params.time = timeStr
      this.params.time = new Date(timeStr).getTime()
      if (this.params.time > new Date().getTime()) return this.$message.warning('请选择小于当前日期')
      await this.getData()
    },
  }
}
</script>
<style lang="scss" scoped>
</style>