var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("收件人姓名："),
        _c("span", { staticClass: "font-weight-bold color-black" }, [
          _vm._v(_vm._s(_vm.orderLogisticsVo.consigneeRealname))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("收件人电话："),
        _c("span", { staticClass: "font-weight-bold color-black" }, [
          _vm._v(_vm._s(_vm.orderLogisticsVo.consigneeTelphone))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("详细地址： "),
        _c("span", { staticClass: "font-weight-bold color-black" }, [
          _vm.orderLogisticsVo.provinceName
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.orderLogisticsVo.provinceName +
                        _vm.orderLogisticsVo.cityName +
                        _vm.orderLogisticsVo.countyName
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.orderLogisticsVo.consigneeAddress) + " ")
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v("物流公司："),
        _c("span", { staticClass: "font-weight-bold color-black" }, [
          _vm._v(_vm._s(_vm.orderLogisticsVo.logisticsName))
        ])
      ]),
      _c("div", { staticClass: "cell-box mr-40" }, [
        _vm._v(" 物流单号： "),
        _c(
          "span",
          {
            staticClass: "font-weight-bold cur-pot color-blue",
            on: {
              click: function($event) {
                return _vm.untils.copy_text(_vm.orderLogisticsVo.expressNo)
              }
            }
          },
          [_vm._v(_vm._s(_vm.orderLogisticsVo.expressNo))]
        )
      ])
    ]),
    _vm.orderReturnVo && _vm.orderReturnVo.gradeFile
      ? _c("div", { staticClass: "flex-start-center flex-wrap mt-20" }, [
          _c(
            "div",
            { staticClass: "cell-box flex-start-center flex-wrap" },
            [
              _vm._v(" 评级图片： "),
              _vm._l(_vm.orderReturnVo.gradeFile, function(img, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "w-50 h-50 flex-center-center" },
                  [
                    _c("img", {
                      staticClass: "w-100-w h-100-h",
                      attrs: { src: img, alt: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowBigImg(
                            _vm.orderReturnVo.gradeFile,
                            index
                          )
                        }
                      }
                    })
                  ]
                )
              })
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }