import instance from "@/axios"

/** 获取物流信息 */
export const getLogisticsInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/qualityzhandian/queryRatingTrack',
        method: 'GET',
        params: params
    })
}

/** 获取D类评级确认列表 */
export const getDTypeRateServiceResultList = async (params) => {
    return instance({
        url: '/dq_admin/confirmationD/list',
        method: 'GET',
        params: params
    })
}

/** D类确定价格 */
export const postConfirmPriceByDType = async (params) => {
    return instance({
        url: '/dq_admin/confirmationD/identifyConfirm',
        method: 'POST',
        data: params
    })
}

/** 获取D类详情数据 */
export const getDTypeDetailData = async (params) => {
    return instance({
        url: '/dq_admin/confirmationD/previewDetails',
        method: 'GET',
        params: params
    })
}

/** d类评级取消评级-退款 /dq_admin/personRatingApply/refundRating?personApplyId=xx*/
export const canCelRateServiceAndReturnPriceByDType = async (params) => {
    return instance({
        url: '/dq_admin/personRatingApply/refundRating',
        method: 'GET',
        params: params
    })
}

/** 修改d类送评物流信息 */
export const changeDTypeLogisticsInfo = async (params) => {
    return instance({
        url: '/dq_admin/personRatingApply/modifyLogistics',
        method: 'POST',
        data: params
    })
}

/** 评级管理-送评流程 /dq_admin/rating/getProcessList?ratingId=xx*/
export const getRateManageRateStepList = async (params) => {
    return instance({
        url: '/dq_admin/rating/getProcessList',
        method: 'GET',
        params: params
    })
}
/** 评级管理-送评金额明细 /dq_admin/rating/getRatingPriceList?ratingId=xx*/
export const getRatePriceListApi = async (params) => {
    return instance({
        url: '/dq_admin/rating/getRatingPriceList',
        method: 'GET',
        params: params
    })
}

/** 评级结果鉴定-回退*/
export const postConfirmRateCheckBackApi = async (params) => {
    return instance({
        url: '',
        method: 'GET',
        params: params
    })
}

/** 评级结果鉴定-刷新评级结果 */
export const getRefreshRateResultApi = async (params) => {
    return instance({
        url: '/dq_admin/boxBase/deleteBoxCode',
        method: 'GET',
        params: params
    })
}