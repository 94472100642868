var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemCoinVersionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(" 【"),
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.coinName))
                  ]),
                  _vm._v("--（"),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v(_vm._s(row.coinId) + "）")
                  ]),
                  _vm._v("】 ")
                ]),
                _c(
                  "div",
                  { staticClass: "mt-10 font-weight-bold color-blue" },
                  [_vm._v(_vm._s(row.recoVersion))]
                )
              ])
            }
          },
          {
            key: "itemBaseDataSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold color-black" }, [
                  _vm._v("对版有效数："),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v(
                      _vm._s(row.qualityNum == null ? "处理中" : row.qualityNum)
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "font-weight-bold color-black mt-5" },
                  [
                    _vm._v("top1=top2记录数："),
                    _c("span", { staticClass: "color-green" }, [
                      _vm._v(
                        _vm._s(
                          row.oneEqTwoNum == null ? "处理中" : row.oneEqTwoNum
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "font-weight-bold color-black mt-5" },
                  [
                    _vm._v("top1=实际版记录数："),
                    _c("span", { staticClass: "color-green" }, [
                      _vm._v(
                        _vm._s(
                          row.oneEqRealNum == null ? "处理中" : row.oneEqRealNum
                        )
                      )
                    ])
                  ]
                )
              ])
            }
          },
          {
            key: "itemRateAboutSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { staticClass: "font-weight-bold color-black mt-5" },
                  [
                    _vm._v(" 弹窗率： "),
                    _c("span", { staticClass: "color-green" }, [
                      _vm._v(
                        _vm._s(
                          row.oneEqTwoPercent == null
                            ? "处理中"
                            : (row.oneEqTwoPercent || 0) * 100 + "%"
                        )
                      )
                    ]),
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v("（top1=2）")
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "font-weight-bold color-black mt-5" },
                  [
                    _vm._v(" 准确率： "),
                    _c("span", { staticClass: "color-green" }, [
                      _vm._v(
                        _vm._s(
                          row.oneEqRealPercent == null
                            ? "处理中"
                            : (row.oneEqRealPercent || 0) * 100 + "%"
                        )
                      )
                    ]),
                    _c("span", { staticClass: "color-red" }, [
                      _vm._v("（top1=真实版别）")
                    ])
                  ]
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowProgress(row.id)
                          }
                        }
                      },
                      [_vm._v("查看进度")]
                    )
                  ],
                  1
                ),
                !row.qualityNum
                  ? _c(
                      "div",
                      { staticClass: "mt-20" },
                      [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: { title: "确认执行任务？" },
                            on: {
                              confirm: function($event) {
                                return _vm.handleExecuteTask(row.id)
                              }
                            }
                          },
                          [
                            _c("a-button", { attrs: { type: "danger" } }, [
                              _vm._v("执行任务")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          }
        ])
      }),
      _c("CoinModelVersionProgressPopup", {
        ref: "coinModelVersionProgressPopupEl"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }