var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000, layerTitle: _vm.popupTitle },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "公告标题" } },
            [
              _c("a-input", {
                staticClass: "w-600",
                attrs: { placeholder: "请输入公告标题" },
                model: {
                  value: _vm.params.layerTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "layerTitle", $$v)
                  },
                  expression: "params.layerTitle"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "公告标题内容图片" } },
            [
              _c("UploadImage", {
                attrs: { list: _vm.titleImgList, maxCount: 1 },
                on: { change: _vm.handleChangeByTitleImg }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "公告开始时间" } },
            [
              _c("a-date-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm:ss",
                  locale: _vm.locale,
                  "show-time": {
                    defaultValue: _vm.moment("00:00:00", "HH:mm:ss")
                  }
                },
                on: { change: _vm.handleSelectedStartTimePicker },
                model: {
                  value: _vm.params.startTime,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "startTime", $$v)
                  },
                  expression: "params.startTime"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "公告结束时间" } },
            [
              _c("a-date-picker", {
                attrs: {
                  format: "YYYY-MM-DD HH:mm:ss",
                  "disabled-date": _vm.disabledEndDate,
                  locale: _vm.locale,
                  "show-time": {
                    defaultValue: _vm.moment("23:59:59", "HH:mm:ss")
                  }
                },
                on: { change: _vm.handleSelectedEndTimePicker },
                model: {
                  value: _vm.params.endTime,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "endTime", $$v)
                  },
                  expression: "params.endTime"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "公告展示方式" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    placeholder: "请选择公告的展示方式"
                  },
                  model: {
                    value: _vm.params.showType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "showType", $$v)
                    },
                    expression: "params.showType"
                  }
                },
                _vm._l(_vm.appHomeNoticeShowTimeTypeList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.value, attrs: { value: items.value } },
                    [_vm._v(_vm._s(items.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "点击遮罩层是否关闭公告" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    placeholder: "点击遮罩层是否关闭公告"
                  },
                  model: {
                    value: _vm.params.maskCloseStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "maskCloseStatus", $$v)
                    },
                    expression: "params.maskCloseStatus"
                  }
                },
                _vm._l(_vm.noticeOnclickOverlayCloseStatusList, function(
                  items
                ) {
                  return _c(
                    "a-select-option",
                    { key: items.value, attrs: { value: items.value } },
                    [_vm._v(_vm._s(items.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "公告排序" } },
            [
              _c("a-input", {
                staticClass: "w-600",
                attrs: { type: "number", placeholder: "公告排序" },
                model: {
                  value: _vm.params.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "sort", $$v)
                  },
                  expression: "params.sort"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "公告内容类型" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    placeholder: "公告内容的表现形式"
                  },
                  on: { change: _vm.handleChangeTemplateType },
                  model: {
                    value: _vm.params.detailType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "detailType", $$v)
                    },
                    expression: "params.detailType"
                  }
                },
                _vm._l(_vm.appHomeNoticeContentTypeList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.value, attrs: { value: items.value } },
                    [_vm._v(_vm._s(items.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.params.detailType === 2
            ? _c(
                "a-form-item",
                { attrs: { label: "公告内容图片" } },
                [
                  _c("UploadImage", {
                    attrs: { list: _vm.contentImgList, maxCount: 1 },
                    on: { change: _vm.handleChangeByContentImg }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.params.detailType === 1
            ? _c(
                "a-form-item",
                { attrs: { label: "公告内容" } },
                [
                  _c("WangEditor", {
                    attrs: { option: _vm.option },
                    model: {
                      value: _vm.params.detailContent,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "detailContent", $$v)
                      },
                      expression: "params.detailContent"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }