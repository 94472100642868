var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "100%",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "div",
              [
                _c("a-input", {
                  staticStyle: { width: "150px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "标题/商品ID/商品编号"
                  },
                  model: {
                    value: _vm.searchParams.productName,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "productName", $$v)
                    },
                    expression: "searchParams.productName"
                  }
                }),
                _c("a-input", {
                  staticStyle: { width: "160px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "昵称/店铺名/userId"
                  },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName"
                  }
                }),
                _c("a-input", {
                  staticStyle: { width: "110px", margin: "5px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "评级编号"
                  },
                  model: {
                    value: _vm.searchParams.productCode,
                    callback: function($$v) {
                      _vm.$set(_vm.searchParams, "productCode", $$v)
                    },
                    expression: "searchParams.productCode"
                  }
                }),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "100px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "审核状态"
                    },
                    model: {
                      value: _vm.searchParams.reviewStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "reviewStatus", $$v)
                      },
                      expression: "searchParams.reviewStatus"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: 0 } }, [
                      _vm._v("待审核")
                    ]),
                    _c("a-select-option", { attrs: { value: 8 } }, [
                      _vm._v("通过极品")
                    ]),
                    _c("a-select-option", { attrs: { value: 1 } }, [
                      _vm._v("通过精品")
                    ]),
                    _c("a-select-option", { attrs: { value: 2 } }, [
                      _vm._v("通过普品")
                    ]),
                    _c("a-select-option", { attrs: { value: 3 } }, [
                      _vm._v("通过差品")
                    ]),
                    _c("a-select-option", { attrs: { value: -1 } }, [
                      _vm._v("驳回")
                    ])
                  ],
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "100px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "拍卖状态"
                    },
                    model: {
                      value: _vm.searchParams.finishStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "finishStatus", $$v)
                      },
                      expression: "searchParams.finishStatus"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: 10 } }, [
                      _vm._v("拍卖中")
                    ]),
                    _c("a-select-option", { attrs: { value: 20 } }, [
                      _vm._v("截拍")
                    ]),
                    _c("a-select-option", { attrs: { value: 30 } }, [
                      _vm._v("流拍")
                    ]),
                    _c("a-select-option", { attrs: { value: 40 } }, [
                      _vm._v("违约")
                    ]),
                    _c("a-select-option", { attrs: { value: 100 } }, [
                      _vm._v("成交")
                    ])
                  ],
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "250px", margin: "5px" },
                    attrs: {
                      size: "small",
                      "filter-option": _vm.untils.filterOption,
                      showSearch: "",
                      placeholder: "请选择大币种",
                      allowClear: ""
                    },
                    on: { search: _vm.searchBigCoinListData },
                    model: {
                      value: _vm.searchParams.coinId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "coinId", $$v)
                      },
                      expression: "searchParams.coinId"
                    }
                  },
                  _vm._l(_vm.bigCoinListData, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [
                        _vm._v(
                          _vm._s(
                            item.coinKindName +
                              "【" +
                              item.id +
                              "】" +
                              (item.coinCategory
                                ? "---" + item.coinCategory
                                : "")
                          )
                        )
                      ]
                    )
                  }),
                  1
                ),
                _c("a-range-picker", {
                  staticStyle: { width: "200px", margin: "5px" },
                  attrs: {
                    disabled: _vm.nullEndTimeCheck,
                    placeholder: ["截拍开始日期", "截拍结束日期"],
                    size: "small"
                  },
                  on: { change: _vm.searchPickerChange }
                }),
                _c("a-range-picker", {
                  staticStyle: { width: "200px", margin: "5px" },
                  attrs: {
                    placeholder: ["审核开始日期", "审核结束日期"],
                    size: "small"
                  },
                  on: { change: _vm.searchReviewTimePickerChange }
                }),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "130px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "心理价筛选"
                    },
                    model: {
                      value: _vm.searchParams.expectPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "expectPrice", $$v)
                      },
                      expression: "searchParams.expectPrice"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: "0-200" } }, [
                      _vm._v("0-200")
                    ]),
                    _c("a-select-option", { attrs: { value: "201-500" } }, [
                      _vm._v("201-500")
                    ]),
                    _c("a-select-option", { attrs: { value: "501-1000" } }, [
                      _vm._v("501-1000")
                    ]),
                    _c("a-select-option", { attrs: { value: "1000-max" } }, [
                      _vm._v("1000以上")
                    ]),
                    _c("a-select-option", { attrs: { value: "5000-max" } }, [
                      _vm._v("5000以上")
                    ]),
                    _c("a-select-option", { attrs: { value: "10000-max" } }, [
                      _vm._v("10000以上")
                    ]),
                    _c("a-select-option", { attrs: { value: "20000-max" } }, [
                      _vm._v("20000以上")
                    ]),
                    _c("a-select-option", { attrs: { value: "50000-max" } }, [
                      _vm._v("50000以上")
                    ]),
                    _c("a-select-option", { attrs: { value: "100000-max" } }, [
                      _vm._v("100000以上")
                    ])
                  ],
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "100px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "是否委托"
                    },
                    model: {
                      value: _vm.searchParams.isMachine,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "isMachine", $$v)
                      },
                      expression: "searchParams.isMachine"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: "1" } }, [
                      _vm._v("是")
                    ]),
                    _c("a-select-option", { attrs: { value: "0" } }, [
                      _vm._v("否")
                    ])
                  ],
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "选择拍品标签"
                    },
                    model: {
                      value: _vm.searchParams.tagId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "tagId", $$v)
                      },
                      expression: "searchParams.tagId"
                    }
                  },
                  _vm._l(_vm.queryProductTagList, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.tagName))]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "150px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "评级公司"
                    },
                    model: {
                      value: _vm.searchParams.productRatingCompany,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "productRatingCompany", $$v)
                      },
                      expression: "searchParams.productRatingCompany"
                    }
                  },
                  _vm._l(_vm.ratingCompanyData, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.value))]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "100px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "审核类型"
                    },
                    model: {
                      value: _vm.searchParams.categoryReviewType,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "categoryReviewType", $$v)
                      },
                      expression: "searchParams.categoryReviewType"
                    }
                  },
                  _vm._l(_vm.coinTypeList, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "100px", margin: "5px" },
                    attrs: {
                      allowClear: "",
                      size: "small",
                      placeholder: "朝代"
                    },
                    model: {
                      value: _vm.searchParams.dynastyType,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "dynastyType", $$v)
                      },
                      expression: "searchParams.dynastyType"
                    }
                  },
                  _vm._l(_vm.dynastyTypeData, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.id, attrs: { value: item.id } },
                      [_vm._v(_vm._s(item.value))]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticClass: "header-item",
                    staticStyle: { width: "200px", "margin-left": "5px" },
                    attrs: {
                      allowClear: "",
                      dropdownMatchSelectWidth: false,
                      "filter-option": _vm.utils.filterOption,
                      showSearch: "",
                      size: "small",
                      placeholder: "请输入场次名称进行查找"
                    },
                    on: { search: _vm.getSquareList },
                    model: {
                      value: _vm.searchParams.periodId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "periodId", $$v)
                      },
                      expression: "searchParams.periodId"
                    }
                  },
                  _vm._l(_vm.squareList, function(item) {
                    return _c(
                      "a-select-option",
                      { key: item.value, attrs: { value: item.value } },
                      [_vm._v(_vm._s(item.name + "【" + item.num + "】") + " ")]
                    )
                  }),
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticClass: "header-item",
                    staticStyle: { width: "200px", "margin-left": "5px" },
                    attrs: {
                      allowClear: "",
                      dropdownMatchSelectWidth: false,
                      "filter-option": _vm.utils.filterOption,
                      size: "small",
                      placeholder: "选择是否包评级"
                    },
                    model: {
                      value: _vm.searchParams.ratingServiceStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.searchParams, "ratingServiceStatus", $$v)
                      },
                      expression: "searchParams.ratingServiceStatus"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: 1 } }, [
                      _vm._v("包评级")
                    ]),
                    _c("a-select-option", { attrs: { value: 0 } }, [
                      _vm._v("不包评级")
                    ])
                  ],
                  1
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.search }
                  },
                  [_vm._v("搜索")]
                ),
                _c(
                  "a-checkbox",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { checked: !!_vm.searchParams.deleteFlag },
                    on: { change: _vm.changeDeleteCheck }
                  },
                  [_vm._v(" 是否已删除 ")]
                ),
                _c(
                  "a-checkbox",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: {
                      disabled: !(
                        _vm.searchParams.queryStartEndTime == "" ||
                        _vm.searchParams.queryStartEndTime == undefined ||
                        _vm.searchParams.queryStartEndTime == null
                      ),
                      checked: _vm.nullEndTimeCheck
                    },
                    on: { change: _vm.changeNullEndTimeCheck }
                  },
                  [_vm._v(" 未设置节拍时间 ")]
                ),
                _c(
                  "span",
                  { staticClass: "change-box" },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "5px" },
                        attrs: {
                          size: "small",
                          disabled: _vm.selectedRowKeys.length < 1,
                          type: "danger"
                        },
                        on: { click: _vm.handleAllConfirm }
                      },
                      [_vm._v("批量推送到拍场")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              },
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "idSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    { style: { color: row.deleteFlag === 1 ? "gray" : "" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { "max-width": "150px" } },
                        [
                          row.periodId
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title: "确定删除推送的拍场？",
                                        "ok-text": "Yes",
                                        "cancel-text": "No"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.handleDeletePushSquare(row)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: { "margin-top": "15px" },
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                            disabled: row.deleteFlag === 1
                                          }
                                        },
                                        [_vm._v("删除推送的拍场")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-popover",
                            { attrs: { title: "商品编号", trigger: "hover" } },
                            [
                              _c("template", { slot: "content" }, [
                                _c("p", [_vm._v(_vm._s(row.productNo))])
                              ]),
                              _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  style: {
                                    color: row.deleteFlag === 1 ? "gray" : ""
                                  },
                                  attrs: { type: "link" },
                                  on: {
                                    click: function($event) {
                                      return _vm.copy(row.productNo)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.id) + " ")]
                              ),
                              row.simProductIdList
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c("div", [_vm._v("相似拍品：")]),
                                      _vm._l(row.simProductIdList, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              color: "red",
                                              cursor: "pointer"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleGoToOther(
                                                  item.productId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.productId) +
                                                " 相似度:" +
                                                _vm._s(item.distance)
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            2
                          ),
                          row.reviewAdmin
                            ? _c("div", [
                                _vm._v("审核人:" + _vm._s(row.reviewAdmin))
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticStyle: { "margin-top": "10px" } }, [
                        _c("div", [_vm._v(_vm._s(row.coinKindName))]),
                        row.shellType
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  class:
                                    row.shellType === "luobi"
                                      ? "color-blue"
                                      : "color-green"
                                },
                                [
                                  _vm._v(
                                    "(" +
                                      _vm._s(
                                        row.shellType === "luobi"
                                          ? "裸币"
                                          : "盒子币"
                                      ) +
                                      ")"
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  disabled: row.deleteFlag === 1,
                                  size: "small"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleShowChangeBigCoinModel(row)
                                  }
                                }
                              },
                              [_vm._v("更改大币种")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }
              },
              {
                key: "images",
                fn: function(row) {
                  return _c(
                    "div",
                    { staticClass: "height-column-sp-bt-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "images-box" },
                        _vm._l(row.coverUrls, function(item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "lazy-container",
                                  rawName: "v-lazy-container",
                                  value: { selector: "img" },
                                  expression: "{ selector: 'img' }"
                                }
                              ],
                              key: index
                            },
                            [
                              _c("img", {
                                class:
                                  row.deleteFlag === 1 ? "delete-auction" : "",
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: { "data-src": item + "/resize,w_200" },
                                on: {
                                  click: function($event) {
                                    return _vm.handlePreviewImg(
                                      row.coverUrls,
                                      row,
                                      index
                                    )
                                  }
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "img-source-popup" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleShowImgMoreInfo(
                                            item,
                                            row.coverUrls[index]
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("类型检测")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                }
              },
              {
                key: "cutImages",
                fn: function(row) {
                  return _c(
                    "div",
                    { staticClass: "height-column-sp-bt-center" },
                    [
                      row.cutCoverUrl
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px",
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "center",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("img", {
                                class:
                                  row.deleteFlag === 1 ? "delete-auction" : "",
                                staticStyle: {
                                  "max-width": "100px",
                                  "max-height": "100px",
                                  "margin-bottom": "6px"
                                },
                                attrs: { src: row.cutCoverUrl || "" },
                                on: {
                                  click: function($event) {
                                    return _vm.previewImg(row.cutCoverUrls)
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            width: "100%",
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                size: "small",
                                disabled: row.deleteFlag === 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.cutAndRatingData(row)
                                }
                              }
                            },
                            [_vm._v("裁剪-评级")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            width: "100%",
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                size: "small",
                                disabled:
                                  row.deleteFlag === 1 || !row.productExtendInfo
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showVideoModel(row)
                                }
                              }
                            },
                            [_vm._v("查看视频")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                }
              },
              {
                key: "officialImages",
                fn: function(row) {
                  return _c(
                    "div",
                    {
                      staticClass: "height-column-sp-bt-center flex-colum-start"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-width": "100px",
                            "max-height": "100px",
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "center",
                            "align-items": "center"
                          }
                        },
                        [
                          row.reviewTipRemark
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "5px" } },
                                [
                                  _c("a-tag", { attrs: { color: "red" } }, [
                                    _vm._v(_vm._s(row.reviewTipRemark))
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          row.officialCoverUrl
                            ? _c(
                                "div",
                                [
                                  _c("img", {
                                    class:
                                      row.deleteFlag === 1
                                        ? "delete-auction"
                                        : "",
                                    staticStyle: {
                                      "max-width": "100px",
                                      "max-height": "100px",
                                      "margin-bottom": "6px"
                                    },
                                    attrs: { src: row.officialCoverUrl || "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.previewImg(
                                          row.officialCoverUrls
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: {
                                        title:
                                          "删除官网图,同时也会删除评级编号,需重新录入编号",
                                        "ok-text": "Yes",
                                        "cancel-text": "No"
                                      },
                                      on: {
                                        confirm: function($event) {
                                          return _vm.deleteOfficialCoverImage(
                                            row
                                          )
                                        }
                                      }
                                    },
                                    [
                                      row.officialCoverUrls.length > 0
                                        ? _c(
                                            "a-button",
                                            {
                                              staticStyle: {
                                                "margin-top": "15px"
                                              },
                                              attrs: {
                                                size: "small",
                                                disabled: row.deleteFlag === 1
                                              }
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                }
              },
              {
                key: "titleSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    { staticClass: "height-column-sp-bt-center" },
                    [
                      _c("div", [
                        _c(
                          "div",
                          [
                            row.ratingServiceStatus
                              ? _c(
                                  "a-tag",
                                  {
                                    attrs: {
                                      color:
                                        row.deleteFlag === 1 ? "gray" : "blue"
                                    }
                                  },
                                  [_vm._v("放心购拍品")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        !row.showDoubleInput
                          ? _c(
                              "p",
                              {
                                style: {
                                  color: row.deleteFlag === 1 ? "gray" : ""
                                },
                                on: {
                                  dblclick: function($event) {
                                    return _vm.handleDoubleClick(
                                      row.id,
                                      row.productName
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.productName))]
                            )
                          : row.deleteFlag === 0
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "justify-content": "center",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("a-textarea", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    type: "text",
                                    "auto-size": { minRows: 4, maxRows: 6 },
                                    placeholder: "填写拍品名称"
                                  },
                                  model: {
                                    value: _vm.selectProductName,
                                    callback: function($$v) {
                                      _vm.selectProductName = $$v
                                    },
                                    expression: "selectProductName"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      display: "flex",
                                      "flex-direction": "column",
                                      "justify-content": "center",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("a-icon", {
                                      staticStyle: {
                                        "font-size": "24px",
                                        color: "blue",
                                        cursor: "pointer"
                                      },
                                      attrs: { type: "check-circle" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleConfirm(row)
                                        }
                                      }
                                    }),
                                    _c("a-icon", {
                                      staticStyle: {
                                        "font-size": "24px",
                                        color: "red",
                                        "margin-top": "20px",
                                        cursor: "pointer"
                                      },
                                      attrs: { type: "close-circle" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleClose(row)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        row.context
                          ? _c("div", { staticClass: "auction-desc-text" }, [
                              _c("span", [_vm._v("『拍品介绍』")]),
                              _vm._v(_vm._s(row.context) + " ")
                            ])
                          : _vm._e(),
                        row.expectPrice
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "rgb(217,13,38)",
                                  "font-weight": "bold"
                                },
                                style: {
                                  color:
                                    row.deleteFlag === 1
                                      ? "gray"
                                      : "rgb(217,13,38)"
                                }
                              },
                              [
                                _vm._v(
                                  "心理价:" + _vm._s(row.expectPrice) + " "
                                ),
                                row.incrPrice
                                  ? _c("span", [
                                      _vm._v("/" + _vm._s(row.incrPrice))
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "justify-content": "center",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v("尺寸：" + _vm._s(row.productSize))
                          ]),
                          row.machinePrice > 0
                            ? _c("div", [
                                _vm._v(" 委托价：" + _vm._s(row.machinePrice))
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                width: "100%",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确认为极品吗?",
                                    "ok-text": "Yes",
                                    "cancel-text": "No"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.reviewAuctionGood(
                                        row.id,
                                        "bestsuccess",
                                        null
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        disabled: row.deleteFlag === 1
                                      }
                                    },
                                    [_vm._v("极品")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确认为精品吗?",
                                    "ok-text": "Yes",
                                    "cancel-text": "No"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.reviewAuctionGood(
                                        row.id,
                                        "goodsuccess",
                                        null
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        type: "primary",
                                        size: "small",
                                        disabled: row.deleteFlag === 1
                                      }
                                    },
                                    [_vm._v("精品")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确认为普通吗?",
                                    "ok-text": "Yes",
                                    "cancel-text": "No"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.reviewAuctionGood(
                                        row.id,
                                        "normalsuccess",
                                        null
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        size: "small",
                                        disabled: row.deleteFlag === 1
                                      }
                                    },
                                    [_vm._v("普通")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "确认为差品吗?",
                                    "ok-text": "Yes",
                                    "cancel-text": "No",
                                    disabled: row.deleteFlag === 1
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.reviewAuctionBefore(
                                        row.id,
                                        "rubbishsuccess",
                                        null
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        size: "small",
                                        type: "danger",
                                        disabled: row.deleteFlag === 1
                                      }
                                    },
                                    [_vm._v("差品")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                width: "100%",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center"
                              }
                            },
                            [
                              row.reviewStatus === 0 ||
                              row.reviewStatus === 1 ||
                              row.reviewStatus === 2 ||
                              row.reviewStatus === 3 ||
                              row.reviewStatus === 8
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        disabled: row.deleteFlag === 1,
                                        type: "danger"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openRefuseModal(row)
                                        }
                                      }
                                    },
                                    [_vm._v("驳回")]
                                  )
                                : _vm._e(),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: {
                                    disabled: row.deleteFlag === 1,
                                    size: "small",
                                    type: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(row, 1)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "20px",
                                width: "100%",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title: "立即截拍？",
                                    "ok-text": "Yes",
                                    "cancel-text": "No"
                                  },
                                  on: {
                                    confirm: function($event) {
                                      return _vm.handleAuctionOver(row)
                                    }
                                  }
                                },
                                [
                                  _vm.localEnv !== "production"
                                    ? _c(
                                        "a-button",
                                        { attrs: { size: "small" } },
                                        [_vm._v("立即截拍")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }
              },
              {
                key: "itemTagSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title:
                                row.homeType === 8
                                  ? "确定取消标王？"
                                  : "确定标王？",
                              "ok-text": "Yes",
                              "cancel-text": "No"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.handleConfirmKing(row)
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: row.homeType === 8 ? "danger" : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(row.homeType === 8 ? "已标王" : "标王")
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-tooltip",
                          { attrs: { placement: "top" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("span", [_vm._v("修改标王截拍时间")])
                            ]),
                            _c("a-button", {
                              staticClass: "ml-10",
                              attrs: {
                                disabled: row.homeType !== 8,
                                size: "small",
                                icon: "edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeKingTime(row)
                                }
                              }
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tag-up-home",
                        class: row.homeType ? "cur-tag-item" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleProductUpHome(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.homeType ? "已推到首页" : "推首页"))]
                    ),
                    _vm.productTagList && _vm.productTagList.length
                      ? _c(
                          "div",
                          { staticClass: "tag-list" },
                          _vm._l(_vm.productTagList, function(item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "tag-item",
                                class: _vm.productTagSelectedFlag(
                                  row.productTagsList,
                                  item
                                )
                                  ? "cur-tag-item"
                                  : "",
                                on: {
                                  click: function($event) {
                                    return _vm.handleEditorProductTag(row, item)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.tagName) + " ")]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "descriptionSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { staticClass: "w-100-w" }, [
                      _c(
                        "div",
                        {
                          style: { color: row.deleteFlag === 1 ? "gray" : "" },
                          on: {
                            click: function($event) {
                              return _vm.copy(
                                row.productRemarkVo.coinStateRemark
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 平台定义瑕疵：" +
                              _vm._s(
                                row.productRemarkVo.coinStateRemark || " "
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: { color: row.deleteFlag === 1 ? "gray" : "" },
                          on: {
                            click: function($event) {
                              return _vm.copy(row.productRemarkVo.customRemark)
                            }
                          }
                        },
                        [
                          _vm._v(" 用户自定义瑕疵： "),
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(row.productRemarkVo.customRemark))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: { color: row.deleteFlag === 1 ? "gray" : "" },
                          on: {
                            click: function($event) {
                              return _vm.copy(
                                row.productRemarkVo.platformRemark
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 平台点评：" +
                              _vm._s(
                                row.productRemarkVo.platformRemark || " "
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: {
                            color: row.deleteFlag === 1 ? "gray" : "orange"
                          },
                          on: {
                            click: function($event) {
                              return _vm.copy(
                                row.productRemarkVo.ratingFlawCode
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 评级编码：" +
                              _vm._s(
                                row.productRemarkVo.ratingFlawCode || " "
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: {
                            color: row.deleteFlag === 1 ? "gray" : "orange"
                          },
                          on: {
                            click: function($event) {
                              return _vm.copy(row.productRemarkVo.ratingRemark)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 评级备注：" +
                              _vm._s(row.productRemarkVo.ratingRemark || " ") +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                size: "small",
                                disabled: row.deleteFlag === 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleEditorProductRemark(row)
                                }
                              }
                            },
                            [_vm._v("编辑产品瑕疵")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            color: "rgb(217,13,38)",
                            "font-weight": "bold",
                            "margin-top": "10px"
                          },
                          style: {
                            color:
                              row.deleteFlag === 1 ? "gray" : "rgb(217,13,38)"
                          }
                        },
                        [_vm._v("￥" + _vm._s(row.finalPrice))]
                      )
                    ])
                  ])
                }
              },
              {
                key: "statusSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.subDownStatus
                      ? _c("div", [
                          _vm._v("拷贝来源："),
                          _c(
                            "span",
                            {
                              class:
                                _vm.copyStoreSourceClassType[row.subDownStatus]
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.computedCopyStoreSourceTypeListMapText(
                                    row.subDownStatus
                                  )
                                )
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticStyle: { "max-width": "150px" } }, [
                      row.reviewStatus === -1
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "red" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "red"
                              }
                            },
                            [
                              _vm._v("已驳回 "),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(row.reviewDetail.reviewReason) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      row.reviewStatus === 0
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "#1890ff" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "#1890ff"
                              }
                            },
                            [_vm._v("待审核")]
                          )
                        : _vm._e(),
                      row.reviewStatus === 1
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "green" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "green"
                              }
                            },
                            [_vm._v("通过精品")]
                          )
                        : _vm._e(),
                      row.reviewStatus === 8
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "green" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "orange"
                              }
                            },
                            [_vm._v("通过极品")]
                          )
                        : _vm._e(),
                      row.reviewStatus === 2
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "blue" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "blue"
                              }
                            },
                            [_vm._v("通过普通")]
                          )
                        : _vm._e(),
                      row.reviewStatus === 3
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "red" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "red"
                              }
                            },
                            [_vm._v("通过差品")]
                          )
                        : _vm._e(),
                      _c("br"),
                      row.finishStatusValue
                        ? _c(
                            "p",
                            {
                              staticStyle: { color: "black" },
                              style: {
                                color: row.deleteFlag === 1 ? "gray" : "black"
                              }
                            },
                            [_vm._v(_vm._s(row.finishStatusValue))]
                          )
                        : _vm._e()
                    ])
                  ])
                }
              },
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row, function(tag, key) {
                      return _c(
                        "a-tag",
                        {
                          key: key,
                          attrs: {
                            color: row.deleteFlag === 1 ? "gray" : "blue"
                          }
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  )
                }
              },
              {
                key: "userName",
                fn: function(row) {
                  return _c("div", {}, [
                    row.isGrayUser == 1
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "#1890ff" },
                            style: {
                              color: row.deleteFlag === 1 ? "gray" : "#1890ff"
                            },
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.userIdSearch(row.userId)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(row.userName) + " ")]
                        )
                      : _vm._e(),
                    row.isGrayUser == 0
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "#1890ff" },
                            style: {
                              color: row.deleteFlag === 1 ? "gray" : "#1890ff"
                            },
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.userIdSearch(row.userId)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(row.userName) + " ")]
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "expandSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    { style: { color: row.deleteFlag === 1 ? "gray" : "" } },
                    [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(row.productRatingCompanyName || "无") +
                            " " +
                            _vm._s(row.productCode) +
                            " "
                        ),
                        _c("p"),
                        _c("div", [
                          _vm._v(
                            "出价人数：" + _vm._s(row.offerPriceNum || "0")
                          )
                        ]),
                        _c("div", [
                          _vm._v("围观人数：" + _vm._s(row.watchNum))
                        ]),
                        _c("div", [
                          _vm._v("曝光次数：" + _vm._s(row.exposeNum))
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "20px",
                              width: "100%",
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-right": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.openPriceModal(row.id)
                                  }
                                }
                              },
                              [_vm._v("出价记录")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                }
              },
              {
                key: "endTime",
                fn: function(row) {
                  return _c(
                    "div",
                    { style: { color: row.deleteFlag === 1 ? "gray" : "" } },
                    [_vm._v(" " + _vm._s(row.endTime) + " ")]
                  )
                }
              },
              {
                key: "createTime",
                fn: function(row) {
                  return _c(
                    "div",
                    { style: { color: row.deleteFlag === 1 ? "gray" : "" } },
                    [_vm._v(" " + _vm._s(row.createTime) + " ")]
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 1000,
            title: "拍品编辑",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "商品名称", prop: "productName" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      "auto-size": "",
                      size: "small",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.forms.productName,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "productName", $$v)
                      },
                      expression: "forms.productName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拍品介绍", prop: "productName" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      "auto-size": "",
                      size: "small",
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.forms.context,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "context", $$v)
                      },
                      expression: "forms.context"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "原图/展示图" } },
                [
                  _vm.fileListYuan && _vm.fileListYuan.length
                    ? _c("DraggableImg", {
                        attrs: {
                          list: _vm.fileListYuan,
                          postParams: { businessType: "product" },
                          maxLength: 12
                        },
                        on: {
                          dragImg: function($event) {
                            return _vm.handleDragYuanImg($event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "封面图/裁剪图" } },
                [
                  _vm.fileList && _vm.fileList.length
                    ? _c("DraggableImg", {
                        attrs: {
                          list: _vm.fileList,
                          postParams: { businessType: "product" },
                          isShowSwitchBtn: true,
                          isShowCroppingBtn: true,
                          maxLength: 12
                        },
                        on: {
                          dragImg: function($event) {
                            return _vm.handleDragImg($event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "评级公司", prop: "productRatingCompany" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.forms.productRatingCompany,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "productRatingCompany", $$v)
                        },
                        expression: "forms.productRatingCompany"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("裸币")]),
                      _c("a-radio", { attrs: { value: 2 } }, [_vm._v("公博")]),
                      _c("a-radio", { attrs: { value: 10 } }, [_vm._v("保粹")]),
                      _c("a-radio", { attrs: { value: 11 } }, [_vm._v("华夏")]),
                      _c("a-radio", { attrs: { value: 14 } }, [_vm._v("轩德")]),
                      _c("a-radio", { attrs: { value: 16 } }, [_vm._v("闻德")]),
                      _c("a-radio", { attrs: { value: 18 } }, [
                        _vm._v("园地评级")
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("PCGS")]),
                      _c("a-radio", { attrs: { value: 3 } }, [_vm._v("NGC")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.productRatingCompany != 0,
                      expression: "forms.productRatingCompany != 0"
                    }
                  ],
                  attrs: { label: "证书编号", prop: "productCode" }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "证书编号" },
                    model: {
                      value: _vm.forms.productCode,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "productCode", $$v)
                      },
                      expression: "forms.productCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "商品尺寸", prop: "productSize" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "商品尺寸" },
                    model: {
                      value: _vm.forms.productSize,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "productSize", $$v)
                      },
                      expression: "forms.productSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "价格", prop: "finalPrice" } },
                [
                  _c("a-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入价格",
                      disabled: ""
                    },
                    model: {
                      value: _vm.forms.finalPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "finalPrice", $$v)
                      },
                      expression: "forms.finalPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "运费", prop: "logisticsFee" } },
                [
                  _c("a-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入运费0元即为包邮",
                      disabled: ""
                    },
                    model: {
                      value: _vm.forms.logisticsFee,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "logisticsFee", $$v)
                      },
                      expression: "forms.logisticsFee"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "心理价", prop: "expectPrice" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        size: "small",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { change: _vm.handleChangeExpectPrice },
                      model: {
                        value: _vm.forms.expectPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "expectPrice", $$v)
                        },
                        expression: "forms.expectPrice"
                      }
                    },
                    _vm._l(_vm.headerPriceListData.priceList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.name, attrs: { value: item.name } },
                        [
                          _vm._v(
                            _vm._s(
                              item.name + " 加价幅度：【" + item.value + "】"
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "加价幅度", prop: "incrPrice" } },
                [
                  _c("a-input", {
                    attrs: {
                      size: "small",
                      type: "number",
                      disabled: "",
                      placeholder: "加价幅度"
                    },
                    model: {
                      value: _vm.forms.incrPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "incrPrice", $$v)
                      },
                      expression: "forms.incrPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "委托价", prop: "machinePrice" } },
                [
                  _c("a-input", {
                    attrs: {
                      size: "small",
                      type: "number",
                      placeholder: "委托价"
                    },
                    model: {
                      value: _vm.forms.machinePrice,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "machinePrice", $$v)
                      },
                      expression: "forms.machinePrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "大币种板块", prop: "coinSid" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        size: "small",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { search: _vm.searchData },
                      model: {
                        value: _vm.forms.coinSid,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "coinSid", $$v)
                        },
                        expression: "forms.coinSid"
                      }
                    },
                    _vm._l(_vm.currency_big_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.sid } },
                        [
                          _vm._v(
                            _vm._s(
                              item.coinKindName +
                                (item.coinCategory
                                  ? "---" + item.coinCategory
                                  : "")
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "截拍时间", prop: "start_time" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      "show-time": "",
                      size: "small",
                      defaultValue: _vm.moment(new Date(), "YYYY-MM-DD"),
                      placeholder: "选择日期",
                      disabled: ""
                    },
                    on: { change: _vm.changeTime },
                    model: {
                      value: _vm.forms.endTime,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "endTime", $$v)
                      },
                      expression: "forms.endTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage }
          })
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 800, title: "出价记录", footer: null },
          on: { cancel: _vm.cancelModal },
          model: {
            value: _vm.visiblePrice,
            callback: function($$v) {
              _vm.visiblePrice = $$v
            },
            expression: "visiblePrice"
          }
        },
        [
          _c(
            "div",
            { staticClass: "take-box" },
            [
              _c("a-table", {
                attrs: {
                  pagination: _vm.paginationModal,
                  loading: _vm.loadingModal,
                  columns: _vm.takeColumns,
                  "data-source": _vm.messages,
                  rowKey: function(record, n) {
                    return n
                  }
                },
                on: { change: _vm.changePageModal },
                scopedSlots: _vm._u([
                  {
                    key: "itemUserNameSlot",
                    fn: function(row) {
                      return _c("div", {}, [
                        _c("div", { staticClass: "flex-colum-start" }, [
                          _c("div", { staticClass: "color-black" }, [
                            _vm._v(_vm._s(row.userName) + " "),
                            row.sellerTowStatus
                              ? _c("span", [
                                  _vm._v("【"),
                                  _c("span", { staticClass: "color-orange" }, [
                                    _vm._v(_vm._s(row.sellerTowStatus))
                                  ]),
                                  _vm._v("】")
                                ])
                              : _vm._e()
                          ]),
                          _c(
                            "div",
                            { staticClass: "w-100-w flex-center-center mt-10" },
                            [
                              _c("div", { staticClass: "color-green" }, [
                                _vm._v("【" + _vm._s(row.userId) + "】")
                              ]),
                              row.userLevel
                                ? _c("img", {
                                    staticClass: "vip-img",
                                    attrs: {
                                      src: _vm.userVipIcon[row.userLevel]
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 700,
            title: "驳回",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.closeRefuseModal, ok: _vm.confirmRefuseModal },
          model: {
            value: _vm.refuseModal,
            callback: function($$v) {
              _vm.refuseModal = $$v
            },
            expression: "refuseModal"
          }
        },
        [
          !_vm.showSelectRefuseAuto
            ? _c(
                "a-select",
                {
                  staticStyle: { width: "600px" },
                  attrs: { allowClear: "", placeholder: "请选择驳回原因" },
                  on: { change: _vm.handleChangeSelectRefuseType },
                  model: {
                    value: _vm.productRefuseType,
                    callback: function($$v) {
                      _vm.productRefuseType = $$v
                    },
                    expression: "productRefuseType"
                  }
                },
                _vm._l(_vm.productRefuseTypeList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [
                      _c("span", [_vm._v(_vm._s(item.value))]),
                      item.icon
                        ? _c("a-icon", {
                            staticStyle: {
                              color: "#2d8cf0",
                              "margin-left": "5px"
                            },
                            attrs: { type: item.icon }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "center"
                  }
                },
                [
                  _c("a-input", {
                    staticStyle: { width: "400px" },
                    attrs: { allowClear: "", placeholder: "输入反驳原因" },
                    model: {
                      value: _vm.showSelectRefuseText,
                      callback: function($$v) {
                        _vm.showSelectRefuseText = $$v
                      },
                      expression: "showSelectRefuseText"
                    }
                  }),
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("关闭自定义输入驳回原因")])
                      ]),
                      _c("a-icon", {
                        staticStyle: {
                          "margin-left": "20px",
                          "font-size": "24px",
                          color: "red",
                          cursor: "pointer"
                        },
                        attrs: { type: "close-circle" },
                        on: { click: _vm.handleCloseAuto }
                      })
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      ),
      _c("ModalChangeBigCoin", {
        ref: "change-coin",
        on: { change: _vm.handleChangeSuccess }
      }),
      _c("ShowVideo", { ref: "show-video" }),
      _c("ChangeKingTagPopup", {
        ref: "changeKingTagPopupEl",
        on: { successEdit: _vm.getData }
      }),
      _c("PushSquarePopup", {
        ref: "pushSquarePopupEl",
        on: { successEdit: _vm.getData }
      }),
      _c("EditorProductRemarkPopup", {
        ref: "editorProductRemarkPopupEl",
        on: {
          editorSuccess: function($event) {
            return _vm.handleEditorSuccess($event)
          }
        }
      }),
      _c("ShowImgScorePopup", { ref: "showImgScorePopupEl" }),
      _c("RejectTipMessagePopup", {
        ref: "rejectTipMessagePopupEl",
        on: { checkSuccess: _vm.handleCheckSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }