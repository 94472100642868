<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.id"
          class="w-200"
          placeholder='反馈ID搜索'
      ></a-input>
      <a-select
          :dropdownMatchSelectWidth="false"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          class="w-300 ml-5"
          v-model="params.coinId"
          placeholder="请选择大币种"
      >
        <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindName + `【${item.id}】` + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          class="w-150 ml-5"
          v-model="params.handleStatus"
          placeholder="处理状态"
      >
        <a-select-option
            v-for="item of handleStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button type="primary" @click="search">搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @success="getList"
          @changePage="handleChangePage"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/versionManage/duibanFeedback/_components/TableList/index.vue"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import untils from "@/untils"
import {getScanVersionFeedbackListApi} from "@/views/cmsPage/versionManage/duibanFeedback/_apis"
import {handleStatusList} from "@/views/cmsPage/versionManage/duibanFeedback/_data"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  components: {
    TableList
  },
  data() {
    return {
      untils,
      handleStatusList,
      data: [],
      coinList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        coinId: undefined,
        handleStatus: undefined,
      },
    };
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getScanVersionFeedbackListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
