<template>
  <a-modal v-model="showPopup" :width='500' title="延长商家确认收货" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model ref="ruleForm" v-bind="layout">
      <a-form-item label="延长天数（整数）">
        <a-input
            class="w-300"
            type="number"
            placeholder="延长天数（整数）"
            v-model="params.daySize"
        ></a-input>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {
  getProlongUserTakeOverProductApi,
} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderReturnList/_apis"

export default {
  emits: ['success'],
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 7 }, //标签占5份  共24份
        wrapperCol: { span: 17 },
      },
      params: {
        orderId: undefined,
        daySize: undefined,
      },
    }
  },
  methods: {
    show(orderId) {
      this.params.orderId = orderId
      this.showPopup = true
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.daySize) return this.$message.warning('请输入延长天数')
      this.$loading.show()
      const res = await getProlongUserTakeOverProductApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info(res.message || '延长成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: undefined,
        daySize: undefined,
      }
      this.showPopup = false
    }
  }
}
</script>