<template>
  <div class="order-log-list-info-pages">
    <div class="order-log-item" v-for="(item,index) of orderNoteList" :key="index">
      <div class="order-item-left">{{ index + 1 }}</div>
      <div class="order-item-right">
        <div class="font-weight-bold color-gray">{{item.createTime}}</div>
        <div class="font-weight-bold color-black mt-5">{{item.sysAccountName}}【<span class="color-blue">{{ item.sysAccountId }}</span>】</div>
        <div class="mt-5">
          <span>备注内容：</span>
          <span class="font-weight-bold color-black">{{ item.content }}</span>
        </div>
        <div class="mt-5 flex-start-center flex-wrap">
          <img
              class="w-50 h-50"
              v-for="(src, index) in item.pictureList"
              :key="index" :src="src" alt=""
              @click="handleShowBigImg(item.pictureList, index)"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"

export default {
  mixins: [_showBigImgMixin],
  props: ['orderNoteList'],
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.order-log-list-info-pages {
  width: 100%;

  .order-log-item {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .order-item-left {
      margin-right: 20px;
      width: 30px !important;
      height: 30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: gray;
    }

    .order-item-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
</style>