var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "添加币种模型版本", width: 500 },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "选择大币种" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-250",
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    placeholder: "请选择大币种"
                  },
                  on: {
                    search: function($event) {
                      return _vm.coinSearch($event, "", [])
                    }
                  },
                  model: {
                    value: _vm.params.coinId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "coinId", $$v)
                    },
                    expression: "params.coinId"
                  }
                },
                _vm._l(_vm.coinList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [
                      _vm._v(
                        _vm._s(
                          item.coinKindName +
                            "【" +
                            item.id +
                            "】" +
                            (item.coinCategory ? "---" + item.coinCategory : "")
                        )
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "版本号" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: { allowClear: "", placeholder: "版本号" },
                model: {
                  value: _vm.params.recoVersion,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "recoVersion", $$v)
                  },
                  expression: "params.recoVersion"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }