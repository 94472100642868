var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 500, title: "客服添加举证信息" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "图片" } },
            [
              _c(
                "a-upload",
                {
                  attrs: {
                    action: _vm.ip + "/file/upload",
                    "list-type": "picture-card",
                    "file-list": _vm.picList,
                    multiple: true
                  },
                  on: { preview: _vm.handlePreview, change: _vm.handleChange }
                },
                [
                  _vm.picList.length < 6
                    ? _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "描述" } },
            [
              _c("a-input", {
                staticClass: "w-100-w",
                attrs: {
                  allowClear: "",
                  type: "textarea",
                  "auto-size": { minRows: 4, maxRows: 10 },
                  placeholder: "描述"
                },
                model: {
                  value: _vm.params.proofDesc,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "proofDesc", $$v)
                  },
                  expression: "params.proofDesc"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "发送短信" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.params.noticeObject,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "noticeObject", $$v)
                    },
                    expression: "params.noticeObject"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 0 } }, [
                    _vm._v("买家")
                  ]),
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v("卖家")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v("买卖双方")
                  ]),
                  _c("a-radio-button", { attrs: { value: null } }, [
                    _vm._v("无需发送")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }