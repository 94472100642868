/** 列表 */
export const dqCheckResultConfirmListTableColumns = [
    { title: "拍品名称", align: "center", dataIndex: 'productName' },
    { title: "订单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "送评类型", align: "center", scopedSlots: {customRender: 'itemOrderRateServicePayTypeSlot'}, width: 150 },
    { title: "评级结果", align: "center", scopedSlots: { customRender: "itemCheckResultSlot" }},
    { title: "平台确认结果", align: "center", scopedSlots: { customRender: "itemDqConfirmSlot" }},
    { title: "商品瑕疵", align: "center", scopedSlots: { customRender: 'itemRemarkSlot'}, width: 150},
    { title: "评级编号", align: "center", scopedSlots: { customRender: 'itemRatingCodeSlot' }},
    { title: "拍品价格", align: "center", dataIndex: 'orderPrice'},
    { title: "发货单号", align: "center", scopedSlots: { customRender: 'itemSenderExpressNoSlot'}},
    { title: "鉴定后买家选择", align: "center", scopedSlots: { customRender: "itemBuyerSelectedSlot" }, width: 100},
    { title: "退货时保价选择", align: "center", scopedSlots: { customRender: "itemReturnSelectedSlot" }, width: 200},
    { title: "鉴定时间", align: "center", dataIndex: 'appraisalTime', width: 150 },
    { title: "操作人", align: "center", dataIndex: 'sysAdmin' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" } },
]

/** 说明类型 */
export const DESC_TYPE_LIST = [
    { name: '描述不符', value: 20},
    { name: '掩盖瑕疵', value: 25},
    { name: '卖家无责', value: 30},
    { name: '有备注', value: 35},
]

/** 地址选择类型 */
export const ADDRESS_SELECTED_TYPE = [
    { name: '发货至买家', value: 1},
    { name: '退货至卖家', value: 2},
    { name: '让买家选择是否要', value: 3},
]

/** 选择评级费用等级-----new */
export const RATE_PRICE_LEVEL_TYPE_LIST = [
    { name: '无需评级费', value: 0 },
    { name: '40元    A类', value: 40 },
    { name: '100元    B类', value: 100 },
    { name: '160元   C类', value: 160 },
    { name: '340元   D类', value: 340 },
    { name: '980元   E类', value: 980 },
]

// /** 选择评级费用等级----old */
// export const RATE_PRICE_LEVEL_TYPE_LIST = [
//     { name: '无需评级费', value: 0 },
//     { name: '30元    A类', value: 30 },
//     { name: '80元    B类', value: 80 },
//     { name: '130元   C类', value: 130 },
//     { name: '280元   D类', value: 280 },
//     { name: '980元   E类', value: 980 },
// ]
