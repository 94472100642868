<template>
  <a-modal
      v-model="pushModal"
      :width='800'
      title="挪动"
  >
    <template #footer>
      <div class="flex-sp-bt-center">
        <a-button key="submit-wait" v-if="!waitStatus" @click="confirmPushModal('sampleToWaiting')">挪动到困难样本</a-button>
        <div v-else></div>
        <div>
          <a-button key="back" @click="cancelPushModal">取消</a-button>
          <a-button key="submit-diku" v-if="isDiku" type="primary" @click="confirmPushModal('sampleToRemoveDiku')">挪出标库</a-button>
          <a-button key="submit-yangBen" v-else type="primary" @click="confirmPushModal('sampleToDiku')">挪至标库</a-button>
          <a-button key="submit-sample" type="primary" @click="confirmPushModal('sampleToUpdate')">挪动版别</a-button>
        </div>
      </div>
    </template>
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoin($event, 'change')"
            @search="coinSearch($event, '', [5])"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 180px"
            v-model="params.coinKindId"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindName + `【${item.id}】` }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 220px"
            v-model="params.coinKindItemId"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of coinItemList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            allowClear
            showSearch
            @change="changeItemVersion"
            :filter-option="untils.filterOption"
            size="small"
            style="margin: 5px; width: 280px"
            v-model="params.coinKindItemVersionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.coinVersionId"
              :value="item.coinVersionId"
          >{{ `${item.coinVersionName}【${item.size}】【${item.level}】` }}</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"

export default {
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      pushModal: false,
      params: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
      loading: false,
      isDiku: false,
      waitStatus: 0,
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  watch: {
    pushModal() {
      if (!this.pushModal) {
        this.params = {};
      }
    },
  },
  methods: {
    async show(data) {
      this.$loading.show()
      const temp = JSON.parse(JSON.stringify(data))
      this.isDiku = temp.isDiku
      this.waitStatus = temp.waitStatus
      this.params.coinKindId = temp.coinId
      this.params.coinKindItemId = temp.coinItemId
      this.params.coinKindItemVersionId = temp.versionId
      this.params.id = temp.id
      this.params.coinKindName = temp.coinKindName;
      this.params.coinKindItemName = temp.coinKindItemName;
      this.params.coinKindItemVersionName = temp.coinKindVersionName;
      await this.coinSearch(temp.coinKindName, 'setDefault', [5])
      this.$loading.hide()
      this.pushModal = true;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal(type) {
      const {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
      } = this.params;
      const url = '/dq_admin/duibanSampleData/updateSampleData'
      const res = await this.axios.post(url, {
        id,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
        operAsycType: type
      });
      if (res.status !== '200') return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>