import instance from "@/axios"

/** 获取退款订单列表数据API */
export const getOrderReturnListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/returnsList',
        method: 'GET',
        params: params
    })
}

/** 获取退款理由 */
export const getReturnReasonListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/getOrderReturnsTypeList',
        method: 'GET',
        params: params
    })
}

/** 获取举证，留言列表 */
export const getUserVoucherListApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProof/list',
        method: 'GET',
        params: params
    })
}

/** 获取退款订单详情数据 */
export const getReturnOrderDetailInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProduct/getOrderReturnDetail',
        method: 'get',
        params: params
    })
}

/** 修改退款理由 */
export const postChangeReturnPriceReasonApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/editReceiptOrReturnTypeV1',
        method: 'get',
        params: params
    })
}
/** 确定修改 */
export const postConfirmChangeButtonApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/operationRefund',
        method: 'post',
        data: params
    })
}
/** 客服添加凭证相关 */
export const postConfirmAddVoucherApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProof/addOrderProof',
        method: 'post',
        data: params
    })
}

/** 修改退款物流费用 */
export const postChangeReturnLogisticsPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/editLogisticsFee',
        method: 'get',
        params: params
    })
}

/** 标记已处理 */
export const postServiceTagActionOverApi = async (id, status) => {
    return instance({
        url: '/dq_admin/orderProduct/markStatus',
        method: 'post',
        data: {
            markStatus: status,
            ids: [id]
        }
    })
}

/** 添加无法交付 */
export const postSellerNotCanSendProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/addNotDeliveryLog',
        method: 'get',
        params: params
    })
}

/** 延长商家确认收货API */
export const getProlongUserTakeOverProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderReturns/bidOrderReturnsAffirmReceivingDelay',
        method: 'get',
        params: params
    })
}