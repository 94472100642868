<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <div><a-button type="primary" @click="handleOSSFileDownload(row)">下载</a-button></div>
        <div class="mt-20">
          <a-popconfirm title="确认删除当前OSS文件？" @confirm="handleDelete(row)">
            <a-button type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
  </div>
</template>
<script>
import {getOSSFileDownloadApi, postDelOSSFileApi} from "@/views/cmsPage/dqSys/OSSFileMange/_apis"
import {OSSFileListTableColumns} from "@/views/cmsPage/dqSys/OSSFileMange/_data"

export default {
  props: ['pagination', 'list'],
  emits: ['success', 'changePage'],
  data() {
    return {
      tableList: OSSFileListTableColumns,
    }
  },
  computed: {},
  methods: {
    /** 确定下载 */
    async handleOSSFileDownload(row) {
      this.$loading.show()
      const res = await getOSSFileDownloadApi({fileName: row.fileName})
      this.$loading.hide()
      if (res.status !== '200') return
      const aEle = document.createElement('a')
      aEle.href = res.data
      aEle.download = row.fileName
      document.body.appendChild(aEle)
      aEle.click()
      document.body.removeChild(aEle)
    },
    /** 确定删除 */
    async handleDelete(row) {
      this.$loading.show()
      const res = await postDelOSSFileApi({fileName: row.fileName})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>