/** 表格数据-table */
export const AUTH_EXPERT_OR_NORMAL_COLUMNS = [
    { title: "权限角色昵称", scopedSlots: { customRender: "authUserInfoSlot" }, width: 230 },
    { title: "权限大币种", scopedSlots: { customRender: "authBigCoinSlot" } },
    { title: "操作", scopedSlots: { customRender: "authActionSlot" }, width: 200 },
]

/** 获取用户的标版的报表接口映射 */
export const userVersionCountListTypeMapType = {
    normal: 'quick-version',
    rating: 'rate-version',
    expert: 'expert-version'
}
